import Enum from "../lib/enum.js";

import { LitElement, html, css } from "../lit.js";

// import style from "./Rank.css";

export const ranks = new Enum({
  KUKYU: 1,
  NANAMON: 2,
  HAKKYU: 3,
  GOMON: 4,
  NANAKYU: 5,
  SANMON: 6,
  ROKKYU: 7,
  ICHIMON: 8,
  GOKYU: 9,
  YONKYU: 10,
  SANKYU: 11,
  NIKYU: 12,
  IKKYU: 13,
  SHODAN: 14,
  NIDAN: 15,
  SANDAN: 16,
  YONDAN: 17,
  GODAN: 18,
  ROKUDAN: 19,
  NANADAN: 20,
  HACHIDAN: 21,
  KUDAN: 22,
  JUDAN: 23,
  WHITE: 100,
  YELLOW: 101,
  ORANGE: 102,
  GREEN: 103,
  BLUE: 104,
  PURPLE: 105,
  BROWN: 106,
  BLACK: 107,
});

export const rankNames = {
  KUKYU: "9. Kyu / 8. Mon",
  NANAMON: "7. Mon",
  HAKKYU: "8. Kyu / 6. Mon",
  GOMON: "5. Mon",
  NANAKYU: "7. Kyu / 4. Mon",
  SANMON: "3. Mon",
  ROKKYU: "6. Kyu / 2. Mon",
  ICHIMON: "1. Mon",
  GOKYU: "5. Kyu",
  YONKYU: "4. Kyu",
  SANKYU: "3. Kyu",
  NIKYU: "2. Kyu",
  IKKYU: "1. Kyu",
  SHODAN: "1. Dan",
  NIDAN: "2. Dan",
  SANDAN: "3. Dan",
  YONDAN: "4. Dan",
  GODAN: "5. Dan",
  ROKUDAN: "6. Dan",
  NANADAN: "7. Dan",
  HACHIDAN: "8. Dan",
  KUDAN: "9. Dan",
  JUDAN: "10. Dan",
  WHITE: "White",
  YELLOW: "Yellow",
  ORANGE: "Orange",
  GREEN: "Green",
  BLUE: "Blue",
  PURPLE: "Purple",
  BROWN: "Brown",
  BLACK: "Black",
};

customElements.define(
  "x-rank",
  class extends LitElement {
    static properties = { value: { type: String } };

    // static styles = css([style]);
    static styles = css`
      :host {
        font-size: 0.75em;
        border-radius: 0.25em;
        box-shadow: 1px 1px 3px black;
        text-align: center;
        display: block;
        margin: 0.25em;
      }
      .KUKYU {
        background: white;
        color: black;
      }
      .NANAMON,
      .HAKKYU {
        background: linear-gradient(white 25%, gold 25%, gold 75%, white 75%);
        color: black;
        text-shadow: 1px 0 2px gold, -1px 0 2px gold, 0 1px 2px gold, 0 -1px 2px gold;
      }
      .HAKKYU {
        background: gold;
      }
      .NANAKYU,
      .GOMON {
        background: linear-gradient(white 25%, orange 25%, orange 75%, white 75%);
        color: black;
        text-shadow: 1px 0 2px orange, -1px 0 2px orange, 0 1px 2px orange, 0 -1px 2px orange;
      }
      .NANAKYU {
        background: orange;
      }
      .SANMON,
      .ROKKYU {
        background: linear-gradient(white 25%, green 25%, green 75%, white 75%);
        color: white;
        text-shadow: 1px 0 2px green, -1px 0 2px green, 0 1px 2px green, 0 -1px 2px green;
      }
      .ROKKYU {
        background: green;
      }
      .ICHIMON,
      .GOKYU {
        background: linear-gradient(white 25%, blue 25%, blue 75%, white 75%);
        color: white;
        text-shadow: 1px 0 2px blue, -1px 0 2px blue, 0 1px 2px blue, 0 -1px 2px blue;
      }
      .GOKYU {
        background: blue;
      }
      .YONKYU {
        background: saddlebrown;
        color: white;
      }
      .SANKYU {
        background: linear-gradient(90deg, saddlebrown 90%, red 90%, red 92%, saddlebrown 92%);
        color: white;
      }
      .NIKYU {
        background: linear-gradient(
          90deg,
          saddlebrown 85%,
          red 85%,
          red 87%,
          saddlebrown 87%,
          saddlebrown 90%,
          red 90%,
          red 92%,
          saddlebrown 92%
        );
        color: white;
      }
      .IKKYU {
        background: linear-gradient(
          90deg,
          saddlebrown 80%,
          red 80%,
          red 82%,
          saddlebrown 82%,
          saddlebrown 85%,
          red 85%,
          red 87%,
          saddlebrown 87%,
          saddlebrown 90%,
          red 90%,
          red 92%,
          saddlebrown 92%
        );
        color: white;
      }
      .SHODAN {
        background: linear-gradient(90deg, black 90%, goldenrod 90%, goldenrod 92%, black 92%);
        color: white;
        opacity: 1;
      }
      .NIDAN {
        background: linear-gradient(
          90deg,
          black 85%,
          goldenrod 85%,
          goldenrod 87%,
          black 87%,
          black 90%,
          goldenrod 90%,
          goldenrod 92%,
          black 92%
        );
        color: white;
        opacity: 1;
      }
      .SANDAN {
        background: linear-gradient(
          90deg,
          black 80%,
          goldenrod 80%,
          goldenrod 82%,
          black 82%,
          black 85%,
          goldenrod 85%,
          goldenrod 87%,
          black 87%,
          black 90%,
          goldenrod 90%,
          goldenrod 92%,
          black 92%
        );
        color: white;
        opacity: 1;
      }
      .YONDAN {
        background: linear-gradient(
          90deg,
          black 75%,
          goldenrod 75%,
          goldenrod 77%,
          black 77%,
          black 80%,
          goldenrod 80%,
          goldenrod 82%,
          black 82%,
          black 85%,
          goldenrod 85%,
          goldenrod 87%,
          black 87%,
          black 90%,
          goldenrod 90%,
          goldenrod 92%,
          black 92%
        );
        color: white;
        opacity: 1;
      }
      .GODAN {
        background: linear-gradient(
          90deg,
          black 70%,
          goldenrod 70%,
          goldenrod 72%,
          black 72%,
          black 75%,
          goldenrod 75%,
          goldenrod 77%,
          black 77%,
          black 80%,
          goldenrod 80%,
          goldenrod 82%,
          black 82%,
          black 85%,
          goldenrod 85%,
          goldenrod 87%,
          black 87%,
          black 90%,
          goldenrod 90%,
          goldenrod 92%,
          black 92%
        );
        color: white;
        opacity: 1;
      }
      .ROKUDAN,
      .NANADAN,
      .HACHIDAN {
        background: linear-gradient(
          90deg,
          red 12.5%,
          white 12.5%,
          white 37.5%,
          red 35%,
          red 65%,
          white 62.5%,
          white 87.5%,
          red 87.5%
        );
        color: black;
        text-shadow: 1px 0 2px red, -1px 0 2px red, 0 1px 2px red, 0 -1px 2px red;
      }
      .KUDAN,
      .JUDAN {
        background: red;
        color: black;
      }
      .WHITE {
        background: white;
        color: black;
      }
      .YELLOW {
        background: gold;
        color: black;
        text-shadow: 1px 0 2px gold, -1px 0 2px gold, 0 1px 2px gold, 0 -1px 2px gold;
      }
      .ORANGE {
        background: orange;
        color: black;
        text-shadow: 1px 0 2px orange, -1px 0 2px orange, 0 1px 2px orange, 0 -1px 2px orange;
      }
      .GREEN {
        background: green;
        color: white;
        text-shadow: 1px 0 2px green, -1px 0 2px green, 0 1px 2px green, 0 -1px 2px green;
      }
      .BLUE {
        background: blue;
        color: white;
        text-shadow: 1px 0 2px blue, -1px 0 2px blue, 0 1px 2px blue, 0 -1px 2px blue;
      }
      .PURPLE {
        background: purple;
        color: white;
      }
      .BROWN {
        background: saddlebrown;
        color: white;
      }
      .BLACK {
        background: black;
        color: white;
        opacity: 1;
      }
    `;

    render = () => html`<div class="${this.value}">${rankNames[this.value]}</div>`;
  }
);
