import { Light, LitElement, html } from "../lit.js";
import clsx from "../lib/clsx.js";

import { _ } from "../i18n.js";
import { state } from "../state.js";
import { httpRequest, replaceEmptyStringsByNull, sleep, trimStrings } from "../util.js";

import Schema from "../validation.js";

import "../widgets/Form.js";
import "../widgets/Input.js";

import "./LanguageSelector.js";

customElements.define(
  "jjcm-login",
  class extends Light(LitElement) {
    constructor() {
      super();
      this.alert_message = null;
      this.alert_class = null;
      this.showResetPasswordForm = false;
    }

    abortController = new AbortController();

    showAlert = (message, alert_class, delay) => {
      this.alert_message = message;
      this.alert_class = alert_class;
      this.requestUpdate();
      return this.getUpdateComplete().then(() => {
        const alert = this.querySelector(".alert");
        alert.parentNode.style.maxHeight = alert.offsetHeight + "px";
        return sleep(delay).then(() => (alert.parentNode.style.maxHeight = 0));
      });
    };

    logIn = (e) =>
      httpRequest("/auth", {
        method: "POST",
        body: JSON.stringify(replaceEmptyStringsByNull(trimStrings(e.target.values))),
      })
        .then(async (response) => {
          if (!response.ok) {
            this.showAlert(_`That did not work.`, "alert-danger", 5000);
            e.target.submittingDone();
            return;
          }
          state.checkAuth();
        })
        .catch((error) => {
          console.error(error);
          e.target.submittingDone();
        });

    logOut = state.logOut;

    changePassword = (e) =>
      httpRequest("/auth/change_password", {
        method: "POST",
        body: JSON.stringify({ password: e.target.values.password }),
      })
        .then(async (response) => {
          if (!response.ok) {
            this.showAlert(_`Could not change the password.`, "alert-danger", 5000);
            e.target.submittingDone();
            return;
          }
          this.showAlert(_`Your password was successfully changed.`, "alert-success", 2000).then(state.checkAuth);
        })
        .catch((error) => {
          console.error(error);
          e.target.submittingDone();
        });

    resetPassword = (e) =>
      httpRequest("/auth/reset_password", {
        signal: this.abortController.signal,
        method: "POST",
        body: JSON.stringify({ email: e.target.values.email }),
      })
        .then(async (response) => {
          if (!response.ok) {
            this.showAlert(_`Could not reset password. Please contact your admin.`, "alert-danger", 5000);
            e.target.submittingDone();
            return;
          }
          this.showAlert(
            _`If the given email address is valid, the according password was reset. Please check your emails (including SPAM folder).`,
            "alert-success",
            2000
          ).then(() => this.requestResetPasswordForm(false));
        })
        .catch((error) => {
          console.error(error);
          e.target.submittingDone();
        });

    requestResetPasswordForm = (value = true) => {
      this.showResetPasswordForm = value;
      this.requestUpdate();
    };

    resetPasswordForm = () => html`<h1>${_`Reset password`}</h1>
      <x-form
        @submit=${this.resetPassword}
        .renderContent=${({ values, errors, touched, submitting }) => html`<div class="row mb-3">
            <div class="col">
              <input
                class="form-control"
                type="text"
                inputmode="email"
                name="email"
                placeholder="${_`Email address`}"
              />
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button class="m-2 me-0 btn btn-primary" type="submit" ?disabled=${submitting}>${_`Reset password`}</button>
          </div>`}
      />
      <div style="max-height: 0; overflow: clip; transition: max-height .15s linear">
        <div class="alert ${this.alert_class}">${this.alert_message}</div>
      </div>`;

    logInForm = () => html`<h1>${_`Log in`}</h1>
      <jjcm-language-selector class="nav position-absolute top-0 end-0 m-5" />
      <x-form
        @submit=${this.logIn}
        .validate=${(values) =>
          Schema.Struct({
            username: Schema.String().required(_`Username missing!`),
            password: Schema.String().required(_`Password missing!`),
            remember_me: Schema.Boolean(),
            session_name: Schema.String().when("remember_me", {
              is: true,
              then: (s) => s.required(_`Session name missing!`),
            }),
          }).validate(values)}
        .renderContent=${({ values, errors, touched, submitting }) => html`<div class="row mb-3">
            <div class="col">
              <input
                class="${clsx("form-control", {
                  "is-invalid": touched.username && errors.username,
                })}"
                type="text"
                inputmode="email"
                name="username"
                placeholder="${_`Email address`}"
              />
              <div class="invalid-feedback">${errors.username}</div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col">
              <input
                class="${clsx("form-control", {
                  "is-invalid": touched.password && errors.password,
                })}"
                type="password"
                name="password"
                placeholder="${_`Password`}"
              />
              <div class="invalid-feedback">${errors.password}</div>
            </div>
          </div>
          <!--div class="row mb-3 form-check form-switch">
                  <div class="col">
                    <x-checkbox name="remember_me" label="${_`Stay logged-in on this device`}" />
                  </div>
                </div-->
          ${values.remember_me
            ? html`<div class="row mb-3">
                <div class="col">
                  <input
                    class="${clsx("form-control", {
                      "is-invalid": touched.session_name && errors.session_name,
                    })}"
                    type="text"
                    name="session_name"
                    placeholder="${_`Name (e.G. Desktop, Office)`}"
                  />
                  <div class="invalid-feedback">${errors.session_name}</div>
                </div>
              </div>`
            : null}
          <div class="d-flex justify-content-end">
            <button class="m-2 me-0 btn btn-primary" type="submit" ?disabled=${submitting}>${_`Log in`}</button>
          </div>
          <p>
            <a href="#" @click=${this.requestResetPasswordForm}>${_`Forgot password?`}</a>
          </p>`}
      />
      <div style="max-height: 0; overflow: clip; transition: max-height .15s linear">
        <div class="alert ${this.alert_class}">${this.alert_message}</div>
      </div>`;

    changePasswordForm = () => html`<h1>${_`Change password`}</h1>
      <x-form
        @submit=${this.changePassword}
        .validate=${(values) =>
          Schema.Struct({
            password: Schema.String()
              .required(_`Password missing!`)
              .trim()
              .min(8, _`The password must contain at least 8 characters.`),
            password_confirmation: Schema.String().test((x, p) => x === p.password, _`The passwords do not match!`),
          }).validate(values)}
        .renderContent=${({ values, errors, touched, submitting }) => html`<div class="row mb-3">
            <div class="col">
              <input
                class="${clsx("form-control", {
                  "is-invalid": touched.password && errors.password,
                })}"
                type="password"
                name="password"
                placeholder="${_`Password`}"
              />
              <div class="invalid-feedback">${errors.password}</div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col">
              <input
                class="${clsx("form-control", {
                  "is-invalid": touched.password_confirmation && errors.password_confirmation,
                })}"
                type="password"
                name="password_confirmation"
                placeholder="${_`Repeat password`}"
              />
              <div class="invalid-feedback">${errors.password_confirmation}</div>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button class="m-2 me-0 btn btn-primary" type="submit" ?disabled=${submitting}>${_`Change password`}</button>
            <button class="m-2 me-0 btn btn-secondary" type="button" ?disabled=${submitting} @click=${this.logOut}>
              ${_`Log out`}
            </button>
          </div>`}
      />
      <div style="max-height: 0; overflow: clip; transition: max-height .15s linear">
        <div class="alert ${this.alert_class}">${this.alert_message}</div>
      </div>`;

    render = () => {
      return state.session === null
        ? this.showResetPasswordForm
          ? this.resetPasswordForm()
          : this.logInForm()
        : state.session?.user.must_change_password
        ? this.changePasswordForm()
        : null;
    };
  }
);
