import { Light, LitElement, html } from "../lit.js";
import { httpRequest, range, unique, shuffle } from "../util.js";
import { _ } from "../i18n.js";
import { state } from "../state.js";

import { clsx } from "../lib/clsx.js";

import "../widgets/Form.js";
import "../widgets/Input.js";
import "../widgets/Table.js";

import { ranks } from "./Rank.js";

customElements.define(
  "jjcm-match-header",
  class extends Light(LitElement) {
    abortController = new AbortController();

    static properties = {
      match: { type: Object },
      points: { type: Array },
      fusenGachiAvailable: { type: Boolean },
      victoryAvailable: { type: Boolean },
    };

    constructor() {
      super();
      this.modal = null;
      this.confirmResult = null;
      document.addEventListener("hidden.bs.modal", () => {
        this.confirmResult = null;
        this.modal = null;
        this.requestUpdate();
      });
    }

    pointsAka = () => this.points.map((x) => x.aka).reduce((p, c) => p + c, 0);
    pointsShiro = () => this.points.map((x) => x.shiro).reduce((p, c) => p + c, 0);

    close = () => {
      this.dispatchEvent(new Event("closed", { bubbles: true }));
    };

    askForStoringResult = (decision) => {
      this.confirmResult = decision;
      this.requestUpdate();
    };

    storeResults = () =>
      httpRequest(`/competitions/${state.session.competition_id}/games/${this.match.id}`, {
        signal: this.abortController.signal,
        method: "POST",
        body: JSON.stringify({ decision: this.confirmResult, points: this.points }),
      })
        .then((response) => response.json())
        .then(() => this.modal.hide())
        .then(() => this.close())
        .catch(console.error);

    render = () => {
      if (!this.modal) {
        this.getUpdateComplete().then(() => {
          const modal = document.querySelector(".modal");
          if (modal) this.modal = new bootstrap.Modal(modal);
          if (this.modal) this.modal.show();
        });
      }
      return html`<div class="m-0 row">
          <div class="col text-center p-5 bg-danger">
            <h1>${this.match.aka.competitor.given_name} ${this.match.aka.competitor.name}</h1>
            <h2>${this.match.aka.dojo.name}</h2>
            <h1>${this.pointsAka()}</h1>
            <hr />
            <div>
              <h2>${_`Decisions`}</h2>
              ${this.fusenGachiAvailable
                ? html`<button
                    class="btn btn-info shadow m-1"
                    @click=${() => this.askForStoringResult({ winner_id: this.match.aka.id, reason: "FUSEN_GACHI" })}
                  >
                    <h1>${_`Fusen Gachi`}</h1>
                    <h3>${_`Win by non-appearance`}</h3>
                  </button>`
                : null}
              <button
                class="btn btn-info shadow m-1"
                @click=${() => this.askForStoringResult({ winner_id: this.match.aka.id, reason: "KIKEN_GACHI" })}
              >
                <h1>${_`Kiken Gachi`}</h1>
                <h3>${_`Win by withdrawal`}</h3>
              </button>
              <button
                class="btn btn-warning shadow m-1"
                @click=${() => this.askForStoringResult({ winner_id: this.match.shiro.id, reason: "HANSOKU_MAKE" })}
              >
                <h1>${_`Hansoku Make`}</h1>
                <h3>${_`Disqualification`}</h3>
              </button>
            </div>
            ${this.victoryAvailable && this.pointsAka() > this.pointsShiro()
              ? html`<div>
                  <button
                    class="btn btn-success shadow m-1"
                    @click=${() => this.askForStoringResult({ winner_id: this.match.aka.id, reason: "POINTS" })}
                  >
                    <h1>${_`Aka no Kachi`}</h1>
                    <h3>${_`Victory for red`}</h3>
                  </button>
                </div>`
              : null}
          </div>
          <div class="col text-center p-5 bg-white">
            <h1>${this.match.shiro.competitor.given_name} ${this.match.shiro.competitor.name}</h1>
            <h2>${this.match.shiro.dojo.name}</h2>
            <h1>${this.pointsShiro()}</h1>
            <hr />
            <div>
              <h2>${_`Decisions`}</h2>
              ${this.fusenGachiAvailable
                ? html`<button
                    class="btn btn-info shadow m-1"
                    @click=${() => this.askForStoringResult({ winner_id: this.match.shiro.id, reason: "FUSEN_GACHI" })}
                  >
                    <h1>${_`Fusen Gachi`}</h1>
                    <h3>${_`Win by non-appearance`}</h3>
                  </button>`
                : null}
              <button
                class="btn btn-info shadow m-1"
                @click=${() => this.askForStoringResult({ winner_id: this.match.shiro.id, reason: "KIKEN_GACHI" })}
              >
                <h1>${_`Kiken Gachi`}</h1>
                <h3>${_`Win by withdrawal`}</h3>
              </button>
              <button
                class="btn btn-warning shadow m-1"
                @click=${() => this.askForStoringResult({ winner_id: this.match.aka.id, reason: "HANSOKU_MAKE" })}
              >
                <h1>${_`Hansoku Make`}</h1>
                <h3>${_`Disqualification`}</h3>
              </button>
            </div>
            ${this.victoryAvailable && this.pointsAka() < this.pointsShiro()
              ? html`<div>
                  <button
                    class="btn btn-success shadow m-1"
                    @click=${() => this.askForStoringResult({ winner_id: this.match.shiro.id, reason: "POINTS" })}
                  >
                    <h1>${_`Shiro no Kachi`}</h1>
                    <h3>${_`Victory for white`}</h3>
                  </button>
                </div>`
              : null}
          </div>
        </div>
        ${this.confirmResult
          ? html`<div class="modal fade">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content border">
                  <header class="modal-header">
                    <h3>${_`Decision`}</h3>
                  </header>
                  <main class="modal-body text-center" style="font-size: 3em">
                    ${this.confirmResult.reason === "POINTS"
                      ? _`Win by points`
                      : this.confirmResult.reason === "FUSEN_GACHI"
                      ? _`Win by non-appearance`
                      : this.confirmResult.reason === "KIKEN_GACHI"
                      ? _`Win by withdrawal`
                      : this.confirmResult.reason === "HANSOKU_MAKE"
                      ? _`Disqualification`
                      : null}
                  </main>
                  <footer class="p-2 text-end">
                    <button class="btn btn-primary" @click=${this.storeResults}>
                      <h3>${_`Confirm`}</h3>
                    </button>
                  </footer>
                </div>
              </div>
            </div>`
          : null}`;
    };
  }
);
