import { Light, LitElement, html } from "../lit.js";

import { _ } from "../i18n.js";
import { state } from "../state.js";
import { httpRequest } from "../util.js";

// import Schema from "../validation.js";

// import "../widgets/Form.js";
// import "../widgets/Input.js";
import "../widgets/Table.js";

import { disciplineTitle } from "./Categories.js";

// const disciplines = ["random_attack", "ground_fighting", "ground_fighting_open", "pairs", "kata", "team"];
const disciplines = ["random_attack", "ground_fighting", "ground_fighting_open", "sparring", "sparring_open", "continuous_fighting", "continuous_fighting_open", "pairs", "empty_hand_kata", "weapons_kata", "two_person_empty_hand_kata", "two_person_weapons_kata", "team"];

const disciplineCertificateFactor = {
  RandomAttack: 1,
  GroundFighting: 1,
  GroundFightingOpen: 1,
  Sparring: 1,
  SparringOpen: 1,
  ContinuousFighting: 1,
  ContinuousFightingOpen: 1,
  Pairs: 2,
  Kata: 2,
  EmptyHandKata: 1,
  WeaponsKata: 1,
  TwoPersonEmptyHandKata: 1,
  TwoPersonWeaponsKata: 1,
  Team: 12,
};

customElements.define(
  "jjcm-dashboard",
  class extends Light(LitElement) {
    abortController = new AbortController();

    constructor() {
      super();
      this.updateData();
    }

    updateData = () =>
      Promise.all([
        httpRequest(
          `/competitions/${state.session.competition_id}/registrations?rel=dojo;rel=${disciplines.join(";rel=")}`,
          {
            signal: this.abortController.signal,
          }
        ),
        httpRequest(`/competitions/${state.session.competition_id}/dojos`, {
          signal: this.abortController.signal,
        }),
        // ...(state.session.competition.admins.map((x) => x.id).includes(state.session.user.id)
        //   ? disciplines.map((d) =>
        //       httpRequest(`/competitions/${state.session.competition_id}/${d}/categories`, {
        //         signal: this.abortController.signal,
        //       })
        //     )
        //   : []),
      ])
        .then((responses) => Promise.all(responses.map((r) => r.json())))
        .then(([competitors, dojos, ...categories]) => {
          this.dojos = dojos.map((dojo) =>
            Object.fromEntries([
              ["dojo", dojo.name],
              ["competitors", competitors.filter((c) => c.dojo.id === dojo.id)],
              ...disciplines.map((d) => [d, competitors.reduce((p, c) => p + !!(c.dojo.id === dojo.id && c[d]), 0)]),
            ])
          );
          this.totalCompetitors = competitors.length;
          this.categories = categories;
          this.requestUpdate();
        })
        .catch(console.error);

    render = () => html`<h1>${_`Dashboard`}</h1>
      <x-table
        tableClass="table table-striped table-hover table-bordered"
        .data=${this.dojos ? Object.entries(this.dojos).map(([k, v]) => ({ dojo: k, ...v })) : []}
        .columns=${[
          ...(this.dojos?.length != 1 ? [{ header: _`Dojo`, sortable: true, filterable: true, accessor: "dojo" }] : []),
          {
            header: _`Competitors`,
            sortable: true,
            accessor: "competitors",
            class: "text-end",
            render: (x) => x.competitors.length,
            renderFooter:
              this.dojos?.length != 1
                ? (data) => {
                    const n = data.reduce((p, c) => p + c.competitors.length, 0);
                    return html`<div class="text-end fw-bold">${n}</div>`;
                  }
                : null,
          },
          ...disciplines.map((d) => ({
            header: disciplineTitle(d),
            sortable: true,
            accessor: d,
            render: (x) => html`<div class="row">
              <div class="col text-end text-nowrap">${x[d]}</div>
              <!--div class="col text-end text-nowrap">(${(x[d] * state.session?.competition.fee).toFixed(2)} €)</div-->
            </div>`,
            renderFooter:
              this.dojos?.length != 1
                ? (data) => {
                    const n = data.reduce((p, c) => p + c[d], 0);
                    return html`<div class="row fw-bold">
                      <div class="col text-end text-nowrap">${n}</div>
                      <!--div class="col text-end text-nowrap">(${(n * state.session?.competition.fee).toFixed(2)} €)</div-->
                    </div>`;
                  }
                : null,
          })),
          {
            header: _`Total`,
            sortable: true,
            accessor: "competitors", // (x) => disciplines.reduce((p, c) => p + x[c], 0),
            render: (x) => {
              const n = x.competitors;
              // const n = disciplines.reduce((p, c) => p + x[c], 0);
              // console.assert(this.totalCompetitors === n);
              return html`<div class="row fw-bold">
                <div class="col text-end text-nowrap">${n.map((c) => disciplines.map((d) => !!c[d]).filter(Boolean).length).reduce((p, c) => p + c, 0)}</div>
                <div class="col text-end text-nowrap">(${n.filter((c) => disciplines.some((d) => !!c[d]))
                                                          .map((c) => disciplines.map((d) => d != "team" && !!c[d]).filter(Boolean).length)
                                                          .reduce((p, c) => p + 60 + Math.max(0, c - 3) * 15, 0).toFixed(2)} €)</div>
              </div>`;
            },
            renderFooter:
              this.dojos?.length != 1
                ? (data) => {
                    const n = data.reduce((p, c) => p + c.competitors.map((c) => disciplines.map((d) => !!c[d]).filter(Boolean).length).reduce((p, c) => p + c, 0), 0);
                    // const n = disciplines.reduce((total, d) => total + data.reduce((p, c) => p + c[d], 0), 0);
                    return html`<div class="row fw-bold">
                      <div class="col text-end text-nowrap">${n}</div>
                      <!--div class="col text-end text-nowrap">(${(n * state.session?.competition.fee).toFixed(2)} €)</div-->
                      <div class="col text-end text-nowrap">(${data.map((c) => c.competitors.filter((c) => disciplines.some((d) => !!c[d]))
                        .map((c) => disciplines.map((d) => d != "team" && !!c[d]).filter(Boolean).length)
                        .reduce((p, c) => p + 60 + Math.max(0, c - 3) * 15, 0)
                      ).reduce((p, c) => p + c, 0)} €)</div>
                    </div>`;
                  }
                : null,
          },
        ]}
      />
      ${state.session.competition.admins.map((x) => x.id).includes(state.session.user.id) && false
        ? html`<p>
            ${_`Needed certificates`}:
            ${this.categories?.reduce(
              (total, d) =>
                total +
                d.reduce(
                  (p, c) => p + Math.min(3, c.cardinality) * disciplineCertificateFactor[c.category.discipline],
                  0
                ),
              0
            ) + this.totalCompetitors}
          </p>`
        : null}`;
  }
);
