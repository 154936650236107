import { Light, LitElement, html } from "../lit.js";
import { httpRequest, last } from "../util.js";
import { _ } from "../i18n.js";

import { clsx } from "../lib/clsx.js";

import "../widgets/Form.js";
import "../widgets/Input.js";
import "../widgets/Table.js";

customElements.define(
  "jjcm-admin-users",
  class extends Light(LitElement) {
    abortController = new AbortController();

    constructor() {
      super();
      this.updateUsers();
    }

    updateUsers = () => {
      httpRequest("/users", {
        signal: this.abortController.signal,
      })
        .then((response) => response.json())
        .then((data) => (this.users = data))
        .then(() => this.requestUpdate())
        .catch(console.error);
    };

    render = () => html`<h1>${_`Users`}</h1>
      <x-table
        tableClass="table table-striped table-hover table-bordered"
        .data=${this.users}
        .columns=${[
          {
            header: _`Username`,
            accessor: "username",
            sortable: true,
            filterable: true,
          },
          {
            header: _`Name`,
            accessor: "name",
            sortable: true,
            filterable: true,
          },
          {
            header: _`Last seen`,
            accessor: "last_seen",
            sortable: true,
          }
        ]}
      />`;
  }
);
