import { makeAutoObservable } from "./lib/mobx.js";

import { httpRequest } from "./util.js";

class State {
  constructor() {
    this.loading = true;
    this.session = undefined;
    this.competitions = undefined;
    this.checkAuth();
    makeAutoObservable(this);
  }

  abortController = new AbortController();

  checkAuth = () =>
    httpRequest(
      "/auth?rel=user;rel=must_change_password;rel=competition;rel=nations;rel=admins;rel=groups;rel=score_keepers",
      this.abortController
    )
      .then(async (response) => {
        this.session = response.ok ? await response.json() : null;
        if (this.session && !this.session.competition_id) {
          httpRequest("/competitions?rel=admins", this.abortController).then(async (response) => {
            this.competitions = response.ok ? await response.json() : null;
          });
        }
        this.loading = false;
      })
      .catch((error) => {
        console.error("Could not check authentication: ", error);
        this.session = null;
        this.competitions = null;
      });

  logOut = () => httpRequest("/auth", { method: "DELETE" }).then(this.checkAuth).catch(console.log);
}

export const state = new State();
