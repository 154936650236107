import { html as lithtml } from "./lib/lit.js";

const parser = new DOMParser();
const parseHTML = (str) => parser.parseFromString(str, "text/html").body.innerHTML;

const literals = new Map();

export const html = (strings, ...values) => {
  const delimiter = "_$value$_";
  if (!literals.get(strings)) {
    literals.set(
      strings,
      strings
        .join(delimiter)
        .split(/ ?\/>/)
        .map((x) => {
          let i = x.length;
          do {
            i = x.substr(0, i).lastIndexOf("<");
          } while ((x.substr(i + 1).match(/"/g) || []).length % 2);
          const tag = x.substr(i).match(/<([\w-]+)/);
          if (tag) {
            const closedTag = `<${tag[1]}></${tag[1]}>`;
            return parseHTML(closedTag) === closedTag ? `${x}></${tag[1]}>` : `${x}/>`;
          }
          return x;
        })
        .join("")
        .split(delimiter)
    );
    literals.get(strings).raw = true;
  }
  return lithtml(literals.get(strings), ...values);
};

export const Light = (Element) =>
  class extends Element {
    createRenderRoot = () => this;
  };

export { css, nothing } from "./lib/lit.js";
export { MobxLitElement as LitElement } from "./lib/@adobe/lit-mobx.js";
