import { _ } from "./i18n.js";

export const uiURL = (path) => {
  if (typeof process.env.UI_URL_PREFIX !== "undefined") {
    return process.env.UI_URL_PREFIX + path;
  }
  // if (typeof process.env.UI_PORT !== "undefined") {
  //   let origin = window.location.origin.replace(
  //     /:\d+$/,
  //     ":" + process.env.UI_PORT
  //   );
  //   return origin + path;
  // }
  return path;
};

export const backendURL = (path) => {
  if (/^https?:\/\//.test(path)) return path;
  if (typeof process.env.BACKEND_URL_PREFIX !== "undefined") {
    return process.env.BACKEND_URL_PREFIX + path;
  }
  if (typeof process.env.BACKEND_PORT !== "undefined") {
    let origin = window.location.origin.replace(/:\d+$/, ":" + process.env.BACKEND_PORT);
    return origin + path;
  }
  return path;
};

export const httpRequest = (url, parameters) =>
  fetch(backendURL(url), {
    credentials: "include",
    headers: { Accept: "application/json" },
    ...parameters,
  });

export const createWebSocket = (url) => new WebSocket(backendURL(url).replace(/^http/, "ws"));

export const uuid = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );

export const isDefined = (value) => value != null;

export const isBoolean = (value) => Object.prototype.toString.call(value) === "[object Boolean]";

export const isNumber = (value) => !isNaN(+value);

export const isString = (value) => Object.prototype.toString.call(value) === "[object String]";

export const isDate = (value) => Object.prototype.toString.call(value) === "[object Date]" && !isNaN(value.getTime());

export const isObject = (x) => typeof x === "object" && x !== null;

export const deepEquals = (a, b) => {
  if (!isObject(a) || !isObject(b)) return a === b;
  if (a === b) return true;
  if (Object.keys(a).length !== Object.keys(b).length) return false;
  for (const key in a) {
    if (!key in b || !deepEquals(a[key], b[key])) return false;
  }
  for (const key in b) {
    if (!key in a) return false;
  }
  return true;
};

export const unique = (values) => values.filter((x, i) => values.findIndex((y) => deepEquals(x, y)) == i);

export const range = (begin, end) => [...Array(end - begin).keys()].map((x) => begin + x);

export const transformData = (data, transform) => {
  if (isObject(data)) {
    Object.keys(data).map((k) => (data[k] = transformData(data[k], transform)));
    return data;
  }
  if (Array.isArray(data)) {
    return data.map((x) => transformData(x, transform));
  }
  return transform(data);
};

export const trimStrings = (data) => transformData(data, (d) => (typeof d === "string" ? d.trim() : d));

export const replaceEmptyStringsByNull = (data) => transformData(data, (d) => (d === "" ? null : d));

export const parseDateFromString = (value) => {
  let m = value.match(/^(\d+).(\d+).(\d{4})$/);
  if (m) return new Date(m[3], m[2] - 1, m[1]);
  return new Date(Date.parse(value));
};

export const waitFor = (conditional) => {
  const poll = (resolve) => {
    if (conditional()) resolve();
    else window.setTimeout((_) => poll(resolve), 100);
  };
  return new Promise(poll);
};

export const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const toBase64 = (data) => {
  if (!ArrayBuffer.isView(data) && !(data instanceof ArrayBuffer)) {
    if (isObject(data)) {
      Object.keys(data).map((k) => (data[k] = toBase64(data[k])));
      return data;
    }
    if (Array.isArray(data) && !ArrayBuffer.isView(data) && !data instanceof ArrayBuffer) {
      return data.map((x) => toBase64(x));
    }
  }
  return window.btoa(String.fromCharCode(...new Uint8Array(data)));
};

export const fromBase64 = (data) =>
  transformData(data, (d) => {
    const string = window.atob(d);
    const buffer = new ArrayBuffer(string.length);
    const bufferView = new Uint8Array(buffer);
    for (let i = 0; i < string.length; i++) {
      bufferView[i] = string.charCodeAt(i);
    }
    return buffer;
  });

export const accessPathInObject = (object, path) => {
  console.assert(isObject(object), "Object must be a valid object!");
  console.assert(path.length, "Path cannot be empty!");
  if (isString(path)) path = path.split(".");
  const [p, ...rest] = path;
  const m = p.match(/(.*)\[(\d+)\]$/);
  if (m) {
    if (!Array.isArray(object[m[1]])) object[m[1]] = [];
    try {
      range(object[m[1]].length, m[2]).forEach((i) => (object[m[1]][i] = object[m[1]][i] || undefined));
    } catch (RangeError) {}
    if (rest.length) {
      if (object[m[1]][m[2]] == undefined) object[m[1]][m[2]] = {};
      return accessPathInObject(object[m[1]][m[2]], rest);
    }
    object[m[1]][m[2]] = object[m[1]][m[2]] || undefined;
    return {
      get: () => object[m[1]][m[2]],
      set: (value) => (object[m[1]][m[2]] = value),
      delete: () => (object[m[1]][m[2]] = undefined),
    };
  }
  if (rest.length) {
    if (object[p] == undefined) object[p] = {};
    return accessPathInObject(object[p], rest);
  }
  object[p] = object[p] || undefined;
  return { get: () => object[p], set: (value) => (object[p] = value), delete: () => delete object[p] };
};

export const last = (array) => array[array.length - 1];

export const assert = (expr, message) => {
  if (!Boolean(expr)) throw Error(message || _`Unknown assertion error!`);
};

export const notifyUser = (title, options) =>
  new Promise((resolve) =>
    resolve(Notification.permission === "default" ? Notification.requestPermission() : Notification.permission)
  )
    .then((permission) => assert(permission === "granted", _`Permission for user notifications denied!`))
    .then(() => new Notification(title, options))
    .catch((error) => console.error(error.message));

export const shuffle = (array) => {
  for (let i = array.length - 1; i > 0; --i) {
    let k = Math.floor(Math.random() * (i + 1));
    [array[i], array[k]] = [array[k], array[i]];
  }
};
