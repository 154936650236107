import { Light, LitElement, html } from "./lit.js";

import { state } from "./state.js";
import { uiURL, httpRequest } from "./util.js";
import "./router.js";

import "./components/LogIn.js";
import "./components/Competitions.js";
import "./components/NavBar.js";
import "./components/Dashboard.js";
// import "./components/Settings.js";
import "./components/ChangePassword.js";
import "./components/Categories.js";
import "./components/Competitors.js";
import "./components/Entries.js";
import "./components/Orders.js";
import "./components/Officials.js";
import "./components/AdminUsers.js";
import "./components/AdminDojos.js";
import "./components/AdminNations.js";
import "./components/DojosByNation.js";
import "./components/Scheduling.js";
import "./components/ScoreKeeper.js";

import { version } from "../package.json";

import "./css/style.css";

import { configureLocale, selectLocale, _ } from "./i18n.js";

import en_GB from "../locales/en_GB.json";
import de_DE from "../locales/de_DE.json";
import de_WK from "../locales/de_WK.json";
import nl_BE from "../locales/nl_BE.json";
import da_DK from "../locales/da_DK.json";
import it_IT from "../locales/it_IT.json";
// import el_GR from "../locales/el_GR.json";
// import hu_HU from "../locales/hu_HU.json";
configureLocale("en-gb", {
  locale: "en-gb",
  currency: "GBP",
  direction: "ltr",
  name: "English (UK)",
  flag: "🇬🇧",  
  translations: en_GB,
});
configureLocale("de-de", {
  locale: "de-de",
  currency: "EUR",
  direction: "ltr",
  name: "Deutsch",
  flag: "🇩🇪",
  translations: de_DE,
});
// configureLocale("de-wk", {
//   locale: "de-wk",
//   currency: "EUR",
//   direction: "ltr",
//   name: "Deutsch (SJW)",
//   flag: "🏳️‍🌈",
//   translations: de_WK,
// });
configureLocale("nl-be", {
  locale: "nl-be",
  currency: "EUR",
  direction: "ltr",
  name: "Nederlands",
  flag: "🇧🇪",
  translations: nl_BE,
});
configureLocale("da-dk", {
  locale: "da-dk",
  currency: "EUR",
  direction: "ltr",
  name: "Dansk",
  flag: "🇩🇰",
  translations: da_DK,
});
configureLocale("it-it", {
  locale: "it-it",
  currency: "EUR",
  direction: "ltr",
  name: "Italiano",
  flag: "🇮🇹",
  translations: it_IT,
});
// configureLocale("el-gr", {
//   locale: "el-gr",
//   currency: "EUR",
//   direction: "ltr",
//   name: "Ελληνικά",
//   flag: "🇬🇷",
//   translations: el_GR,
// });
// configureLocale("hu-hu", {
//   locale: "hu-hu",
//   currency: "EUR",
//   direction: "ltr",
//   name: "Magyar",
//   flag: "🇭🇺",
//   translations: hu_HU,
// });

const locale = window.localStorage.getItem("locale");
selectLocale(locale ? locale : "en-gb");
if (locale) selectLocale(locale);
//selectLocale("de-de");

customElements.define(
  "fnet-jjcm",
  class extends Light(LitElement) {
    // static properties = {
    //   // menuOpen: { type: Boolean, reflect: false },
    // };
    // constructor() {
    //   super();
    // }

    render = () =>
      !state.loading
        ? state.session?.competition?.score_keepers.map((x) => x.id).includes(state.session.user_id)
          ? html`<jjcm-score-keeper />`
          : /*html`<x-router
            class="d-flex flex-fill flex-column"
            .routes=${{
              "/blubb": () => html`<jjcm-competition-runner />`,
            }}
            .notfound=${() =>*/ html`<jjcm-navbar />
              <div class="d-flex flex-fill">
                ${state.session?.competition
                  ? html`<sidebar>
                      <ul class="nav flex-column">
                        <li class="nav-item">
                          <a class="nav-link" href="/" @click=${this.activateLink}>${_`Dashboard`}</a>
                        </li>
                        ${state.session.competition?.admins.map((x) => x.id).includes(state.session.user_id)
                          ? html`<!--li class="nav-item">
                              <a class="nav-link" href="/settings" @click=${this.activateLink}>${_`Settings`}</a>
                            </li-->
                              <li class="nav-item">
                                <a class="nav-link" href="/categories" @click=${this.activateLink}>${_`Categories`}</a>
                              </li>
                              <!--li class="nav-item">
                                <a class="nav-link" href="/scheduling" @click=${this.activateLink}>${_`Scheduling`}</a>
                              </li>
                              <li class="nav-item">
                                <a class="nav-link" href="/run_competition" @click=${this.activateLink}>${_`Run competition`}</a>
                              </li-->`
                          : null}
                        <li class="nav-item">
                          <a class="nav-link" href="/competitors" @click=${this.activateLink}>${_`Competitors`}</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" href="/entries" @click=${this.activateLink}>${_`Entries`}</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" href="/orders" @click=${this.activateLink}>${_`Orders`}</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" href="/officials" @click=${this.activateLink}>${_`Officials`}</a>
                        </li>
                        ${state.session.competition?.nations.some((n) => n.admins.map((x) => x.id).includes(state.session.user_id))
                            ? html `<li class="nav-item">
                            <a class="nav-link" href="/dojos" @click=${this.activateLink}>${_`Dojos`}</a>
                          </li>`
                          : null}
                        ${state.session.user.groups.map((x) => x.name).includes("admin")
                          ? html`<li>${_`Admin`}<ul><li class="nav-item">
                                <a class="nav-link" href="/admin/users" @click=${this.activateLink}>${_`Users`}</a>
                              </li>
                              <li class="nav-item">
                                <a class="nav-link" href="/admin/dojos" @click=${this.activateLink}>${_`Dojos`}</a>
                              </li>
                              <li class="nav-item">
                                <a class="nav-link" href="/admin/nations" @click=${this.activateLink}>${_`Nations`}</a>
                              </li></ul></li>`
                          : null}
                      </ul>
                    </sidebar>`
                  : null}
                <main class="flex-fill">
                  ${state.session?.user.must_change_password === false
                    ? state.session?.competition_id
                      ? html`<x-router
                          .routes=${{
                            "/": () => html`<div class="container"><jjcm-dashboard /></div>`,
                            "/change_password": () =>
                              html`<div class="container d-grid h-100"><jjcm-change-password /></div>`,
                            "/settings": () => html`<div class="container"><jjcm-settings /></div>`,
                            "/categories": () => html`<div class="container"><jjcm-categories /></div>`,
                            "/competitors": () => html`<div class="container"><jjcm-competitors /></div>`,
                            "/entries": () => html`<div class="container"><jjcm-entries /></div>`,
                            "/orders": () => html`<div class="container"><jjcm-orders /></div>`,
                            "/officials": () => html`<div class="container"><jjcm-officials /></div>`,
                            "/dojos": () => html`<div class="container"><jjcm-dojos-by-nation /></div>`,
                            "/admin/users": () => html`<div class="container"><jjcm-admin-users /></div>`,
                            "/admin/dojos": () => html`<div class="container"><jjcm-admin-dojos /></div>`,
                            "/admin/nations": () => html`<div class="container"><jjcm-admin-nations /></div>`,
                            "/scheduling": () => html`<div class="container"><jjcm-scheduling /></div>`,
                            "/run_competition": () => html`<div class="container"><jjcm-competition-table /></div>`,
                          }}
                        />`
                      : html`<div class="container"><jjcm-competitions /></div>`
                    : html`<jjcm-login class="border rounded shadow p-5" />`}
                </main>
              </div>
              <footer class="page-footer bg-primary">
                Ju Jitsu Competition Manager v${version} (${_`built ${process.env.buildTime}:datetime`})
              </footer>` /*}
          />`*/
        : html`Loading`;
  }
);
