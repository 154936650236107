import { Light, LitElement, html } from "../lit.js";
import { httpRequest, last } from "../util.js";
import { _ } from "../i18n.js";

import { clsx } from "../lib/clsx.js";

import "../widgets/Form.js";
import "../widgets/Input.js";
import "../widgets/Table.js";

customElements.define(
  "jjcm-admin-nations",
  class extends Light(LitElement) {
    abortController = new AbortController();

    constructor() {
      super();
      this.updateNations();
    }

    updateNations = () => {
      httpRequest("/nations?rel=admins", {
        signal: this.abortController.signal,
      })
        .then((response) => response.json())
        .then((data) => (this.nations = data))
        .then(() => this.requestUpdate())
        .catch(console.error);
    };

    render = () => html`<h1>${_`Nations`}</h1>
      <x-table
        tableClass="table table-striped table-hover table-bordered"
        .data=${this.nations}
        .columns=${[
          {
            header: _`Name`,
            accessor: "name",
            sortable: true,
            filterable: true,
          },
          {
            header: _`Admins`,
            render: (r) => html`${r.admins.map(x => x.username).join(", ")}`
          }
        ]}
      />`;
  }
);
