import { Light, LitElement, html } from "../lit.js";
import { httpRequest } from "../util.js";
import { _ } from "../i18n.js";

import { state } from "../state.js";

import "./LanguageSelector.js";

customElements.define(
  "jjcm-navbar",
  class extends Light(LitElement) {
    logOut = () =>
      httpRequest("/auth", {
        method: "DELETE",
      }).then(() => state.checkAuth());

    activateLink = (e) => {
      window.history.pushState(null, null, e.target.href);
      dispatchEvent(new PopStateEvent("popstate"));
      e.preventDefault();
    };

    render = () =>
      state.session
        ? html`<nav class="navbar navbar-expand-lg navbar-light bg-light shadow">
            <div class="container-fluid">
              <a class="navbar-brand" href="#">
                <img src="logo.png" alt="JJCM" height="30" class="d-inline-block align-text-top me-2" />
              </a>
              <h1 class="navbar-nav">${state.session?.competition?.name}</h1>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">
                <span class="navbar-toggler-icon" />
              </button>
              <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav ms-auto">
                  <jjcm-language-selector />
                </ul>
                <ul class="navbar-nav">
                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">
                      <img src="avatar.jpg" alt="${_`Avatar`}" width="30" height="30" class="rounded-circle" />
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <!--li><a class="dropdown-item" href="#">${_`Preferences`}</a></li-->
                      <li><a class="dropdown-item" href="/change_password">${_`Change password`}</a></li>
                      <!--li><hr class="dropdown-divider" /></li-->
                      <li><a class="dropdown-item" href="#" @click=${this.logOut}>${_`Log out`}</a></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </nav>`
        : null;
  }
);
