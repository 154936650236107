import { Light, LitElement, html } from "../lit.js";
import { httpRequest, range, unique } from "../util.js";
import { _ } from "../i18n.js";
import { state } from "../state.js";
import Schema from "../validation.js";

import { clsx } from "../lib/clsx.js";

import "../widgets/Form.js";
import "../widgets/Input.js";
import "../widgets/Table.js";
import { renderAgeAndRankClassCategory, getSortableCategoryName } from "./Categories.js";
import { sexInfo } from "./Sex.js";
import { ranks } from "./Rank.js";

customElements.define(
  "jjcm-orders",
  class extends Light(LitElement) {
    abortController = new AbortController();
    constructor() {
      super();
      this.orderables = [];
      this.orders = [];
      Promise.all([
        httpRequest(`/competitions/${state.session.competition_id}/dojos`, {
          signal: this.abortController.signal,
        }),
        httpRequest(`/competitions/${state.session.competition_id}/settings/orderable`, {
          signal: this.abortController.signal,
        }),
        httpRequest(`/competitions/${state.session.competition_id}/orders`, {
          signal: this.abortController.signal,
        }),
      ])
        .then((response) => Promise.all(response.map((r) => r.json())))
        .then(([dojos, orderables, orders]) => {
          this.orderables = orderables;
          this.dojos = dojos;
          this.orders = Object.fromEntries(
            this.dojos
              .map((d) =>
                orderables.map((o) => {
                  const order = [
                    ...orders.filter((x) => x.orderable_id == o.id && x.dojo_id == d.id),
                    { count: 0 },
                  ].map((x) => ({ id: x.id, count: x.count }))[0];
                  return [`order_${d.id}_${o.id}`, { ...order, dojo_id: d.id, orderable_id: o.id }];
                })
              )
              .reduce((c, p) => [...p, ...c])
          );
          this.requestUpdate();
        })
        .catch(console.error);
    }

    submit = (e) => {
      httpRequest(`/competitions/${state.session.competition_id}/orders`, {
        signal: AbortSignal.signal,
        method: "POST",
        body: JSON.stringify(
          Object.entries(e.target.values).map(([k, v]) => ({ ...this.orders[k], count: Number(v) }))
        ),
      })
        .then((response) => response.ok && response)
        .then((data) => {
          this.requestUpdate();
        })
        .catch((e) => {
          console.error(e);
          e.target.submittingDone();
        });
    };

    render = () => html`<h1>${_`Orders`}</h1>
      <x-form
        @submit=${this.submit}
        .validate=${(values) =>
          Schema.Struct(Object.fromEntries(Object.keys(this.orders).map((k) => [k, Schema.Number()]))).validate(values)}
        .values=${Object.fromEntries(Object.entries(this.orders).map(([k, v]) => [k, v.count]))}
        .renderContent=${({ values, errors, touched, submitting }) => {
          const unappliedChanges = Object.entries(values).some(([k, v]) => this.orders[k].count != v);
          return html`<x-table
              tableClass="table table-striped table-hover table-bordered"
              .data=${this.dojos}
              .columns=${[
                ...(this.dojos?.length != 1
                  ? [
                      {
                        header: _`Dojo`,
                        accessor: "name",
                      },
                    ]
                  : []),
                ...this.orderables?./*filter((o) => o.id != 24 && o.id != 25).*/map((o) => ({
                  header: html`<div class="text-center text-nowrap">${o.name}</div>
                    <div class="text-center text-nowrap">(${o.price} €)</div>`,
                  render: (x) => {
                    const key = `order_${x.id}_${o.id}`;
                    return html`<div class="row">
                      <div class="col">
                        <input
                          class="${clsx("form-control", { "is-invalid": touched[key] && errors[key] })}"
                          name="${key}"
                          value="${values[key]}"
                          readonly
                        />
                        <div class="invalid-feedback">${errors[key]}</div>
                      </div>
                      <div class="col">(${(values[key] * o.price).toFixed(2)} €)</div>
                    </div>`;
                  },
                  renderFooter:
                  this.dojos?.length != 1
                    ? (data) => {
                        const n = Object.values(this.orders).map((v) => v).filter((x) => x.orderable_id == o.id).map((x) => x.count).reduce((p, c) => p + c);
                        return html`<div class="row fw-bold"><div class="col text-end">${n}</div><div class="col">(${n * o.price} €)</div></div>`;
                      }
                    : null,
                    })),
                {
                  header: _`Total`,
                  render: (x) =>
                    html`${this.orderables
                      .map((o) => values[`order_${x.id}_${o.id}`] * o.price)
                      .reduce((p, c) => p + c, 0)
                      .toFixed(2)}
                    €`,
                  renderFooter:
                    this.dojos?.length != 1
                      ? (data) => {
                          const n = Object.values(this.orders).map((x) => x.count * this.orderables.find((o) => o.id == x.orderable_id)?.price).reduce((p, c) => p + c, 0);
                          return html`<div class="fw-bold">${n} €</div>`;
                        }
                      : null,
                },
              ]}
            />
            <div class="d-flex justify-content-end">
              <!--button
                class="${clsx("m-2", "me-0", "btn", {
                "btn-danger": unappliedChanges,
                "btn-success": !unappliedChanges,
              })}"
                type="submit"
                ?disabled=${submitting || !unappliedChanges}
              >
                ${unappliedChanges ? _`Apply changes` : _`All changes applied`}
              </button-->
            </div>`;
        }}
      />`;
  }
);
