import { Light, LitElement, html } from "../lit.js";
import { _, selectLocale, currentLocale, availableLocales } from "../i18n.js";

customElements.define(
  "jjcm-language-selector",
  class extends Light(LitElement) {
    selectLocale = (locale) => {
      selectLocale(locale);
      window.localStorage.setItem("locale", locale);
    };

    render = () => html`<li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">${currentLocale().flag}</a>
      <ul class="dropdown-menu dropdown-menu-end">
        ${availableLocales()
          .sort((x) => x.name)
          .map(
            (x) => html`<li>
              <a class="dropdown-item" href="#" @click=${() => this.selectLocale(x.locale)}> ${x.flag} ${x.name} </a>
            </li>`
          )}
      </ul>
    </li>`;
  }
);
