import { Light, LitElement, html } from "../lit.js";
import { httpRequest, range, unique } from "../util.js";
import { _ } from "../i18n.js";
import { state } from "../state.js";
import { clsx } from "../lib/clsx.js";

import { sexInfo } from "./Sex.js";
import { ranks } from "./Rank.js";
import "./RankClass.js";

// const firstAndLast = (array) => [array[0], array[array.length - 1]];

export const renderAgeAndRankClassCategory = (category) => {
  if (!category.age_class || !category.rank_class) {
    return html`<pre>${JSON.stringify(category, null, 2)}</pre>`;
  }
  // return html`${category.age_class.length > 1 ? category.name.split(";")[0] : category.age_class.map((x) => x.name).join("/")},
  // ${firstAndLast(category.rank_class
  return html`${category.age_class.map((x) => x.name).join("/")},
  ${category.rank_class
      .sort(
      (a, b) =>
        a.ranks.map((r) => ranks.get(r.id).value).sort()[0] - b.ranks.map((r) => ranks.get(r.id).value).sort()[0]
    )
    .map(
      (x) => html`<span style="display: inline-block; padding: 0 0.5em;">
        <jjcm-rank-class .config=${x} />
      </span>`
    )
    .reduce((p, c) => [p, "/", c])}`;
    // ).reduce((p, c) => [p, category.rank_class.length > 2 ? " - " : "/", c])}`;
};

export const getSortableCategoryName = (category) => {
  let keys = [];
  if (category.sex[0]) {
    keys.push(category.sex.sort()[0]);
  }
  if (category.age_class[0]) {
    keys.push(category.age_class.sort((a, b) => a.min - b.min)[0].min);
  }
  if (category.weight_class[0]) {
    keys.push(category.weight_class.sort((a, b) => a.age_class.min - b.age_class.min)[0].age_class.min);
    keys.push(category.weight_class.sort((a, b) => a.sex - b.sex)[0].sex);
    keys.push(category.weight_class.sort((a, b) => a.min - b.min)[0].min);
  }
  if (category.rank_class[0]) {
    keys.push(category.rank_class.map((x) => x.ranks.map((x) => ranks.get(x.id).value).sort()[0]).sort()[0]);
  }
  if (category.rank[0]) {
    keys.push(category.rank.map((x) => ranks.get(x).value).sort()[0]);
  }
  return keys.map((x) => x.toString().padStart(3, "0")).join("-") + category.name;
};

export const disciplineTitle = (key) =>
  ({
    random_attack: _`Random Attack`,
    RandomAttack: _`Random Attack`,
    ground_fighting: _`Ground Fighting`,
    GroundFighting: _`Ground Fighting`,
    ground_fighting_open: _`Ground Fighting (Open Class)`,
    GroundFightingOpen: _`Ground Fighting (Open Class)`,
    sparring: _`Sparring`,
    Sparring: _`Sparring`,
    sparring_open: _`Sparring (Open Class)`,
    SparringOpen: _`Sparring (Open Class)`,
    continuous_fighting: _`Continuous Fighting`,
    ContinuousFighting: _`Continuous Fighting`,
    continuous_fighting_open: _`Continuous Fighting (Open Class)`,
    ContinuousFightingOpen: _`Continuous Fighting (Open Class)`,
    pairs: _`Pairs`,
    Pairs: _`Pairs`,
    kata: _`Kata`,
    Kata: _`Kata`,
    empty_hand_kata: _`Empty Hand Kata`,
    EmptyHandKata: _`Empty Hand Kata`,
    weapons_kata: _`Weapons Kata`,
    WeaponsKata: _`Weapons Kata`,
    two_person_empty_hand_kata: _`Two Person Empty Hand Kata`,
    TwoPersonEmptyHandKata: _`Two Person Empty Hand Kata`,
    two_person_weapons_kata: _`Two Person Weapons Kata`,
    TwoPersonWeaponsKata: _`Two Person Weapons Kata`,
    team: _`Team`,
    Team: _`Team`,
  }[key]);

customElements.define(
  "jjcm-categories-list",
  class extends Light(LitElement) {
    abortController = new AbortController();

    static properties = {
      discipline: { type: String },
      config: { type: Object },
    };

    constructor() {
      super();
      this.categories = [];
      this.max = {};
      this.modal = null;
      this.matchesDialog = null;
      this.switchCategoryDialog = null;
      document.addEventListener("hidden.bs.modal", () => {
        this.matchesDialog = null;
        this.switchCategoryDialog = null;
        this.modal = null;
        this.requestUpdate();
      });
    }

    firstUpdated = () => this.updateCategories();

    updateCategories = () =>
      httpRequest(
        `/competitions/${state.session.competition_id}/${
          this.discipline
        }/categories?rel=ranks;rel=age_class;rel=dojo;rel=entries;rel=${{
          random_attack: ["competitor"],
          ground_fighting: ["competitor"],
          ground_fighting_open: ["competitor"],
          sparring: ["competitor"],
          sparring_open: ["competitor"],
          continuous_fighting: ["competitor"],
          continuous_fighting_open: ["competitor"],
          pairs: ["competitor_a", "competitor_b"],
          // kata: ["tori"],
          empty_hand_kata: ["competitor"],
          weapons_kata: ["competitor"],
          two_person_empty_hand_kata: ["tori"],
          two_person_weapons_kata: ["tori"],
          team: ["members"],
        }[this.discipline].join(";rel=")}`,
        {
          signal: this.abortController.signal,
        }
      )
        .then((response) => response.json())
        .then((categories) => {
          this.categories = categories;
          ["cardinality", "sigma_age", "sigma_weight", "sigma_rank"].forEach((k) => {
            this.max[k] = Math.max(...this.categories.map((x) => x[k]));
          });
          this.requestUpdate();
        })
        .catch(console.error);

    instantiateCategory = (category) => {
      httpRequest(`/competitions/${state.session.competition_id}/${this.discipline}/categories`, {
        signal: this.abortController.signal,
        method: "POST",
        body: JSON.stringify(category),
      })
        .then((response) => response.ok)
        .then(() => this.updateCategories())
        .catch(console.error);
    };

    createAggregation = (members) =>
      httpRequest(`/competitions/${state.session.competition_id}/${this.discipline}/categories`, {
        signal: this.abortController.signal,
        method: "POST",
        body: JSON.stringify(members),
      })
        .then((response) => response.ok)
        .then(() => this.updateCategories())
        .catch(console.error);

    deleteCategory = (id) =>
      httpRequest(`/competitions/${state.session.competition_id}/${this.discipline}/categories/${id}`, {
        signal: this.abortController.signal,
        method: "DELETE",
      })
        .then((response) => response.ok)
        .then(() => this.updateCategories())
        .catch(console.error);

    showSwitchCategoryDialog = (entries, categories) => {
      this.switchCategoryDialog = { entries: entries, categories: categories };
      this.requestUpdate();
    };

    switchCategory = (entries, category) =>
      Promise.all(
        entries.map((e) =>
          httpRequest(`/competitions/${state.session.competition_id}/${this.discipline}/entries/${e.id}`, {
            signal: this.abortController.signal,
            method: "PATCH",
            body: JSON.stringify({ override_category_id: category.id }),
          })
        )
      )
        .then((responses) => Promise.all(responses.map((r) => r.ok)))
        .then(() => {
          this.modal.hide();
          this.updateCategories();
        })
        .catch(console.error);

    showInitializeMatchesDialog = (category) => {
      this.matchesDialog = category;
      this.requestUpdate();
    };

    initializeMatches = (categoryId, config) =>
      httpRequest(`/competitions/${state.session.competition_id}/${this.discipline}/categories/${categoryId}`, {
        signal: this.abortController.signal,
        method: "POST",
        body: JSON.stringify(config),
      })
        .then((response) => response.ok)
        .then(() => {
          this.modal.hide();
          this.updateCategories();
        })
        .catch(console.error);

    renderStatisticalValue = (data, key) => html`<div class="progress">
      <div
        class="${clsx("progress-bar", { "bg-danger": data.cardinality == 1 })}"
        style="width: ${((100 * data[key]) / this.max[key]).toFixed(0)}%;"
      >
        ${data[key].toFixed(key === "cardinality" ? 0 : 2)}
      </div>
    </div>`;

    render = () => {
      if (!this.modal) {
        this.getUpdateComplete().then(() => {
          const modal = document.querySelector(".modal");
          if (modal) this.modal = new bootstrap.Modal(modal);
          if (this.modal) this.modal.show();
        });
      }
      return html`<x-table
          tableClass="table table-striped table-hover table-bordered"
          .data=${this.categories}
          .sort=${[[0, "asc"]]}
          .columns=${[
            {
              header: _`Category`,
              sortable: true,
              filterable: true,
              accessor: (x) => getSortableCategoryName(x.category),
              render: (x) => (this.config.renderCategory ? this.config.renderCategory(x.category) : x.category.name),
            },
            {
              header: _`Entries`,
              class: "w-10",
              sortable: true,
              accessor: "cardinality",
              render: (x) => this.renderStatisticalValue(x, "cardinality"),
            },
            ...(this.categories[0] && "sigma_age" in this.categories[0]
              ? [
                  {
                    header: _`Age`,
                    class: "w-10",
                    sortable: true,
                    accessor: "sigma_age",
                    render: (x) =>
                      x.min_age != x.max_age
                        ? html`<!--div>${x.ages.sort().join(" ")}</div-->
                            ${this.renderStatisticalValue(x, "sigma_age")}
                            <div>${x.min_age} - ${x.max_age}</div>`
                        : html`${x.min_age}`,
                  },
                ]
              : []),
            ...(this.categories[0] && "sigma_weight" in this.categories[0]
              ? [
                  {
                    header: _`Weight`,
                    class: "w-10",
                    sortable: true,
                    accessor: "sigma_weight",
                    render: (x) =>
                      x.min_weight != x.max_weight
                        ? html`${this.renderStatisticalValue(x, "sigma_weight")}
                            <div>${x.min_weight.toFixed(1)} kg - ${x.max_weight.toFixed(1)} kg</div>`
                        : html`${x.min_weight.toFixed(1)} kg`,
                  },
                ]
              : []),
            ...(this.categories[0] && "sigma_rank" in this.categories[0]
              ? [
                  {
                    header: _`Rank`,
                    class: "w-10",
                    sortable: true,
                    accessor: "sigma_rank",
                    render: (x) =>
                      x.min_rank != x.max_rank
                        ? html`<!--${this.renderStatisticalValue(x, "sigma_rank")}-->
                            <x-rank value="${ranks.get(x.min_rank)}" /><x-rank value="${ranks.get(x.max_rank)}" />`
                        : html`<x-rank value="${ranks.get(x.min_rank)}" />`,
                  },
                ]
              : []),
            ...(state.session.user.groups.map((x) => x.name).includes("admin")
              ? [
                  {
                    class: "position-relative",
                    render: (x) => {
                      if ("id" in x.category) {
                        return html`<button class="btn btn-danger" @click=${() => this.deleteCategory(x.category.id)}>
                            ${x.category.rank_class?.length > 1 ? _`Split` : _`Delete`}</button
                          >${!x.category.has_games
                            ? html`<button
                                class="ms-2 btn btn-warning"
                                @click=${() => this.showInitializeMatchesDialog(x)}
                              >
                                ${_`Initialize matches`}
                              </button>`
                            : null}`;
                      } else {
                        const aggregationMembers = [
                          x.category,
                          ...this.categories
                            .map((c) => c.category)
                            .filter(
                              (c) =>
                                c.age_class?.length &&
                                c.age_class?.map((p) => p.id)[0] === x.category.age_class?.map((c) => c.id)[0] &&
                                c.rank_class?.length &&
                                c.rank_class?.map((p) => p.id)[0] === x.category.rank_class?.map((c) => c.id + 1)[0] &&
                                !("id" in x.category || "id" in c)
                            ),
                        ];
                        const alternativeCategories = this.categories
                          .map((c) => c.category)
                          .filter((c) => "id" in c)
                          // .filter(    TODO: Allow more candidates for UNJJ
                          //   (c) =>
                          //     !(c.age_class?.length > 0) ||
                          //     c.age_class?.some((a) => a.difficulty >= x.category.age_class[0].difficulty)
                          // )
                          // .filter(
                          //   (c) =>
                          //     !(c.weight_class?.length > 0) ||
                          //     c.weight_class?.some(
                          //       (w) =>
                          //         w.age_class.difficulty >= x.category.weight_class[0].age_class.difficulty &&
                          //         w.min >= x.category.weight_class[0].min &&
                          //         w.sex === x.category.weight_class[0].sex
                          //     )
                          // )
                          // .filter((c) => !(c.rank?.length > 0) || c.rank?.includes(x.category.rank[0]))
                          .map((c) => ({ id: c.id, name: c.name }));
                        return html`<button
                            class="btn btn-success"
                            @click=${() => this.instantiateCategory(x.category)}
                          >
                            ${_`Confirm`}
                          </button>
                          ${aggregationMembers.length === 2
                            ? html`<button
                                class="ms-2 btn btn-primary aggregation"
                                @click=${() => this.createAggregation(aggregationMembers)}
                              >
                                ${_`Aggregate`}
                              </button>`
                            : null}
                          ${x.cardinality === 1 && alternativeCategories.length
                            ? html`<button
                                class="ms-1 btn btn-danger"
                                @click=${() => this.showSwitchCategoryDialog(x.entries, alternativeCategories)}
                              >
                                ${_`Switch difficulty (voluntarily)`}
                              </button>`
                            : null}`;
                      }
                    },
                  },
                ]
              : []),
          ]}
        />
        ${this.switchCategoryDialog
          ? html`<div class="modal fade">
              <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                  <header class="modal-header">
                    <h3>${_`Switch difficulty`}</h3>
                  </header>
                  <main class="modal-body">
                    ${_`The following competitors must be asked and consent to switch to a higher difficulty:`}
                    <ul>
                      ${this.switchCategoryDialog.entries
                        .reduce(
                          (p, c) => [
                            ...p,
                            ...[c.competitor, c.competitor_a, c.competitor_b, c.tori, ...(c.members || [])].filter(
                              (x) => x
                            ),
                          ],
                          []
                        )
                        .map(
                          (c) =>
                            html`<li>
                              ${c.given_name} ${c.name}, ${c.dojo.name}, ${c.age_class.name},
                              <x-rank class="d-inline-block" style="width: 10em" .value=${c.rank_id} />
                              ${c.weight ? html`${c.weight.toFixed(1)} kg` : null}
                            </li>`
                        )}
                    </ul>
                    ${this.switchCategoryDialog.categories.map(
                      (c) =>
                        html`<div class="d-flex justify-content-center">
                          <button
                            class="mt-2 btn btn-primary"
                            @click=${() => this.switchCategory(this.switchCategoryDialog.entries, c)}
                          >
                            ${c.name}
                          </button>
                        </div>`
                    )}
                  </main>
                </div>
              </div>
            </div>`
          : null}
        ${this.matchesDialog
          ? html`<div class="modal fade">
              <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                  <header class="modal-header">
                    <h3>${_`Initialize matches for ${this.matchesDialog.name} in ${this.config.title}`}</h3>
                  </header>
                  <main class="modal-body">
                    <p>${_`Number of entries`}: ${this.matchesDialog.cardinality}</p>
                    <div class="d-flex justify-content-end">
                      ${this.matchesDialog.cardinality === 1 ? html`Mal schauen` : null}
                      ${this.config.gameType === "KO"
                        ? this.matchesDialog.cardinality == 2
                          ? html`<button
                              class="m-2 me-0 btn btn-primary"
                              @click=${() =>
                                this.initializeMatches(this.matchesDialog.category.id, {
                                  type: "single-elimination",
                                })}
                            >
                              Single elimination (${1} matches)
                            </button>`
                          : this.matchesDialog.cardinality == 3
                          ? html`<button
                              class="m-2 me-0 btn btn-primary"
                              @click=${() =>
                                this.initializeMatches(this.matchesDialog.category.id, {
                                  type: "round-robin",
                                })}
                            >
                              ${_`Round robin (${4} matches)`}
                            </button>`
                          : html`<button
                                class="m-2 me-0 btn btn-primary"
                                @click=${() =>
                                  this.initializeMatches(this.matchesDialog.category.id, {
                                    type: "single-elimination",
                                  })}
                              >
                                ${_`Single elimination (${this.matchesDialog.cardinality} matches)`}
                              </button>
                              <button
                                class="m-2 me-0 btn btn-primary"
                                @click=${() =>
                                  this.initializeMatches(this.matchesDialog.category.id, {
                                    type: "double-elimination",
                                  })}
                              >
                                ${_`Consolation system (${this.matchesDialog.cardinality * 2} matches)`}
                              </button>`
                        : html`<button
                            class="m-2 me-0 btn btn-primary"
                            @click=${() =>
                              this.initializeMatches(this.matchesDialog.category.id, {
                                type: "pool",
                              })}
                          >
                            Pool system
                          </button>`}
                    </div>
                  </main>
                </div>
              </div>
            </div>`
          : null}`;
    };
  }
);

customElements.define(
  "jjcm-categories",
  class extends Light(LitElement) {
    constructor() {
      super();
      this.tabs = {
        random_attack: {
          title: _`Random Attack`,
          accessor: (x) => `${x.competitor.competitor.name}, ${x.competitor.competitor.given_name}`,
          // renderCategory: renderAgeAndRankClassCategory,
          gameType: "KO",
        },
        ground_fighting: {
          title: _`Ground Fighting`,
          accessor: (x) => `${x.competitor.name}, ${x.competitor.given_name}`,
          gameType: "KO",
        },
        ground_fighting_open: {
          title: _`Ground Fighting (Open Class)`,
          accessor: (x) => `${x.competitor.name}, ${x.competitor.given_name}`,
          renderCategory: (x) => x.sex.map((s) => sexInfo[s].name()).join("/"),
          gameType: "KO",
        },
        sparring: {
          title: _`Sparring`,
          accessor: (x) => `${x.competitor.competitor.name}, ${x.competitor.competitor.given_name}`,
          gameType: "KO",
        },
        sparring_open: {
          title: _`Sparring (Open Class)`,
          accessor: (x) => `${x.competitor.name}, ${x.competitor.given_name}`,
          renderCategory: (x) => x.sex.map((s) => sexInfo[s].name()).join("/"),
          gameType: "KO",
        },
        continuous_fighting: {
          title: _`Continuous Fighting`,
          accessor: (x) => `${x.competitor.competitor.name}, ${x.competitor.competitor.given_name}`,
          gameType: "KO",
        },
        continuous_fighting_open: {
          title: _`Continuous Fighting (Open Class)`,
          accessor: (x) => `${x.competitor.name}, ${x.competitor.given_name}`,
          renderCategory: (x) => x.sex.map((s) => sexInfo[s].name()).join("/"),
          gameType: "KO",
        },
        pairs: {
          title: _`Pairs`,
          accessor: (x) =>
            `${x.competitor_a.name}, ${x.competitor_a.given_name} / ${x.competitor_b.name}, ${x.competitor_b.given_name}`,
          // renderCategory: renderAgeAndRankClassCategory,
          gameType: "Pool",
        },
        // kata: {
        //   title: _`Kata`,
        //   accessor: (x) => `${x.tori.name}, ${x.tori.given_name} / ${x.uke.name}, ${x.uke.given_name}`,
        //   gameType: "Pool",
        // },
        empty_hand_kata: {
          title: _`Empty Hand Kata`,
          accessor: (x) => `${x.competitor.name}, ${x.competitor.given_name}`,
          gameType: "Pool",
        },
        weapons_kata: {
          title: _`Weapons Kata`,
          accessor: (x) => `${x.competitor.name}, ${x.competitor.given_name}`,
          gameType: "Pool",
        },
        two_person_empty_hand_kata: {
          title: _`Two Person Empty Hand Kata`,
          accessor: (x) => `${x.tori.name}, ${x.tori.given_name} / ${x.uke.name}, ${x.uke.given_name}`,
          gameType: "Pool",
        },
        two_person_weapons_kata: {
          title: _`Two Person Weapons Kata`,
          accessor: (x) => `${x.tori.name}, ${x.tori.given_name} / ${x.uke.name}, ${x.uke.given_name}`,
          gameType: "Pool",
        },
        team: {
          title: _`Team`,
          accessor: (x) => x.members.map((m) => `${m.name}, ${m.given_name}`).join("; "),
          gameType: "Pool",
        },
      };
    }

    render = () => html`<h1>${_`Categories`}</h1>
      <ul class="nav nav-tabs">
        ${Object.entries(this.tabs).map(
          ([k, v], i) => html`<li class="nav-item">
            <button class="${clsx("nav-link", { active: !i })}" data-bs-toggle="tab" data-bs-target="#${k}">
              ${v.title}
            </button>
          </li>`
        )}
      </ul>
      <div class="tab-content border border-top-0 p-3">
        ${Object.entries(this.tabs).map(
          ([k, v], i) =>
            html`<div class="${clsx("tab-pane fade", { "show active": !i })}" id="${k}">
              <jjcm-categories-list .discipline="${k}" .config=${v} />
            </div>`
        )}
      </div>`;
  }
);
