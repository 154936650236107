import { Light, LitElement, html } from "../lit.js";
import { httpRequest, range, unique, shuffle } from "../util.js";
import { _ } from "../i18n.js";
import { state } from "../state.js";

import { clsx } from "../lib/clsx.js";

import "../widgets/Form.js";
import "../widgets/Input.js";
import "../widgets/Table.js";

import { ranks } from "./Rank.js";

import "./Match.js";

// const attacks = [
//   { age: "A", rank: "HAKKYU", title: "Handgelenk", sub: "einhändig, parallel gefasst" },
//   { age: "A", rank: "HAKKYU", title: "Handgelenk", sub: "einhändig, diagonal gefasst" },
//   { age: "A", rank: "HAKKYU", title: "Handgelenk", sub: "beidhändig, von vorne gefasst" },
//   { age: "A", rank: "HAKKYU", title: "Würgeabwehr", sub: "beidhändig, von vorne gewürgt" },
//   { age: "A", rank: "HAKKYU", title: "Würgeabwehr", sub: "beidhändig, von der Seite gewürgt" },
//   { age: "B", rank: "HAKKYU", title: "Schwingerabwehr", sub: "gegen Schwinger zum Kopf" },
//   { age: "C", rank: "HAKKYU", title: "Fauststoßabwehr", sub: "gegen geraden Fauststoß zum Kopf" },
//   { age: "C", rank: "HAKKYU", title: "Fauststoßabwehr", sub: "gegen geraden Fauststoß zum Solar Plexus" },
//   { age: "A", rank: "NANAKYU", title: "Würgeabwehr", sub: "beidhändig, von hinten gewürgt" },
//   { age: "A", rank: "NANAKYU", title: "Würgen, Bodenlage", sub: "von der Seite, daneben kniend" },
//   { age: "A", rank: "NANAKYU", title: "Umklammerungsabwehr", sub: "von vorne, unter den Armen" },
//   { age: "A", rank: "NANAKYU", title: "Haarzugabwehr", sub: "von vorne, einhändig gefasst" },
//   { age: "A", rank: "NANAKYU", title: "Doppelschwingerabwehr", sub: "gegen Doppelschwinger (rechts/links) zum Kopf" },
//   { age: "B", rank: "NANAKYU", title: "Kurzstockabwehr", sub: "von oben, zum Kopf" },
//   { age: "C", rank: "NANAKYU", title: "Messerabwehr", sub: "von oben, zum Kopf oder Hals" },
//   {
//     age: "C",
//     rank: "NANAKYU",
//     title: "Jackenbefreiung",
//     sub: "von vorne am Oberarm gefasst, mit Schwinger als Folgeangriff",
//   },
//   { age: "A", rank: "ROKKYU", title: "Umklammerungsabwehr", sub: "von hinten, unter den Armen" },
//   {
//     age: "A",
//     rank: "ROKKYU",
//     title: "Jackenbefreiung",
//     sub: "von vorne einhändig am Revers gefasst, mit Schwinger als Folgeangriff",
//   },
//   { age: "A", rank: "ROKKYU", title: "Fausstoßabwehr", sub: "gegen Aufwärtshaken zum Magen" },
//   { age: "A", rank: "ROKKYU", title: "Trittabwehr", sub: "gegen Mae Geri" },
//   { age: "A", rank: "ROKKYU", title: "Trittabwehr", sub: "gegen Mawashi Geri" },
//   { age: "B", rank: "ROKKYU", title: "Kurzstockabwehr", sub: "von der Seite, Innenschlag zum Kopf" },
//   { age: "C", rank: "ROKKYU", title: "Messerabwehr", sub: "von der Seite, Innenstich zum Kopf oder Hals" },
//   {
//     age: "C",
//     rank: "ROKKYU",
//     title: "Jackenbefreiung",
//     sub: "von hinten am Oberarm gefasst, mit Schwinger als Folgeangriff",
//   },
//   { age: "A", rank: "GOKYU", title: "Handgelenk", sub: "beidhändig, von hinten gefasst" },
//   { age: "A", rank: "GOKYU", title: "Schwitzkasten", sub: "von der Seite gegriffen" },
//   { age: "A", rank: "GOKYU", title: "Umklammerungsabwehr", sub: "von vorne, über den Armen gegriffen" },
//   { age: "A", rank: "GOKYU", title: "Umklammerungsabwehr", sub: "von hinten, über den Armen" },
//   { age: "A", rank: "GOKYU", title: "Trittabwehr", sub: "gegen Yoko Geri" },
//   { age: "B", rank: "GOKYU", title: "Kurzstockabwehr", sub: "von der Seite, Außenschlag zum Kopf" },
//   { age: "C", rank: "GOKYU", title: "Messerabwehr", sub: "von der Seite, Außenstich" },
//   {
//     age: "C",
//     rank: "GOKYU",
//     title: "Jackenbefreiung",
//     sub: "von vorne, beidhändig am Revers gefasst, mit Kopfstoß als Folgeangriff",
//   },
//   { age: "B", rank: "YONKYU", title: "Würgen, Bodenlage", sub: "aus dem Reitsitz" },
//   { age: "B", rank: "YONKYU", title: "Würgen, Bodenlage", sub: "aus der Zwischenbeinlage" },
//   { age: "B", rank: "YONKYU", title: "Würgen, Bodenlage", sub: "von hinten, vor Kopf" },
//   { age: "B", rank: "YONKYU", title: "Schwitzkasten", sub: "von vorne gegriffen" },
//   { age: "B", rank: "YONKYU", title: "Umklammerungsabwehr", sub: "von hinten, gegen Doppelnelson-Angriff" },
//   { age: "B", rank: "YONKYU", title: "Kurzstockabwehr", sub: "Mit beiden Händen, quer zum Oberkörper gestoßen" },
//   { age: "C", rank: "YONKYU", title: "Messerabwehr", sub: "gerader Stich (Florett) zum Magen" },
//   { age: "C", rank: "YONKYU", title: "Befreiung aus Transportgriff", sub: "gegen Ude Garami (Polizeigriff)" },
// ];

// const neededNumberOfAttacks = [
//   { rank: "HAKKYU", number: 4 },
//   { rank: "ROKKYU", number: 5 },
//   { rank: "YONKYU", number: 6 },
// ];

const attacks = [
    { rank: "WHITE", title: "right hand roundhouse punch to the head", sub: "rechter Schwinger zum Kopf" },
    { rank: "WHITE", title: "right downward punch to the midriff", sub: "rechter gerader Faustoß zum Zwerchfell" },
    { rank: "WHITE", title: "double handed strangle from behind", sub: "doppelt gewürgt, von hinten" },
    { rank: "WHITE", title: "right straight punch to the head", sub: "rechter gerader Fauststoß zum Kopf" },
    { rank: "WHITE", title: "double handed strangle from the front", sub: "doppelt gewürgt, von vorne" },
    { rank: "ORANGE", title: "front hair grab", sub: "Haarzug von vorne" },
    { rank: "ORANGE", title: "right hand upper cut to the solar plexus", sub: "rechter Aufwärtshaken zum Solar Plexus" },
    { rank: "ORANGE", title: "grab from the front arms free", sub: "Umklammern von vorne, unter den Armen" },
    { rank: "ORANGE", title: "right hand to the wrist grab", sub: "rechtes Handgelenk, diagonal fassen" },
    { rank: "ORANGE", title: "right hand hammer blow to the head", sub: "rechter Hammerschlag zum Kopf" },
    { rank: "ORANGE", title: "grab from behind arms free", sub: "Umklammern von hinten, unter den Armen" },
    { rank: "ORANGE", title: "left hand lapel grab", sub: "links ans Revers fassen" },
    { rank: "GREEN", title: "full nelson", sub: "Doppelnelson" },
    { rank: "GREEN", title: "two handed strangle from right side", sub: "Würgen von der rechten Seite" },
    { rank: "GREEN", title: "head chancery from the left side", sub: "Schwitzkasten von links" },
    { rank: "GREEN", title: "right grab on left wrist", sub: "linkes Handgelenk parallel gefasst" },
    { rank: "GREEN", title: "strangle between the legs (on floor)", sub: "Würgen am Boden in Zwischenbeinlage" },
    { rank: "GREEN", title: "head chancery from the right side", sub: "Schwitzkasten von rechts" },
    { rank: "GREEN", title: "grab from the front with both arms trapped", sub: "Umklammern von vorne, über den Armen" },
    { rank: "GREEN", title: "grab from behind with both arms trapped", sub: "Umklammern von hinten, über den Armen" },
    { rank: "BLUE", title: "strangle over legs / body", sub: "Würgen am Boden in Reitsitz" },
    { rank: "BLUE", title: "double wrist grab from the rear", sub: "beide Handgelenke von hinten fassen" },
    { rank: "BLUE", title: "double lapel grab from the front followed by a head butt", sub: "beidhändig Revers von vorne gefasst, gefolgt von einem Kopfstoß" },
    { rank: "BLUE", title: "right knife thrust to the midriff", sub: "rechter Florettstich mit Messer zum Zwechfell" },
    { rank: "BLUE", title: "right knfe slash to neck", sub: "Messerschnitt von außen zum Hals" },
    { rank: "BLUE", title: "right hand overhead knife attack", sub: "rechter Messerangriff von oben" },
    { rank: "BLUE", title: "right handed back slash to the neck with knife", sub: "Messerschnitt mit rechts von innen zum Hals" },
    { rank: "BLUE", title: "right hand downward blow to the head with a baton", sub: "rechter Kurzsockangriff von oben zum Kopf" },
    { rank: "BROWN", title: "right side thrust kick", sub: "Tritt Yoko Geri rechts zum Bauch" },
    { rank: "BROWN", title: "right front kick to groin/midriff", sub: "Tritt Mae Geri rechts zum Unterleib" },
    { rank: "BROWN", title: "right backslash with baton to side of head", sub: "rechts Kurzstock von innen zum Kopf" },
    { rank: "BROWN", title: "ground strangle from right side", sub: "Würgen am Boden, rechte Seite kniend" },
    { rank: "BLACK", title: "back arm and collar hold", sub: "Arm auf dem Rücken halten (Polizeigriff)" },
    { rank: "BLACK", title: "left roundhouse punch to the head", sub: "linker Schwinger zum Kopf" },
    { rank: "BLACK", title: "right roundhouse kick to the solar plexus", sub: "rechter Mawashi Geri zum Solar Plexus" },
    { rank: "BLACK", title: "right lapel grab and left punch", sub: "rechts Revers fassen links Schwinger zum Kopf" },
    { rank: "BLACK", title: "double wrist grab from the front", sub: "beide Handgelenke von vorne gefasst" },
    { rank: "BLACK", title: "left footed front snapping kick to groin", sub: "linker Mae Geri zum Unterleib" },
    { rank: "BLACK", title: "left hand upper cut to the solar plexus", sub: "linker Aufwärtshaken zum Solar Plexus" },
    { rank: "BLACK", title: "reverse headlock", sub: "Schwitzkasten von vorne" },
  ];

customElements.define(
  "jjcm-random-attack-match",
  class extends Light(LitElement) {
    abortController = new AbortController();

    static properties = {
      match: { type: Object },
    };

    constructor() {
      super();
      this.modal = null;
      this.hantei = false;
      this.points = [];
      this.pointsToAdd = [];
      document.addEventListener("hidden.bs.modal", () => {
        this.hantei = false;
        this.modal = null;
        this.requestUpdate();
      });
    }

    firstUpdated = () => {
      console.log(this.match.aka.competitor.rank_id, " -> ", ranks.get(this.match.aka.competitor.rank_id));
      console.log(this.match.shiro.competitor.rank_id, " -> ", ranks.get(this.match.shiro.competitor.rank_id));
      this.attackSequence = range(0, attacks.length).filter(
        (i) =>
          // this.match.aka.competitor.age_class.short >= attacks[i].age && UNJJ
          // this.match.shiro.competitor.age_class.short >= attacks[i].age &&  UNJJ
          ranks.get(this.match.aka.competitor.rank_id) >= ranks.get(attacks[i].rank) &&
          ranks.get(this.match.shiro.competitor.rank_id) >= ranks.get(attacks[i].rank)
      );
      console.log(this.attackSequence);
      shuffle(this.attackSequence);
      this.currentAttackIndex = -1;
      this.numberOfAttacks = 0;
      this.neededNumberOfAttacks = 3; // neededNumberOfAttacks     UNJJ
        // .filter(
        //   (x) =>
        //     ranks.get(x.rank) <= ranks.get(this.match.shiro.competitor.rank_id) &&
        //     ranks.get(x.rank) <= ranks.get(this.match.aka.competitor.rank_id)
        // )
        // .sort((a, b) => ranks.get(b.rank).value - ranks.get(a.rank).value)[0].number;
      console.log(this.attackSequence);
      this.requestUpdate();
    };

    nextAttack = () => {
      this.numberOfAttacks++;
      this.currentAttackIndex++;
      if (this.currentAttackIndex >= this.attackSequence.length) {
        const lastIndex = this.attackSequence.slice(-1)[0];
        while (this.attackSequence[0] == lastIndex) shuffle(this.attackSequence);
        this.currentAttackIndex = 0;
      }
      this.showAttack();
    };

    removeAttack = () => {
      this.numberOfAttacks--;
      this.requestUpdate();
    };

    showAttack = (show = true) => {
      this.currentAttack = show ? this.attackSequence[this.currentAttackIndex] : null;
      this.requestUpdate();
    };

    hideAttack = () => this.showAttack(false);

    showHantei = () => {
      this.hantei = true;
      this.requestUpdate();
    };

    addPoint = (color) => {
      this.pointsToAdd.push(color);
      this.requestUpdate();
    };

    removePoint = (color) => {
      if (!this.pointsToAdd.includes(color)) return;
      this.pointsToAdd.splice(this.pointsToAdd.lastIndexOf(color), 1);
      this.requestUpdate();
    };

    applyPoints = () => {
      this.points = [
        ...this.points,
        {
          aka: this.pointsToAdd.filter((x) => x === "aka").length,
          shiro: this.pointsToAdd.filter((x) => x === "shiro").length,
        },
      ];
      this.pointsToAdd = [];
      this.modal.hide();
    };

    close = () => {
      this.dispatchEvent(new Event("closed", { bubbles: false }));
    };

    readyForHantei = () => true; // UNJJ
      // (this.numberOfAttacks % 2 == 0 || this.numberOfAttacks >= this.neededNumberOfAttacks) &&
      // this.points.length != Math.ceil(this.numberOfAttacks / 2);

    victoryAvailable = () =>
      this.numberOfAttacks >= this.neededNumberOfAttacks; // UNJJ &&
      // this.points.length >= Math.ceil(this.neededNumberOfAttacks / 2);

    render = () => {
      if (!this.modal) {
        this.getUpdateComplete().then(() => {
          const modal = document.querySelector(".modal");
          if (modal) this.modal = new bootstrap.Modal(modal);
          if (this.modal) this.modal.show();
        });
      }
      return html`<div class="vh-100 vw-100 d-flex flex-column justify-content-between bg-dark">
          ${this.currentAttack != null
            ? html`<div
                class="d-grid text-center h-100 justify-items-center random-attack bg-white"
                style="transform: rotate(180deg)"
                @click=${this.hideAttack}
              >
                <img
                  class="mh-100 mw-100"
                  src="/random-attack/${String(this.currentAttack + 1).padStart(2, "0")}.jpg"
                />
                <h1>${attacks[this.currentAttack].title}</h1>
                <h2 class="mb-5">${attacks[this.currentAttack].sub}</h2>
              </div>`
            : html`<jjcm-match-header
                  .match=${this.match}
                  .points=${this.points}
                  ?fusenGachiAvailable=${this.numberOfAttacks == 0}
                  ?victoryAvailable=${this.victoryAvailable()}
                />
                <div class="d-flex justify-content-center p-5">
                  <button class="btn btn-primary" @click=${this.nextAttack}>
                    <h1>${_`Shido`}</h1>
                    <h2>${_`Next attack`}</h2>
                  </button>
                </div>
                <div class="d-flex justify-content-center text-light">
                  <h1 style="font-size: 5em">${this.numberOfAttacks} / ${this.neededNumberOfAttacks}</h1>
                </div>
                <div class="d-flex justify-content-center p-5">
                  ${this.numberOfAttacks > 0
                    ? html`<button
                        class="${clsx("btn", {
                          "btn-primary": this.readyForHantei(),
                          "btn-secondary": !this.readyForHantei(),
                        })}"
                        @click=${this.showHantei}
                      >
                        <h1>${_`Hantei`}</h1>
                        <h2>${_`Judgement`}</h2>
                      </button>`
                    : null}
                </div>
                <div class="d-flex justify-content-between">
                  <div class="justify-self-start">
                    ${this.numberOfAttacks == 0
                      ? html`<button class="btn btn-primary mb-2 ms-2" @click=${this.close}>
                          <h1>${_`Postpone match`}</h1>
                        </button>`
                      : html`<button class="btn btn-primary mb-2 ms-2" @click=${this.removeAttack}>
                          <h1>${_`Current attack invalid`}</h1>
                        </button>`}
                  </div>
                  <div class="justify-self-end">
                    ${this.currentAttackIndex != -1
                      ? html`<button class="btn btn-primary mb-2 me-2" @click=${() => this.showAttack()}>
                          <h1>${_`Show current attack again`}</h1>
                        </button>`
                      : null}
                  </div>
                </div>`}
        </div>
        ${this.hantei
          ? html`<div class="modal fade">
              <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content border border-light">
                  <header class="modal-header">
                    <h1>${_`Hantei - Judgement`}</h1>
                  </header>
                  <main class="modal-body bg-dark">
                    <div class="d-flex justify-content-around fs-1 fw-bolder p-5">
                      ${this.pointsToAdd.map(
                        (x) => html`<i class="bi bi-flag-fill text-${x == "aka" ? "danger" : "white"}" />`
                      )}
                    </div>
                    <div class="row p-2">
                      <button class="btn btn-danger col shadow m-1" @click=${() => this.addPoint("aka")}>
                        <h1>+</h1>
                      </button>
                      <button class="btn btn-danger col shadow m-1" @click=${() => this.removePoint("aka")}>
                        <h1>-</h1>
                      </button>
                      <button class="btn btn-light col shadow m-1" @click=${() => this.addPoint("shiro")}>
                        <h1>+</h1>
                      </button>
                      <button class="btn btn-light col shadow m-1" @click=${() => this.removePoint("shiro")}>
                        <h1>-</h1>
                      </button>
                    </div>
                  </main>
                  <footer class="p-2 text-end">
                    <button
                      class="btn btn-primary"
                      ?disabled=${![3, 5].includes(this.pointsToAdd.length)}
                      @click=${this.applyPoints}
                    >
                      <h1>${_`Apply`}</h1>
                    </button>
                  </footer>
                </div>
              </div>
            </div>`
          : null}`;
    };
  }
);
