import { Light, LitElement, html } from "./lit.js";
import { unsafeHTML } from "./lib/lit-html/directives/unsafe-html.js";
import { _ } from "./i18n.js";

customElements.define(
  "x-router",
  class extends Light(LitElement) {
    constructor() {
      super();
      window.addEventListener("popstate", (e) => this.requestUpdate());
      this.notfound = () =>
        html`<h1>${_`Not found`}</h1>
          <p>${unsafeHTML(_`The URL ${`<code>${window.location.pathname}</code>`} could not be loaded.`)}</p>`;
    }

    render = () => (this.routes[window.location.pathname] || this.notfound)();
  }
);
