import { Light, LitElement, html } from "../lit.js";
import { isDefined, isString, isBoolean } from "../util.js";
import { _ } from "../i18n.js";

import { clsx } from "../lib/clsx.js";

const renderHeader = (target, data, config) => {
  const sortClass = (column) => {
    if (config?.sort) {
      const sort = config.sort.filter((x) => x[0] === column)[0];
      if (sort) return sort[1];
    }
    return "";
  };
  const dispatchSortEvent = (i, e) => {
    e.stopPropagation();
    if (config?.columns && !config.columns[i].sortable) return;
    target.dispatchEvent(new CustomEvent("sort", { detail: { column: i, shift: e.shiftKey } }));
    document.getSelection().removeAllRanges();
  };
  const dispatchFilterEvent = (i, e) => {
    e.stopPropagation();
    if (config?.columns && !config.columns[i].filterable) return;
    target.dispatchEvent(new CustomEvent("filter", { detail: { column: i, mask: e.target.value } }));
  };
  if (config?.columns) {
    return html`<thead><tr>
      ${config.columns.filter((c) => c.header !== null).map(
        (c, i) =>
          html`<th
            class="${clsx(c.headerClass, { sortable: c.sortable })}"
            colspan="${c.headerSpan || 1}"
            @click=${(e) => dispatchSortEvent(i, e)}
          >
            <div class="${sortClass(i)}">${c.header}</div>
          </th>`
      )}</tr>
      ${config.columns.some((c) => c.subHeader)
        ? html`<tr>
          ${config.columns.map(
            (c, i) =>
              html`<th
                class="${clsx(c.headerClass, { storable: c.sortable })}"
                colspan="1"
                @click=${(e) => dispatchSortEvent(i, e)}
                >
                  <div class="${sortClass(i)}">${c.subHeader}</div>
              </th>`
          )}
      </tr>`
        : null}
      ${config.columns.some((c) => c.filterable)
        ? html`<tr>
            ${config.columns.map(
              (c, i) =>
                html`<th class="filterable" colspan="${c.span || 1}">
                  ${c.filterable
                    ? html`<input type="text" placeholder="${_`filter`}" @input=${(e) => dispatchFilterEvent(i, e)} />`
                    : null}
                </th>`
            )}
          </tr>`
        : null}
    </thead>`;
  }
  return data.length
    ? html`<thead>
        ${Object.keys(data[0]).map(
          (k, i) =>
            html`<th class="sortable" @click=${(e) => dispatchSortEvent(i, e)}>
              <div class="${sortClass(i)}">${k}</div>
            </th>`
        )}
        <tr>
          ${Object.keys(data[0]).map(
            (k, i) => html`<th class="filterable"><input type="text" @input=${(e) => dispatchFilterEvent(i, e)} /></th>`
          )}
        </tr>
      </thead>`
    : null;
};

const renderTD = (rowData, config) => {
  const renderData = () => (isString(config.accessor) ? rowData[config.accessor] : config.accessor(rowData));
  return html`<td class="${config.class || ""}">${(config.render || renderData)(rowData, config)}</td>`;
};

const renderRows = (data, config) => {
  if (!data.length) {
    return html`<tr>
      <td colspan="${config?.columns?.length}" style="font-style: italic; text-align: center">
        ${_`No data available.`}
      </td>
    </tr>`;
  }
  if (config?.columns) {
    return data.map(
      (x) =>
        html`<tr class="${config.rowClass && config.rowClass(x)}">
          ${config.columns.map((c) => (c.renderTD || renderTD)(x, c))}
        </tr>`
    );
  }
  return data.map(
    (x) =>
      html`<tr class="${config.rowClass && config.rowClass(x)}">
        ${Object.keys(x).map((k) => html`<td>${x[k]}</td>`)}
      </tr>`
  );
};

const renderFooter = (data, config) => {
  if (config?.columns?.some((x) => x.footer || x.renderFooter)) {
    return html`<tfoot>
      <tr>
        ${config.columns.map(
          (c) =>
            html`<td class="${c.footerClass || ""}">
              ${c.renderFooter || c.footer ? (c.renderFooter || c.footer)(data) : null}
            </td>`
        )}
      </tr>
    </tfoot>`;
  }
  return null;
};

const compare = (a, b) => {
  if (a == null) return 1;
  if (b == null) return -1;
  if (isString(a)) return a.localeCompare(b);
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};

const sortedData = (data, config) => {
  if (!data) return [];
  if (config?.sort?.length) {
    const columnConfig = (column) => {
      if (!config?.columns) return [Object.keys(data[0])[column], compare];
      const sortable = config.columns[column].sortable;
      console.assert(sortable, "Column " + column + " is not sortable!");
      if (isBoolean(sortable)) return [config.columns[column].accessor, compare];
      return [null, sortable];
    };

    const sort = (a, b, config, i) => {
      const [column, direction] = config.sort[i];
      const [k, compare] = columnConfig(column);
      const c = k ? (isString(k) ? compare(a[k], b[k]) : compare(k(a), k(b))) : compare(a, b);
      if (c === 0 && i + 1 < config.sort.length) return sort(a, b, config, i + 1);
      return (direction === "desc" ? -1 : 1) * c;
    };
    return data.sort((a, b) => sort(a, b, config, 0));
  }
  return data;
};

const match = (x, mask) => isDefined(x) && x.toString().toLowerCase().includes(mask.toLowerCase());

const filteredData = (data, config) => {
  if (config?.filter?.length) {
    const columnConfig = (column) => {
      if (!config?.columns) return [Object.keys(data[0])[column], match];
      const filterable = config.columns[column].filterable;
      console.assert(filterable, "Column " + column + " is not filterable!");
      if (isBoolean(filterable)) return [config.columns[column].accessor, match];
      return [null, filterable];
    };

    const filter = (x, config, i) => {
      if (i == config.filter.length) return true;
      const [column, mask] = config.filter[i];
      const [k, match] = columnConfig(column);
      const m = k ? (isString(k) ? match(x[k], mask) : match(k(x), mask)) : match(x, mask);
      if (!m) return false;
      return filter(x, config, i + 1);
    };
    return data.filter((x) => filter(x, config, 0));
  }
  return data;
};

const renderTable = (target, data, config) => {
  const d = sortedData(filteredData(data, config), config);
  return html`${renderHeader(target, d, config)}
    <tbody>
      ${renderRows(d, config)}
    </tbody>
    ${renderFooter(d, config)}`;
};
export default renderTable;

customElements.define(
  "x-table",
  class extends Light(LitElement) {
    static properties = {
      tableClass: { type: String },
      rowClass: { type: Object },
      data: { type: Object },
      columns: { type: Object },
      sort: { type: Object },
      filter: { type: Object },
    };

    constructor() {
      super();
      this.data = [];
      this.sort = [];
      this.filter = [];
      this.addEventListener("sort", this.handleSort);
      this.addEventListener("filter", this.handleFilter);
    }

    handleSort = (e) => {
      e.stopPropagation();
      const column = this.sort.filter((x) => x[0] === e.detail.column)[0];
      this.sort = (e.detail.shift ? this.sort.filter((x) => x[0] !== e.detail.column) : []).concat(
        column ? (column[1] === "asc" ? [[column[0], "desc"]] : []) : [[e.detail.column, "asc"]]
      );
      this.parentNode.dispatchEvent(new CustomEvent("sort", { detail: [...this.sort] }));
    };

    handleFilter = (e) => {
      e.stopPropagation();
      this.filter = this.filter
        .filter((x) => x[0] !== e.detail.column)
        .concat(e.detail.mask ? [[e.detail.column, e.detail.mask]] : []);
    };

    render = () =>
      html`<table class="${this.tableClass}">
        ${renderTable(this, this.data, {
          rowClass: this.rowClass,
          columns: this.columns,
          sort: this.sort,
          filter: this.filter,
        })}
      </table>`;
  }
);
