import { observable, toJS } from "./lib/mobx.js";

let fallbackConfig = {
  locale: "en-us",
  currency: "USD",
  direction: "ltr",
  name: "English (US)",
  flag: "🇺🇸",
  translations: {},
};
export const configureFallbackLocale = (locale, currency, direction) =>
  (fallbackConfig = { locale: locale, currency: currency, direction: direction, translations: {} });

const availableConfigs = observable.map();
const active = observable({ config: fallbackConfig });

export const configureLocale = (locale, config) => availableConfigs.set(locale, config);
export const availableLocales = () => [fallbackConfig, ...Array.from(availableConfigs).map((x) => toJS(x[1]))];

export const selectLocale = (locale) => {
  active.config = availableConfigs.get(locale) || fallbackConfig;
  const html = document.getElementsByTagName("html")[0];
  html.lang = active.config.locale;
  html.dir = active.config.direction;
};
export const currentLocale = () => active.config;

const typeInfoRegex = /^:([a-z]+)(\((.+)\))?/;

export const createKeyFromStrings = (strings) =>
  strings.reduce((p, c, i) => `${p}{${i}}${c.replace(typeInfoRegex, "")}`).replace("\n", "");

const assertDate = (v) => {
  if (!(v instanceof Date)) v = new Date(v);
  if (!(v instanceof Date)) throw Error(`Value ${v} is not a Date: ${v.constructor.name}`);
  return v;
};

const ms_to_relative_time = (ms) => {
  if (Math.abs(ms) < 1000 * 60) return [Math.round(ms / 100) / 10, "second"];
  if (Math.abs(ms) < 1000 * 60 * 60) return [Math.round(ms / (100 * 60)) / 10, "minute"];
  if (Math.abs(ms) < 1000 * 60 * 60 * 24) return [Math.round(ms / (100 * 60 * 60)) / 10, "hour"];
  if (Math.abs(ms) < 1000 * 60 * 60 * 24 * 7) return [Math.round(ms / (100 * 60 * 60 * 24)) / 10, "day"];
  if (Math.abs(ms) < 1000 * 60 * 60 * 24 * 30) return [Math.round(ms / (100 * 60 * 60 * 24 * 7)) / 10, "week"];
  if (Math.abs(ms) < 1000 * 60 * 60 * 24 * 365) return [Math.round(ms / (100 * 60 * 60 * 24 * 30)) / 10, "month"];
  return [Math.round(ms / (100 * 60 * 60 * 24 * 365)) / 10, "year"];
};

const localizers = {
  string: (v, locale) => v.toLocaleString(locale),
  currency: (v, locale, currency) =>
    v.toLocaleString(locale, { style: "currency", currency: currency || defaultCurrency }),
  number: (v, locale, precision) =>
    v.toLocaleString(locale, { minimumFractionDigits: precision, maximumFractionDigits: precision }),
  datetime: (v, locale, options) => assertDate(v).toLocaleString(locale, options),
  date: (v, locale, options) => assertDate(v).toLocaleDateString(locale, options),
  time: (v, locale, options) => assertDate(v).toLocaleTimeString(locale, options),
  reltime: (v, locale, options) =>
    new Intl.RelativeTimeFormat(locale, { numeric: "auto" }).format(...ms_to_relative_time(v)),
  list: (v, locale, options) => new Intl.ListFormat(locale, { style: "long", ...options }).format(v),
};

const translate = (strings, values) => {
  const typeInfoRegex = /^:([a-z]+)(\((.+)\))?/;
  const key = createKeyFromStrings(strings);
  const translation = active.config.translations[key];

  const typeInfoForValues = strings.slice(1).map((string) => {
    const m = typeInfoRegex.exec(string);
    return m ? { type: m[1], options: m[3] && m[3][0] == "{" ? JSON.parse(m[3]) : m[3] } : { type: "string" };
  });

  const localize = (value, { type, options }) => localizers[type](value, active.config.locale, options);
  const localizedValues = values.map((v, i) => localize(v, typeInfoForValues[i]));

  if (translation) {
    return translation.replace(/{(\d)}/g, (_, i) => localizedValues[i - 1]);
  }
  return key.replace(/{(\d)}/g, (_, i) => localizedValues[i - 1]);
};
export const _ = (strings, ...values) => translate(strings, values);
