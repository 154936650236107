import { LitElement, html, css } from "../lit.js";

customElements.define(
  "jjcm-rank-class",
  class extends LitElement {
    static properties = { config: { type: Object } };

    static styles = css`
      :host {
        font-size: 0.75em;
        border-radius: 0.25em;
        box-shadow: 1px 1px 3px black;
        text-align: center;
        display: block;
        margin: 0.25em;
        overflow: clip;
      }
      div {
        padding: 0 0.25em;
      }
    `;

    render = () => html`<div style="color: ${this.config.css_color}; background: ${this.config.css_background}">${this.config.name}</div>`;
  }
);
