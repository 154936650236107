import { Light, LitElement, html } from "../lit.js";
import { range, accessPathInObject, isObject } from "../util.js";

const getFormElements = (elements) =>
  elements
    .reduce(
      (p, c) => [
        ...p,
        ...(["INPUT", "X-FORM-ARRAY", "X-SELECT"].includes(c.tagName)
          ? [c]
          : c.querySelectorAll("input, x-form-array, x-select")),
      ],
      []
    )
    .filter((x) => x)
    .filter((x) => {
      for (let e = x.parentNode; e && e.tagName !== "FORM"; e = e.parentNode) {
        if (["X-SELECT"].includes(e.tagName)) return false;
      }
      return true;
    });

customElements.define(
  "x-form",
  class extends Light(LitElement) {
    static properties = {
      renderContent: { type: Function },
      values: { type: Object },
      errors: { type: Object },
      touched: { type: Object },
      submitting: { type: Boolean },
    };

    constructor() {
      super();
      this.defaultValues = {};
      this.reset();
    }

    change = (e) => {
      if (e.target.type === "checkbox") {
        const candidates = getFormElements([...this.children]).filter((x) => x.name === e.target.name);
        if (candidates.length > 1) {
          accessPathInObject(this.values, e.target.name).set(candidates.filter((x) => x.checked).map((x) => x.value));
        } else {
          accessPathInObject(this.values, e.target.name).set(candidates.some((x) => x.checked));
        }
      } else {
        accessPathInObject(this.values, e.target.name).set(e.target.value);
      }
      this.requestUpdate("values");
    };

    input = (e) => {
      isObject(this.touched) && accessPathInObject(this.touched, e.target.name).set(true);
      isObject(this.errors) && accessPathInObject(this.errors, e.target.name).delete();
      this.requestUpdate();
    };

    reset = (e) => {
      getFormElements([...this.children]).forEach((x) => {
        if (x.type === "x-form-array") {
          x.value = JSON.parse(JSON.stringify(x.defaultValue));
        }
      });
      this.values = JSON.parse(JSON.stringify(this.defaultValues));
      this.errors = {};
      this.touched = {};
      this.submitting = false;
    };

    submit = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.submitting = true;
      if (this.validate) this.errors = this.validate(this.values) || {};
      this.touched = {};
      getFormElements([...this.children]).forEach((x) => accessPathInObject(this.touched, x.name).set(true));
      if (!Object.keys(this.errors).length) return this.dispatchEvent(new Event("submit", { bubbles: true }));
      this.submitting = false;
    };

    submittingDone = () => (this.submitting = false);

    initializeElement = (element) => {
      if (this.defaultValues === undefined) return;
      if (!(element.name in this.defaultValues)) return;
      switch (element.type) {
        case "radio":
          element.defaultChecked = this.defaultValues[element.name] == element.value;
          break;
        case "checkbox":
          const checked =
            this.defaultValues[element.name] === true ||
            (this.defaultValues[element.name] || []).some((option) => option == element.value);
          element.defaultChecked = checked;
          break;
        default:
          element.defaultValue = JSON.parse(JSON.stringify(this.defaultValues[element.name]));
      }
    };

    firstUpdated(changedProperties) {
      this.defaultValues = JSON.parse(JSON.stringify(this.values));
      this.updateComplete.then(() =>
        getFormElements([...this.children]).forEach((element) => this.initializeElement(element))
      );
      this.requestUpdate();
    }

    render = () => html`<form
      autocomplete="new-password"
      novalidate
      @change=${this.change}
      @input=${this.input}
      @reset=${this.reset}
      @submit=${this.submit}
    >
      ${this.renderContent({
        values: this.values,
        errors: this.errors,
        touched: this.touched,
        submitting: this.submitting,
      })}
      ${process.env.NODE_ENV === "development"
        ? html`<pre style="background: cyan; border: 1px solid black">
${JSON.stringify(
              {
                defaultValues: this.defaultValues,
                values: this.values,
                errors: this.errors,
                touched: this.touched,
                submitting: this.submitting,
              },
              null,
              2
            )}</pre
          >`
        : null}
    </form>`;
  }
);

customElements.define(
  "x-form-array",
  class extends Light(LitElement) {
    static properties = {
      renderContent: { type: Function },
      name: { type: String },
      value: { type: Array, attribute: false },
      defaultValue: { type: Array, attribute: "value" },
    };

    constructor() {
      super();
      this.type = "x-form-array";
      this.defaultValue = [];
      this.value = [];
    }

    initializeElement = (element, index, name) => {
      try {
        const defaultValue = name ? this.defaultValue[index][name] : this.defaultValue[index];
        const value = name ? this.value[index][name] : this.value[index];
        switch (element.type) {
          case "radio":
            element.defaultChecked = defaultValue == element.value;
            element.checked = value == element.value;
            break;
          case "checkbox":
            element.defaultChecked =
              defaultValue === true || (defaultValue || []).some((option) => option == element.value);
            element.checked = value === true || (value || []).some((option) => option == element.value);
            break;
          default:
            element.defaultValue = JSON.parse(JSON.stringify(defaultValue));
            element.value = JSON.parse(JSON.stringify(value));
        }
      } catch (TypeError) {}
    };

    updated = (changedProperties) => {
      if (changedProperties.has("name")) this.itemRegex = RegExp(`^${this.name}\\[(\\d+)\\](\.(.+))?$`);
      if (changedProperties.has("defaultValue")) this.value = JSON.parse(JSON.stringify(this.defaultValue));
      if (changedProperties.has("value")) {
        this.updateComplete.then(() => {
          getFormElements([...this.children])
            .map((element) => [element, element.name.match(this.itemRegex)])
            .filter(([, matches]) => matches)
            .forEach(([element, matches]) => {
              // console.log(element);
              const [, index, , name] = matches;
              this.initializeElement(element, index, name);
              // console.log(element);
            });
        });
      }
    };

    performAction = () => {
      this.requestUpdate("value");
      this.dispatchEvent(new Event("change", { bubbles: true }));
    };

    render = () =>
      this.renderContent({
        push: (item) => this.performAction(this.value.push(item)),
        swap: (index_a, index_b) =>
          this.performAction(this.value.splice(index_a, 1, this.value.splice(index_b, 1, this.value[index_a])[0])),
        move: (from, to) => this.performAction(this.value.splice(to, 0, this.value.splice(from, 1))),
        insert: (index, item) => this.performAction(this.value.splice(index, 0, item)),
        replace: (index, item) => this.performAction((this.value[index] = item)),
        unshift: () => this.performAction(this.value.splice(0, 1)),
        pop: () => this.performAction(this.value.pop()),
        remove: (index) => this.performAction(this.value.splice(index, 1)),
        length: (this.value || []).length,
        itemName: (index) => `${this.name}[${index}]`,
      });
  }
);

const exampleFormWithArray = html`<x-form
  .values=${{
    complex: [
      { first: "first_0", second: "second_0", checkboxes: ["foo", "bar"] },
      { first: "first_1", second: "second_1", checkboxes: [] },
    ],
  }}
  .renderContent=${() => html`<x-form-array
    name="complex"
    .renderContent=${({ itemName, remove, push, length }) => {
      return html`${range(0, length).map(
          (i) => html`<div>
            <input name="${itemName(i)}.first" type="text" />
            <input name="${itemName(i)}.second" type="text" />
            <button type="button" @click=${() => remove(i)}>-</button>
          </div>`
        )} <button type="button" @click=${() => push({ first: "", second: "" })}>+</button>`;
    }}
  />`}
/>`;
