import { Light, LitElement, html } from "../lit.js";
import { httpRequest, range, unique, isObject } from "../util.js";
import { _ } from "../i18n.js";
import { state } from "../state.js";
import { isDefined, accessPathInObject } from "../util.js";
import Schema from "../validation.js";

import { clsx } from "../lib/clsx.js";
import zipcelx from "../lib/zipcelx.js";

import "../widgets/Form.js";
import "../widgets/Input.js";
import "../widgets/Table.js";

import { sexInfo } from "./Sex.js";
import { ranks, rankNames } from "./Rank.js";

customElements.define(
  "jjcm-competitors",
  class extends Light(LitElement) {
    abortController = new AbortController();

    constructor() {
      super();
      this.updateCompetitors();
      this.registrations = [];
      // this.selectAll = false;
      this.showSelector = false;
      this.showAddDialog = false;
      this.modal = null;
      document.addEventListener("hidden.bs.modal", (e) => {
        this.showSelector = false;
        this.showAddDialog = false;
        this.registrationToEdit = null;
        this.registrationToDelete = null;
        this.modal = null;
        this.requestUpdate();
      });
    }

    firstUpdated() {
      // this.showSelector = false;
      // this.showAddDialog = false;
      this.requestUpdate();
    }

    reload = (action = () => {}) => {
      if (this.modal) this.modal.hide();
      this.modal = null;
      this.showSelector = false;
      this.showAddDialog = false;
      this.registrationToEdit = null;
      this.registrationToDelete = null;
      action();
    };

    updateCompetitors = () => {
      Promise.all([
        httpRequest(
          `/competitions/${state.session.competition_id}/registrations?rel=dojo;rel=age_class;${[
            "random_attack",
            "ground_fighting",
            "ground_fighting_open",
            "sparring",
            "sparring_open",
            "continuous_fighting",
            "continuous_fighting_open",
            "pairs",
            // "kata",
            "empty_hand_kata",
            "weapons_kata",
            "two_person_empty_hand_kata",
            "two_person_weapons_kata",
            "team",
          ]
            .map((x) => `rel=${x}`)
            .join(";")}`,
          {
            signal: this.abortController.signal,
          }
        ),
        httpRequest(`/competitions/${state.session.competition_id}/settings/age`, {
          signal: this.abortController.signal,
        }),
        httpRequest("/competitors?rel=dojo", {
          signal: this.abortController.signal,
        }),
        httpRequest(`/competitions/${state.session.competition_id}/dojos`, {
          signal: this.abortController.signal,
        }),
      ])
        .then((responses) => Promise.all(responses.map((r) => r.json())))
        .then(([registrations, age_classes, competitors, dojos]) => {
          this.registrations = registrations;
          this.max_birthdate =
            state.session.competition.date.substring(0, 4) -
            age_classes.map((x) => x.min).sort((a, b) => a - b)[0] +
            state.session.competition.date.substring(4);
          this.competitors = competitors.filter(
            (c) => c.birthdate <= this.max_birthdate && !registrations.some((r) => r.competitor_id === c.id)
          );
          this.dojos = dojos;
        })
        .then(() => this.requestUpdate())
        .catch(console.error);
    };

    addRegistrations = (data, submittingDone) =>
      httpRequest(`/competitions/${state.session.competition_id}/registrations`, {
        method: "POST",
        body: JSON.stringify(data.map((c) => ({ competitor: c, weight: c.weight, rank_id: ranks.get(c.rank).key }))),
      })
        .then((response) => response.ok && response)
        .then((data) => {
          this.reload(this.updateCompetitors);
        })
        .catch((e) => {
          console.error(e);
          submittingDone();
        });

    registerCompetitors = (e) =>
      this.addRegistrations(
        Object.values(e.target.values)
          .filter((x) => x.selected)
          .map((c) => ({ id: c.id, name: c.name, weight: c.weight, rank: c.rank })),
        e.target.submittingDone
      );

    addCompetitors = (e) => this.addRegistrations(e.target.values.competitors, e.target.submittingDone);

    editRegistration = (e) => {
      const values = (({ id, name, rank_id }) => ({ id, name, rank_id }))(e.target.values);
      values.weight = Number(e.target.values.weight);
      httpRequest(`/competitions/${state.session.competition_id}/registrations/${values.id}`, {
        signal: this.abortController.signal,
        method: "PATCH",
        body: JSON.stringify(values),
      })
        .then((response) => response.ok && response)
        .then((data) => this.reload(this.updateCompetitors))
        .catch((e) => {
          console.error(e);
          submittingDone();
        });
    };

    deleteRegistration = (id) =>
      httpRequest(`/competitions/${state.session.competition_id}/registrations/${id}`, {
        signal: this.abortController.signal,
        method: "DELETE",
      })
        .then((response) => response.ok && response)
        .then(() => this.reload(this.updateCompetitors))
        .catch(console.error);

    showRegistrationEditor = (competitor) => {
      this.registrationToEdit = (({ id, name, given_name, birthdate, dojo, rank_id }) => ({
        id,
        name,
        given_name,
        birthdate,
        dojo,
        rank_id,
      }))(competitor);
      if (competitor.weight) this.registrationToEdit.weight = competitor.weight;
      this.requestUpdate();
    };
    askForRegistrationDeletion = (competitor) => {
      this.registrationToDelete = competitor;
      this.requestUpdate();
    };

    downloadExcelLists = () => {
      zipcelx({
        filename: `${state.session.competition.name} - All`,
        sheet: {
          data: [
            [
              { value: "Name", type: "string" },
              { value: "Vorname", type: "string" },
              { value: "Dojo", type: "string" },
            ],
            ...this.registrations.map((r) => [
              { value: r.name, type: "string" },
              { value: r.given_name, type: "string" },
              { value: r.dojo.name, type: "string" },
            ]),
          ],
        },
      });

      zipcelx({
        filename: `${state.session.competition.name} - Wiegen`,
        sheet: {
          data: [
            [
              { value: "Name", type: "string" },
              { value: "Vorname", type: "string" },
              { value: "Dojo", type: "string" },
              { value: "zum Wiegen erschienen", type: "string" },
            ],
            ...this.registrations
              .filter((r) => r.ground_fighting || r.sparring || r.continuous_fighting)
              .map((r) => [
                { value: r.name, type: "string" },
                { value: r.given_name, type: "string" },
                { value: r.dojo.name, type: "string" },
              ]),
          ],
        },
      });

      [true, false].forEach((random_attack) =>
        [true, false].forEach((ground_fighting) =>
          [true, false].forEach((ground_fighting_open) =>
          [true, false].forEach((sparring) =>
          [true, false].forEach((sparring_open) =>
          [true, false].forEach((continuous_fighting) =>
          [true, false].forEach((continuous_fighting_open) =>
            [true, false].forEach((pairs) =>
              // [true, false].forEach((kata) =>
              [true, false].forEach((empty_hand_kata) =>
              [true, false].forEach((weapons_kata) =>
              [true, false].forEach((two_person_empty_hand_kata) =>
              [true, false].forEach((two_person_weapons_kata) =>
                [true, false].forEach((team) => {
                  const registrations = this.registrations.filter(
                    (x) =>
                      !!x.random_attack == random_attack &&
                      !!x.ground_fighting == ground_fighting &&
                      !!x.ground_fighting_open == ground_fighting_open &&
                      !!x.sparring == sparring &&
                      !!x.sparring_open == sparring_open &&
                      !!x.continuous_fighting == continuous_fighting &&
                      !!x.continuous_fighting_open == continuous_fighting_open &&
                      !!x.pairs == pairs &&
                      // !!x.kata == kata &&
                      !!x.empty_hand_kata == empty_hand_kata &&
                      !!x.weapons_kata == weapons_kata &&
                      !!x.two_person_empty_hand_kata == two_person_empty_hand_kata &&
                      !!x.two_person_weapons_kata == two_person_weapons_kata &&
                      !!x.team == team
                  );
                  if (registrations.length) {
                    zipcelx({
                      filename: `${state.session.competition.name} - ${[
                        random_attack ? "RA" : null,
                        ground_fighting ? "G" : null,
                        ground_fighting_open ? "GO" : null,
                        sparring ? "S" : null,
                        sparring_open ? "SO" : null,
                        continuous_fighting ? "CF" : null,
                        continuous_fighting_open ? "CFO" : null,
                        pairs ? "P" : null,
                        // kata ? "K" : null,
                        empty_hand_kata ? "EK" : null,
                        weapons_kata ? "WK" : null,
                        two_person_empty_hand_kata ? "TEK" : null,
                        two_person_weapons_kata ? "TWK" : null,
                        team ? "T" : null,
                      ]
                        .filter((x) => x != null)
                        .join("+")}`,
                      sheet: {
                        data: [
                          [
                            { value: "Name", type: "string" },
                            { value: "Dojo", type: "string" },
                          ],
                          ...registrations.map((r) => [
                            { value: `${r.given_name} ${r.name}`, type: "string" },
                            { value: r.dojo.name, type: "string" },
                          ]),
                        ],
                      },
                    });
                  }
                })
              )
              )
              )
              )
              // )
            )
          )
          )
          )
          )
          )
        )
      );
    };

    render = () => {
      if (!this.modal) {
        this.getUpdateComplete().then(() => {
          const modal = document.querySelector(".modal");
          if (modal) this.modal = new bootstrap.Modal(modal);
          if (this.modal) this.modal.show();
        });
      }
      return html`<h1>${_`Competitors`}</h1>
        <div>
          ${state.session.competition?.admins.map((x) => x.id).includes(state.session.user_id)
            ? html`<button @click=${this.downloadExcelLists}>Download Excel-Lists</button>`
            : null}
        </div>
        <div class="d-flex justify-content-end">
          ${this.competitors?.length
          ? html`<button
              class="m-2 me-0 btn btn-primary"
              @click=${() => {
                this.showSelector = true;
                this.requestUpdate();
              }}
            >
              ${_`Register already existing competitors`}
            </button>`
          : null}
          <button
            class="m-2 me-0 btn btn-primary"
            @click=${() => {
          this.showAddDialog = true;
          this.requestUpdate();
        }}
          >
            ${_`Create and register new competitors`}
          </button>
        </div>
        <x-table
          tableClass="table table-striped table-hover table-bordered"
          .data=${this.registrations}
          .sort=${[[0, "asc"]]}
          .columns=${[
            {
              header: _`Name`,
              sortable: true,
              filterable: true,
              accessor: (x) => `${x.name}, ${x.given_name}`,
            },
            {
              header: _`Birthdate`,
              sortable: true,
              filterable: true,
              accessor: "birthdate",
              render: (x) => _`${x.birthdate}:date`,
              class: "minimal-width",
            },
            {
              header: _`Sex`,
              sortable: true,
              filterable: true,
              accessor: "sex",
              render: (x) => html`<x-sex value="${x.sex}" />`,
              class: "minimal-width text-center",
            },
            {
              header: _`Weight`,
              sortable: true,
              accessor: (x) => (x.weight ? `${x.weight.toFixed(1)} kg` : null),
              class: "minimal-width text-end text-nowrap",
            },
            {
              header: _`Rank`,
              sortable: true,
              filterable: true,
              accessor: (x) => ranks.get(x.rank_id),
              render: (x) => html`<x-rank value="${x.rank_id}" />`,
              class: "minimal-width text-nowrap",
            },
            ...(this.dojos?.length > 1
              ? [
                  {
                    header: _`Dojo`,
                    sortable: true,
                    filterable: true,
                    accessor: (x) => x.dojo.name,
                    class: "minimal-width text-nowrap",
                  },
                ]
              : []),
            {
              header: _`Age class`,
              sortable: true,
              filterable: true,
              accessor: (x) => x.age_class?.name,
              class: "minimal-width text-nowrap",
            },
            ...[
              { header: _`R`, title: _`Random Attack`, key: "random_attack" },
              { header: _`G`, title: _`Ground Fighting`, key: "ground_fighting" },
              { header: _`GO`, title: _`Ground Fighting (Open Class)`, key: "ground_fighting_open" },
              { header: _`S`, title: _`Sparring`, key: "sparring" },
              { header: _`SO`, title: _`Sparring (Open Class)`, key: "sparring_open" },
              { header: _`CF`, title: _`Continuous Fighting`, key: "continuous_fighting" },
              { header: _`CFO`, title: _`Continuous Fighting (Open Class)`, key: "continuous_fighting_open" },
              { header: _`P`, title: _`Pairs`, key: "pairs" },
              // { header: _`K`, title: _`Kata`, key: "kata" },
              { header: _`EK`, title: _`Empty Hand Kata`, key: "empty_hand_kata" },
              { header: _`WK`, title: _`Weapons Kata`, key: "weapons_kata" },
              { header: _`TEK`, title: _`Two Person Empty Hand Kata`, key: "two_person_empty_hand_kata" },
              { header: _`TWK`, title: _`Two Person Weapons Kata`, key: "two_person_weapons_kata" },
              { header: _`T`, title: _`Team`, key: "team" },
            ].map((c) => ({
              header: html`<span title="${c.title}">${c.header}</span>`,
              header: c.header,
              sortable: true,
              filterable: true,
              accessor: (x) => (x[c.key] ? "t" : "f"),
              render: (x) => html`<span title="${c.title}">${x[c.key] ? html`&times;` : null}</span>`,
              class: "minimal-width fs-5 fw-bold",
              footer: (data) => data.filter((x) => x[c.key]).length,
            })),
            {
              class: "minimal-width text-nowrap",
              render: (x) =>
                html`<button
                    class="btn btn-link"
                    title="${_`Edit competitor`}"
                    @click=${() => this.showRegistrationEditor(x)}
                  >
                    <i class="bi bi-pencil-square" />
                  </button>
                  <button
                    class="btn btn-link"
                    title="${_`Unregister competitor`}"
                    @click=${() => this.askForRegistrationDeletion(x)}
                  >
                    <i class="bi bi-trash3" /i>
                  </button>`,
            },
          ]}
        />
        ${this.registrationToEdit
          ? html`<div class="modal fade">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <header class="modal-header">
              <h5>${_`Edit competitor`}</h5>
            </header>
            <main class="modal-body">
              <x-form
                @submit=${this.editRegistration}
                .validate=${(values) =>
                  Schema.Struct({
                    name: Schema.String().test((x, p) => !p.selected || (isDefined(x) && x), _`Name missing!`),
                    weight: Schema.Number(),
                    rank: Schema.String().test((x, p) => !p.selected || isDefined(x), _`Rank missing!`),
                  }).validate(values)}
                .values=${this.registrationToEdit}
                .renderContent=${({ values, errors, touched, submitting }) => html`<div class="row">
                    <div class="col">
                      <label>${_`Name`}</label>
                      <input
                        class="${clsx("form-control", {
                          "is-invalid": touched.name && errors.name,
                        })}"
                        type="text"
                        name="name"
                      />
                    </div>
                    <div class="col">
                      <label>${_`Given name`}</label>
                      <div class="form-control" style="background-color: #e9ecef">
                        ${this.registrationToEdit.given_name}
                      </div>
                    </div>
                    <div class="col">
                      <label>${_`Birthdate`}</label>
                      <div class="form-control" style="background-color: #e9ecef">
                        ${_`${this.registrationToEdit.birthdate}:date`}
                      </div>
                    </div>
                    <div class="col">
                      <label>${_`Weight`}</label>
                      <input
                        class="${clsx("form-control", {
                          "is-invalid": touched.weight && errors.weight,
                        })}"
                        type="text"
                        name="weight"
                      />
                    </div>
                    <div class="col">
                      <label>${_`Rank`}</label>
                      <x-select
                        inputclass="${clsx("form-control", {
                          "is-invalid": touched.rank && errors.rank,
                        })}"
                        name="rank_id"
                        value="${values.rank_id}"
                        .options=${ranks.enums.filter(x => x >= ranks.WHITE)}
                        .optionToString=${(x) => rankNames[x]}
                        .optionToCandidate=${(x) => html`<x-rank value="${x}" />`}
                      />
                    </div>
                    ${this.dojos?.length > 1
                      ? html`<div class="col">
                          <label>${_`Dojo`}</label>
                          <div class="form-control" style="background-color: #e9ecef">
                            ${this.registrationToEdit.dojo.name}
                          </div>
                        </div>`
                      : null}
                  </div>
                  <div class="d-flex justify-content-end">
                    <button class="m-2 me-0 btn btn-secondary" type="reset" ?disabled=${submitting}>${_`Reset`}</button>
                    <button class="m-2 me-0 btn btn-primary" type="submit" ?disabled=${submitting}>${_`Apply`}</button>
                  </div>`}
              />
            </main>
            </div>
          </div>
        </div>
      </div>`
          : null}
        ${this.registrationToDelete
          ? html`<div class="modal fade">
              <div class="modal-dialog modal modal-dialog-centered">
                <div class="modal-content">
                  <header class="modal-header">
                    <h5>${_`Confirmation required!`}</h5>
                  </header>
                  <main class="modal-body">
                    ${_`Unregister ${this.registrationToDelete.given_name} ${this.registrationToDelete.name} from this competition?`}
                  </main>
                  <footer class="modal-footer">
                    <div class="d-flex justify-content-end">
                      <button class="m-2 me-0 btn btn-secondary" @click=${() => this.reload()}>${_`Cancel`}</button>
                      <button
                        class="m-2 me-0 btn btn-primary"
                        @click=${() => this.deleteRegistration(this.registrationToDelete.id)}
                      >
                        ${_`Confirm`}
                      </button>
                    </div>
                  </footer>
                </div>
              </div>
            </div>`
          : null}
        ${this.showSelector
          ? html`<div class="modal fade">
              <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                  <header class="modal-header"><h1>${_`Register already existing competitors`}</h1></header>
                  <main class="modal-body">
                    <x-form
                      @submit=${this.registerCompetitors}
                      .validate=${(values) =>
                        Schema.Dictionary(
                          Schema.Struct({
                            name: Schema.String().test((x, p) => !p.selected || (isDefined(x) && x), _`Name missing!`),
                            weight: Schema.Number(),
                            rank: Schema.String().test((x, p) => !p.selected || isDefined(x), _`Rank missing!`),
                          })
                        ).validate(values)}
                      .values=${this.competitors?.reduce((p, c) => {
                        p[c.id] = c;
                        return p;
                      }, {}) || []}
                      .renderContent=${({ values, errors, touched, submitting }) => html`<x-table
                          tableClass="table table-striped table-hover table-bordered"
                          .data=${Object.entries(values)
                            .map(([k, v]) => v)
                            .filter((x) => !x.inactive)}
                          .columns=${[
                            {
                              class: "minimal-width",
                              render: (x) =>
                                html`<label
                                  ><input type="hidden" name="${x.id}.inactive" />
                                  <i
                                    class="bi bi-eye-slash"
                                    title="${_`Do not show again`}"
                                    @click=${(e) => {
                                      e.target.previousElementSibling.value = true;
                                      e.target.previousElementSibling.dispatchEvent(
                                        new Event("change", { bubbles: true })
                                      );
                                    }}
                                /></label>`,
                            },
                            {
                              header: _`Name`,
                              accessor: "name",
                              render: (x) => html`<input
                                class="${clsx("form-control", {
                                  "is-invalid": touched[x.id]?.name && errors[x.id]?.name,
                                })}"
                                type="text"
                                name="${x.id}.name"
                                value="${values[x.id].name}"
                              />`,
                            },
                            {
                              header: _`Given name`,
                              accessor: "given_name",
                            },
                            {
                              header: _`Birthdate`,
                              render: (x) => _`${x.birthdate}:date`,
                            },
                            {
                              header: _`Weight`,
                              render: (x) =>
                                html`<div class="input-group input-group-sm">
                                  <input
                                    class="${clsx("form-control", {
                                      "is-invalid": touched[x.id]?.weight && errors[x.id]?.weight,
                                    })}"
                                    type="text"
                                    name="${x.id}.weight"
                                    value="${values[x.id].weight}"
                                  />
                                  <div class="input-group-append"><span class="input-group-text">kg</span></div>
                                </div>`,
                            },
                            {
                              header: _`Rank`,
                              render: (x) => html`<x-select
                                inputclass="${clsx("form-control", {
                                  "is-invalid": touched[x.id]?.rank && errors[x.id]?.rank,
                                })}"
                                name="${x.id}.rank"
                                value="${values[x.id].rank}"
                                .options=${ranks.enums.filter(x => x >= ranks.WHITE)}
                                .optionToString=${(x) => rankNames[x]}
                                .optionToCandidate=${(x) => html`<x-rank value="${x}" />`}
                              />`,
                            },
                            ...(this.dojos?.length > 1
                              ? [
                                  {
                                    header: _`Dojo`,
                                    render: (x) => x.dojo.name,
                                  },
                                ]
                              : []),
                            {
                              header: _`Register`,
                              class: "minimal-width center",
                              render: (x) =>
                                html`<input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="${x.id}.selected"
                                  value="${x.id}.selected"
                                />`,
                            },
                          ]}
                        />
                        <div class="d-flex justify-content-end">
                          <button class="m-2 me-0 btn btn-secondary" type="reset" ?disabled=${submitting}>
                            ${_`Reset`}
                          </button>
                          <button class="m-2 me-0 btn btn-primary" type="submit" ?disabled=${submitting}>
                            ${_`Register selected competitors`}
                          </button>
                        </div>`}
                    />
                  </main>
                </div>
              </div>
            </div>`
          : null}
        ${this.showAddDialog && this.dojos
          ? html`<div class="modal fade">
              <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                  <header class="modal-header"><h1>${_`Create and register new competitors`}</h1></header>
                  <main class="modal-body">
                    <x-form
                      @submit=${this.addCompetitors}
                      .validate=${(values) =>
                        Schema.Struct({
                          competitors: Schema.Array(
                            Schema.Struct({
                              name: Schema.String().required(_`Name missing!`),
                              given_name: Schema.String().required(_`Given name missing!`),
                              birthdate: Schema.Date()
                                .max(this.max_birthdate, _`Too young!`)
                                .required(_`Birthdate missing!`),
                              sex: Schema.String().required(_`Sex missing!`),
                              dojo: Schema.String().required(_`Dojo missing!`),
                              rank: Schema.String().required(_`Rank missing!`),
                              weight: Schema.Number(),
                            }).test(
                              (x) =>
                                ![...this.competitors, ...this.registrations].some(
                                  (c) =>
                                    c.name === x.name && c.given_name === x.given_name && c.birthdate === x.birthdate
                                ),
                              _`Competitor already exists!`
                            )
                          ),
                        }).validate(values)}
                      .values=${{
                        competitors: [this.dojos?.length === 1 ? { dojo: this.dojos[0] } : {}],
                      }}
                      .renderContent=${({ values, errors, touched, submitting }) => html`<div>
                          <x-form-array
                            name="competitors"
                            value="${JSON.stringify(values.competitors)}"
                            .renderContent=${({ itemName, push, pop, length }) =>
                              html`${range(0, length).map(
                                  (i) => html`<div class="row">
                                    <div class="col mb-2">
                                      ${i === 0 ? html`<label>${_`Name`}</label>` : null}
                                      <input
                                        class="${clsx("form-control", {
                                          "is-invalid":
                                            accessPathInObject(touched, itemName(i)).get()?.name &&
                                            (!isObject(accessPathInObject(errors, itemName(i)).get()) ||
                                              accessPathInObject(errors, itemName(i)).get()?.name),
                                        })}"
                                        type="text"
                                        name="${itemName(i)}.name"
                                        value="${accessPathInObject(values, itemName(i)).get()?.name}"
                                      />
                                      <div class="invalid-feedback">
                                        ${isObject(accessPathInObject(errors, itemName(i)).get())
                                          ? accessPathInObject(errors, itemName(i)).get().name
                                          : accessPathInObject(errors, itemName(i)).get()}
                                      </div>
                                    </div>
                                    <div class="col mb-2">
                                      ${i === 0 ? html`<label>${_`Given name`}</label>` : null}
                                      <input
                                        class="${clsx("form-control", {
                                          "is-invalid":
                                            accessPathInObject(touched, itemName(i)).get()?.given_name &&
                                            (!isObject(accessPathInObject(errors, itemName(i)).get()) ||
                                              accessPathInObject(errors, itemName(i)).get()?.given_name),
                                        })}"
                                        type="text"
                                        name="${itemName(i)}.given_name"
                                        value="${accessPathInObject(values, itemName(i)).get()?.given_name}"
                                      />
                                      <div class="invalid-feedback">
                                        ${accessPathInObject(errors, itemName(i)).get()?.given_name}
                                      </div>
                                    </div>
                                    <div class="col mb-2">
                                      ${i === 0 ? html`<label>${_`Birthdate`}</label>` : null}
                                      <input
                                        class="${clsx("form-control", {
                                          "is-invalid":
                                            accessPathInObject(touched, itemName(i)).get()?.birthdate &&
                                            (!isObject(accessPathInObject(errors, itemName(i)).get()) ||
                                              accessPathInObject(errors, itemName(i)).get()?.birthdate),
                                        })}"
                                        type="date"
                                        name="${itemName(i)}.birthdate"
                                        value="${accessPathInObject(values, itemName(i)).get()?.birthdate}"
                                      />
                                      <div class="invalid-feedback">
                                        ${accessPathInObject(errors, itemName(i)).get()?.birthdate}
                                      </div>
                                    </div>
                                    <div class="col mb-2">
                                      ${i === 0 ? html`<label>${_`Sex`}</label>` : null}
                                      <x-select
                                        class="${clsx({
                                          "is-invalid":
                                            accessPathInObject(touched, itemName(i)).get()?.sex &&
                                            accessPathInObject(errors, itemName(i)).get()?.sex,
                                        })}"
                                        inputclass="${clsx("form-control", {
                                          "is-invalid":
                                            accessPathInObject(touched, itemName(i)).get()?.sex &&
                                            accessPathInObject(errors, itemName(i)).get()?.sex,
                                        })}"
                                        name="${itemName(i)}.sex"
                                        value="${accessPathInObject(values, itemName(i)).get()?.sex}"
                                        .options=${["MALE", "FEMALE"]}
                                        .optionToString=${(x) => sexInfo[x].name()}
                                      />
                                      <div class="invalid-feedback">
                                        ${accessPathInObject(errors, itemName(i)).get()?.sex}
                                      </div>
                                    </div>
                                    ${this.dojos?.length > 1
                                      ? html`<div class="col mb-2">
                                          ${i === 0 ? html`<label>${_`Dojo`}</label>` : null}
                                          <x-select
                                            class="${clsx({
                                              "is-invalid":
                                                accessPathInObject(touched, itemName(i)).get()?.dojo &&
                                                accessPathInObject(errors, itemName(i)).get()?.dojo,
                                            })}"
                                            inputclass="${clsx("form-control", {
                                              "is-invalid":
                                                accessPathInObject(touched, itemName(i)).get()?.dojo &&
                                                accessPathInObject(errors, itemName(i)).get()?.dojo,
                                            })}"
                                            name="${itemName(i)}.dojo"
                                            value="${accessPathInObject(values, itemName(i)).get()?.dojo}"
                                            .options=${this.dojos}
                                            .optionToString=${(x) => x.name}
                                          />
                                          <div class="invalid-feedback">
                                            ${accessPathInObject(errors, itemName(i)).get()?.dojo}
                                          </div>
                                        </div>`
                                      : null}
                                    <div class="col mb-2">
                                      ${i === 0 ? html`<label>${_`Rank`}</label>` : null}
                                      <x-select
                                        class="${clsx({
                                          "is-invalid":
                                            accessPathInObject(touched, itemName(i)).get()?.rank &&
                                            accessPathInObject(errors, itemName(i)).get()?.rank,
                                        })}"
                                        inputclass="${clsx("form-control", {
                                          "is-invalid":
                                            accessPathInObject(touched, itemName(i)).get()?.rank &&
                                            accessPathInObject(errors, itemName(i)).get()?.rank,
                                        })}"
                                        name="${itemName(i)}.rank"
                                        value="${accessPathInObject(values, itemName(i)).get()?.rank}"
                                        .options=${ranks.enums.filter(x => x >= ranks.WHITE)}
                                        .optionToString=${(x) => rankNames[x]}
                                        .optionToCandidate=${(x) => html`<x-rank value="${x}" />`}
                                      />
                                      <div class="invalid-feedback">
                                        ${accessPathInObject(errors, itemName(i)).get()?.rank}
                                      </div>
                                    </div>
                                    <div class="col mb-2">
                                      ${i === 0 ? html`<label>${_`Weight`}</label>` : null}
                                      <div class="input-group input-group-sm">
                                        <input
                                          class="${clsx("form-control", {
                                            "is-invalid":
                                              accessPathInObject(touched, itemName(i)).get()?.weight &&
                                              accessPathInObject(errors, itemName(i)).get()?.weight,
                                          })}"
                                          type="text"
                                          name="${itemName(i)}.weight"
                                        />
                                        <div class="input-group-append"><span class="input-group-text">kg</span></div>
                                        <div class="invalid-feedback">
                                          ${accessPathInObject(errors, itemName(i)).get()?.weight}
                                        </div>
                                      </div>
                                    </div>
                                  </div>`
                                )}
                                <div class="d-flex justify-content-center">
                                  <button
                                    class="btn btn-secondary px-4"
                                    @click=${() => pop()}
                                    ?disabled=${length === 1}
                                  >
                                    -</button
                                  ><button
                                    class="btn btn-primary px-4"
                                    type="button"
                                    @click=${() => push(this.dojos.length === 1 ? { dojo: this.dojos[0] } : {})}
                                  >
                                    +
                                  </button>
                                </div>`}
                          />
                        </div>
                        <div class="d-flex justify-content-end">
                          <button class="m-2 me-0 btn btn-secondary" type="reset" ?disabled=${submitting}>
                            ${_`Reset`}
                          </button>
                          <button class="m-2 me-0 btn btn-primary" type="submit" ?disabled=${submitting}>
                            ${_`Register competitors`}
                          </button>
                        </div>`}
                    />
                    <div style="max-height: 0; overflow: clip; transition: max-height .15s linear">
                      <div class="alert ${this.alert_class}">${this.alert_message}</div>
                    </div>
                  </main>
                </div>
              </div>
            </div>`
          : null}`;
    };
  }
);
