import { Light, LitElement, html } from "../lit.js";

import { _ } from "../i18n.js";
import { state } from "../state.js";
import { httpRequest } from "../util.js";

// import Schema from "../validation.js";

// import "../widgets/Form.js";
// import "../widgets/Input.js";

customElements.define(
  "jjcm-competitions",
  class extends Light(LitElement) {
    constructor() {
      super();
      this.selectCompetition({ id: 7 });
    }

    abortController = new AbortController();

    selectCompetition = (competition) =>
      httpRequest("/competitions/" + competition.id, { method: "POST", signal: this.abortController.signal })
        .then(state.checkAuth)
        .catch(console.error);

    disabledRender = () => {
      return html`<h1>${_`Select Competition`}</h1>
        ${state.competitions
          ? html`<x-table
              tableClass="table table-striped table-hover table-bordered"
              @filter=${this.resetBatchSelection}
              .data=${state.competitions.slice()}
              .sort=${[[1, "asc"]]}
              .columns=${[
                {
                  header: _`Name`,
                  accessor: "name",
                  sortable: true,
                  filterable: true,
                  render: (x) => x.name,
                },
                {
                  header: _`Date`,
                  sortable: true,
                  filterable: true,
                  accessor: "date",
                  render: (x) => x.date && _`${x.date}:date`,
                },
                {
                  header: _`Role`,
                  sortable: true,
                  filterable: true,
                  accessor: "role",
                  render: (x) =>
                    x.admins.find((u) => u.id == state.session.user.id) ? _`Competition Admin` : _`Dojo Admin`,
                },
                {
                  class: "text-center",
                  render: (x) =>
                    html`<button class="btn btn-primary" @click=${() => this.selectCompetition(x)}>
                      ${_`Select Competition`}
                    </button>`,
                },
              ]}
            />`
          : null}`;
    };
  }
);
