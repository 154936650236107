import { Light, LitElement, html } from "../lit.js";
import { httpRequest, range, unique, shuffle } from "../util.js";
import { _ } from "../i18n.js";
import { state } from "../state.js";

import { clsx } from "../lib/clsx.js";

import "../widgets/Form.js";
import "../widgets/Input.js";
import "../widgets/Table.js";

import { ranks } from "./Rank.js";

import "./RandomAttack.js";
import "./GroundFighting.js";

import { disciplineTitle } from "./Categories.js";

customElements.define(
  "jjcm-competition-board",
  class extends Light(LitElement) {
    abortController = new AbortController();

    static properties = {
      category: { type: Object },
    };

    constructor() {
      super();

      document.body.style.width = "fit-content";

      this.doubleElimination = true;
      this.rounds = [];
      this.activeMatch = null;

      this.canvas = null;
    }

    firstUpdated = () => this.updateMatches();

    updateMatches = () =>
      httpRequest(
        `/competitions/${state.session.competition_id}/categories/${this.category.id}/games?rel=aka;rel=shiro;rel=competitor;rel=dojo;rel=age_class;rel=results`,
        {
          signal: this.abortController.signal,
        }
      )
        .then((response) => response.json())
        .then((data) => {
          this.rounds = data;
          if (this.rounds[this.rounds.length - 1].major.length) {
            this.rounds[this.rounds.length - 1].major[0].number = `(${
              this.rounds[this.rounds.length - 1].major[0].number
            })`;
          }
          this.requestUpdate();
        })
        .catch(console.error);

    matchComplete = (match) => match.results.length;
    matchNotReady = (match) => !(match.aka && match.shiro);

    activateMatch = (match) => {
      if (match.bye || this.matchComplete(match) || this.matchNotReady(match)) return;
      this.activeMatch = match;
      this.requestUpdate();
    };

    deactivateMatch = (e) => {
      e.stopPropagation();
      this.activeMatch = null;
      this.updateMatches();
    };

    close = () => {
      this.canvas?.remove();
      this.dispatchEvent(new Event("closed", { bubbles: true }));
    };

    renderMatch = (bracket, round, number, match) => html`<div
      id="${bracket}_${round}_${number}"
      class="${clsx("match", "shadow", {
        bye: match.bye,
        complete: this.matchComplete(match),
        "not-ready": this.matchNotReady(match),
      })}"
      @click=${() => this.activateMatch(match)}
    >
      <table>
        <tr class="aka">
          <td rowspan="2" class="match-number">${match.number}</td>
          <td class="color"></td>
          <td
            class="${clsx("competitor", {
              bye: match.bye && !match.aka,
              lost: match.results.length && match.shiro?.id === match.results.slice(-1)[0]?.winner_id,
            })}"
          >
            ${match.bye && !match.aka
              ? _`Bye`
              : html`<div class="name">${match.aka?.competitor.given_name} ${match.aka?.competitor.name}</div>
                  <div class="dojo">${match.aka?.dojo.name}</div>
                  <div class="points row">
                    ${match.results
                      .filter((r) => r.type == "elimination_points")
                      .map((r) => html`<div class="col">${r.aka}</div>`)}
                  </div>`}
          </td>
          <td class="points">
            ${match.results.filter((r) => r.type === "elimination_points").length
              ? match.results
                  .filter((r) => r.type === "elimination_points")
                  .map((r) => r.aka)
                  .reduce((p, c) => p + c, 0)
              : null}
          </td>
        </tr>
        <tr class="shiro">
          <td class="color"></td>
          <td
            class="${clsx("competitor", {
              bye: match.bye && !match.shiro,
              lost: match.results.length && match.aka?.id === match.results.slice(-1)[0]?.winner_id,
            })}"
          >
            ${match.bye && !match.shiro
              ? _`Bye`
              : html`<div class="name">${match.shiro?.competitor.given_name} ${match.shiro?.competitor.name}</div>
                  <div class="dojo">${match.shiro?.dojo.name}</div>
                  <div class="points row">
                    ${match.results
                      .filter((r) => r.type == "elimination_points")
                      .map((r) => html`<div class="col">${r.shiro}</div>`)}
                  </div>`}
          </td>
          <td class="points">
            ${match.results.filter((r) => r.type === "elimination_points").length
              ? match.results
                  .filter((r) => r.type === "elimination_points")
                  .map((r) => r.shiro)
                  .reduce((p, c) => p + c, 0)
              : null}
          </td>
        </tr>
      </table>
    </div>`;

    renderActiveMatch = () =>
      this.category.discipline == "RandomAttack"
        ? html`<jjcm-random-attack-match .match=${this.activeMatch} @closed=${this.deactivateMatch} />`
        : html`<jjcm-ground-fighting-match .match=${this.activeMatch} @closed=${this.deactivateMatch} />`;

    render = () =>
      this.activeMatch
        ? this.renderActiveMatch()
        : html`<h1>${disciplineTitle(this.category.discipline)} - ${this.category.name}</h1>
            <button class="btn-close" @click=${this.close} />
            <div class="brackets">
              <div class="winner-bracket d-flex flex-row">
                ${this.rounds.map(
                  (round, i) => html`<div
                    class="${clsx(
                      "round",
                      "d-flex",
                      "flex-column",
                      "justify-content-around",
                      "align-items-center",
                      "p-3",
                      {
                        last: i == this.rounds.length - 1,
                        // "double-elimination": this.doubleElimination,
                        "single-elimination": !this.doubleElimination,
                      }
                    )}"
                    style="width: ${Math.round(100 / this.rounds.length)}%"
                  >
                    ${this.rounds.length > 1 && i == this.rounds.length - 1
                      ? html`<div class="position-absolute top-0"><h1>${_`Final round`}</h1></div>`
                      : null}
                    ${round.winner.map((x, k) => this.renderMatch("W", i, k, x))}
                  </div>`
                )}
              </div>
              <div class="bracket d-flex flex-row">
                <div
                  class="round d-flex flex-column justify-content-around align-items-center p-3"
                  style="width: ${Math.round(100 / this.rounds.length)}%"
                ></div>
                ${this.rounds.map(
                  (round, i) => html`${round.minor
                    ? html`<div
                        class="${clsx(
                          "round",
                          "minor",
                          "d-flex",
                          "flex-column",
                          "justify-content-around",
                          "align-items-center",
                          "p-3",
                          {
                            last: i == this.rounds.length - 1,
                            // "double-elimination": this.doubleElimination,
                            "single-elimination": !this.doubleElimination,
                          }
                        )}"
                        style="width: ${Math.round(50 / this.rounds.length)}%"
                      >
                        ${round.minor?.map((x, k) => this.renderMatch("l", i, k, x))}
                      </div>`
                    : null}
                  ${round.major
                    ? html`<div
                        class="${clsx(
                          "round",
                          "major",
                          "d-flex",
                          "flex-column",
                          "justify-content-around",
                          "align-items-center",
                          "p-3",
                          {
                            last: i == this.rounds.length - 1,
                            // "double-elimination": this.doubleElimination,
                            "single-elimination": !this.doubleElimination,
                          }
                        )}"
                        style="width: ${Math.round((i == this.rounds.length - 1 ? 100 : 50) / this.rounds.length)}%"
                      >
                        ${round.major?.map((x, k) => this.renderMatch("L", i, k, x))}
                      </div>`
                    : null}`
                )}
              </div>
            </div>`;

    findAbsolutePosition = (element) => {
      const position = { x: 0, y: 0 };
      for (; element != null; element = element.offsetParent) {
        position.x += element.offsetLeft;
        position.y += element.offsetTop;
      }
      return position;
    };

    drawConnection = (leftId, rightId) => {
      const left = document.getElementById(leftId);
      if (!left) return; // UNJJ ???
      const right = document.getElementById(rightId);
      const start = this.findAbsolutePosition(left);
      start.x += left.offsetWidth;
      start.y += left.offsetHeight / 2;
      const end = this.findAbsolutePosition(right);
      end.y += right.offsetHeight / 2;

      const connector = document.createElementNS("http://www.w3.org/2000/svg", "path");
      connector.setAttribute("d", `M ${start.x},${start.y}H${(start.x + end.x) / 2}V${end.y}H${end.x}`);
      connector.setAttribute("fill", "none");
      connector.setAttribute("stroke", "#dee2e6");
      connector.setAttribute("stroke-width", "3px");
      this.canvas.appendChild(connector);
    };

    updated = () => {
      this.canvas?.remove();
      if (!this.rounds.length) return;
      if (this.activeMatch) return;
      this.canvas = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      this.canvas.setAttribute("style", "position:absolute;top:0px;left:0px;pointer-events:none");
      this.canvas.setAttribute("width", document.body.clientWidth);
      this.canvas.setAttribute("height", document.body.clientHeight);
      this.canvas.setAttributeNS("http://www.w3.org/2000/xmlns/", "xmlns:xlink", "http://www.w3.org/1999/xlink");
      document.body.appendChild(this.canvas);

      range(0, this.rounds.length - 1).forEach((i) =>
        // range(0, Math.pow(2, this.rounds.length - i - (this.doubleElimination ? 2 : 1))).forEach((k) => { // real double-elimination
        range(0, Math.pow(2, this.rounds.length - i - (this.doubleElimination ? 1 : 1))).forEach((k) => {
          this.drawConnection(`W_${i}_${k}`, `W_${i + 1}_${Math.floor(k / 2)}`);
        })
      );
      if (this.doubleElimination) {
        // range(1, this.rounds.length - 2).forEach((i) =>  // real double-elimination
        range(1, this.rounds.length - 1).forEach((i) =>
          range(0, Math.pow(2, this.rounds.length - i - 1 /*2*/)).forEach((k) => {
            this.drawConnection(`l_${i}_${k}`, `L_${i}_${k}`);
            this.drawConnection(`L_${i}_${k}`, `l_${i + 1}_${Math.floor(k / 2)}`);
          })
        );
        // for real double-elimination
        // this.drawConnection(`l_${this.rounds.length - 2}_${0}`, `L_${this.rounds.length - 2}_${0}`);
        // this.drawConnection(`L_${this.rounds.length - 2}_${0}`, `W_${this.rounds.length - 1}_${0}`);
      }
    };
  }
);

customElements.define(
  "jjcm-competition-table",
  class extends Light(LitElement) {
    constructor() {
      super();
      this.parentNode.classList.add("h-100");
      this.classList.add("d-flex", "flex-fill", "flex-column", "h-100");
    }
    render = () =>
      html`<h1>${_`Run competition`}</h1>
        <iframe src="/blubb" class="w-100 h-100 border border-2 rounded" />`;
  }
);
