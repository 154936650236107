import { Light, LitElement, html } from "../lit.js";
import { httpRequest, range, unique, isObject } from "../util.js";
import { _ } from "../i18n.js";
import { state } from "../state.js";
import { isDefined, accessPathInObject } from "../util.js";
import Schema from "../validation.js";

import { clsx } from "../lib/clsx.js";
import zipcelx from "../lib/zipcelx.js";

import "../widgets/Form.js";
import "../widgets/Input.js";
import "../widgets/Table.js";

import { sexInfo } from "./Sex.js";
import { ranks, rankNames } from "./Rank.js";

const officialRoles = ["random_attack_referee",
  "random_attack_judge", 
  "ground_fighting_referee", 
  "ground_fighting_judge",
  "sparring_referee", 
  "sparring_judge",
  "continuous_fighting_referee", 
  "continuous_fighting_judge",
  "pairs_referee",
  "pairs_judge",
  "empty_hand_kata_judge",
  "weapons_kata_judge",
  "two_person_empty_hand_kata_judge",
  "two_person_weapons_kata_judge",
  "team_judge"
];

customElements.define(
  "jjcm-officials",
  class extends Light(LitElement) {
    abortController = new AbortController();

    constructor() {
      super();
      this.updateOfficials();
      this.officials = [];
      // this.selectAll = false;
      this.showSelector = false;
      this.showAddDialog = false;
      this.modal = null;
      document.addEventListener("hidden.bs.modal", (e) => {
        this.showSelector = false;
        this.showAddDialog = false;
        this.officialToEdit = null;
        this.officialToDelete = null;
        this.modal = null;
        this.requestUpdate();
      });
    }

    firstUpdated() {
      // this.showSelector = false;
      // this.showAddDialog = false;
      this.requestUpdate();
    }

    reload = (action = () => {}) => {
      if (this.modal) this.modal.hide();
      this.modal = null;
      this.showSelector = false;
      this.showAddDialog = false;
      this.officialToEdit = null;
      this.officialToDelete = null;
      action();
    };

    updateOfficials = () => {
      Promise.all([
        httpRequest(
          `/competitions/${state.session.competition_id}/officials?rel=dojo`,
          {
            signal: this.abortController.signal,
          }
        ),
        httpRequest("/competitors?rel=dojo", {
          signal: this.abortController.signal,
        }),
        httpRequest(`/competitions/${state.session.competition_id}/dojos`, {
          signal: this.abortController.signal,
        }),
      ])
        .then((responses) => Promise.all(responses.map((r) => r.json())))
        .then(([officials, persons, dojos]) => {
          this.officials = officials;
          this.persons = persons.filter((c) => !officials.some((o) => o.person_id === c.id));
          this.dojos = dojos;
        })
        .then(() => this.requestUpdate())
        .catch(console.error);
    };

    addRegistrations = (data, submittingDone) =>
      httpRequest(`/competitions/${state.session.competition_id}/officials`, {
        method: "POST",
        body: JSON.stringify(data.map((x) => Object.fromEntries([["person", x], ["rank_id", ranks.get(x.rank).key], ...officialRoles.map((role) => ([role, x[role]]))]))),
      })
        .then((response) => response.ok && response)
        .then((data) => {
          this.reload(this.updateOfficials);
        })
        .catch((e) => {
          console.error(e);
          submittingDone();
        });

    registerOfficials = (e) =>
      this.addRegistrations(
        Object.values(e.target.values)
          .filter((x) => x.selected)
          .map((c) => Object.fromEntries(["id", "name", "rank", ...officialRoles].map((k) => [k, c[k]]))),
        e.target.submittingDone
      );

    addOfficials = (e) => this.addRegistrations(e.target.values.officials, e.target.submittingDone);

    editOfficial = (e) => {
      const values = Object.fromEntries(["id", "name", "rank_id", ...officialRoles].map((k) => [k, e.target.values[k]]));
      httpRequest(`/competitions/${state.session.competition_id}/officials/${values.id}`, {
        signal: this.abortController.signal,
        method: "PATCH",
        body: JSON.stringify(values),
      })
        .then((response) => response.ok && response)
        .then((data) => this.reload(this.updateOfficials))
        .catch((e) => {
          console.error(e);
          submittingDone();
        });
    };

    deleteOfficial = (id) =>
      httpRequest(`/competitions/${state.session.competition_id}/officials/${id}`, {
        signal: this.abortController.signal,
        method: "DELETE",
      })
        .then((response) => response.ok && response)
        .then(() => this.reload(this.updateOfficials))
        .catch(console.error);

    showOfficialEditor = (official) => {
      this.officialToEdit = (({ id, name, given_name, birthdate, dojo, rank_id,
        random_attack_referee, random_attack_judge,
        ground_fighting_referee, ground_fighting_judge, 
        sparring_referee, sparring_judge, 
        continuous_fighting_referee, continuous_fighting_judge,
        pairs_referee,
        pairs_judge,
        empty_hand_kata_judge,
        weapons_kata_judge,
        two_person_empty_hand_kata_judge,
        two_person_weapons_kata_judge,
        team_judge
      }) => ({
        id,
        name,
        given_name,
        birthdate,
        dojo,
        rank_id,
        random_attack_referee, random_attack_judge,
        ground_fighting_referee, ground_fighting_judge, 
        sparring_referee, sparring_judge, 
        continuous_fighting_referee, continuous_fighting_judge,
        pairs_referee,
        pairs_judge,
        empty_hand_kata_judge,
        weapons_kata_judge,
        two_person_empty_hand_kata_judge,
        two_person_weapons_kata_judge,
        team_judge
      }))(official);
      this.requestUpdate();
    };
    askForOfficialDeletion = (official) => {
      this.officialToDelete = official;
      this.requestUpdate();
    };

    render = () => {
      if (!this.modal) {
        this.getUpdateComplete().then(() => {
          const modal = document.querySelector(".modal");
          if (modal) this.modal = new bootstrap.Modal(modal);
          if (this.modal) this.modal.show();
        });
      }
      return html`<h1>${_`Officials`}</h1>
        <div class="d-flex justify-content-end">
          ${this.persons?.length
          ? html`<button
              class="m-2 me-0 btn btn-primary"
              @click=${() => {
                this.showSelector = true;
                this.requestUpdate();
              }}
            >
              ${_`Register existing officials`}
            </button>`
          : null}
          <button
            class="m-2 me-0 btn btn-primary"
            @click=${() => {
          this.showAddDialog = true;
          this.requestUpdate();
        }}
          >
            ${_`Create and register new officials`}
          </button>
        </div>
        <x-table
          tableClass="table table-striped table-hover table-bordered"
          .data=${this.officials}
          .sort=${[[0, "asc"]]}
          .columns=${[
            {
              header: _`Name`,
              sortable: true,
              filterable: true,
              accessor: (x) => `${x.name}, ${x.given_name}`,
              render: (x) => html`<span class="text-nowrap">${x.name}, ${x.given_name}</span><x-rank value="${x.rank_id}" />`
            },
            ...(this.dojos?.length > 1
              ? [
                  {
                    header: _`Dojo`,
                    sortable: true,
                    filterable: true,
                    accessor: (x) => x.dojo.name,
                    class: "minimal-width text-nowrap",
                  },
                ]
              : []),
            ...[
              { header: _`Random Attack`, key: "random_attack_referee", headerSpan: 2, subHeader: _`Referee` },
              { header: null, key: "random_attack_judge", subHeader: _`Judge` },
              { header: _`Ground Fighting`, key: "ground_fighting_referee", headerSpan: 2, subHeader: _`Referee` },
              { header: null, key: "ground_fighting_judge", subHeader: _`Judge` },
              { header: _`Sparring`, key: "sparring_referee", headerSpan: 2, subHeader: _`Referee` },
              { header: null, key: "sparring_judge", subHeader: _`Judge` },
              { header: _`Continuous Fighting`, key: "continuous_fighting_referee", headerSpan: 2, subHeader: _`Referee` },
              { header: null, key: "continuous_fighting_judge", subHeader: _`Judge` },
              { header: _`Pairs`, key: "pairs_referee", headerSpan: 2, subHeader: _`Referee` },
              { header: null, key: "pairs_judge", subHeader: _`Judge` },
              // { header: _`Kata`, key: "kata" },
              { header: `${_`One Person Kata`} ${_`Judge`}`, key: "empty_hand_kata_judge", headerSpan: 2, subHeader: _`Empty Hand` },
              { header: null, key: "weapons_kata_judge", subHeader: _`Weapons` },
              { header: `${_`Two Person Kata`} ${_`Judge`}`, key: "two_person_empty_hand_kata_judge", headerSpan: 2, subHeader: _`Empty Hand` },
              { header: null, key: "two_person_weapons_kata_judge", subHeader: _`Weapons` },
              { header: _`Team`, key: "team_judge", subHeader: _`Judge` },
            ].map((c) => ({
              // header: html`<span title="${c.title}">${c.header}</span>`,
              header: c.header,
              headerClass: "text-nowrap",
              headerSpan: c.headerSpan,
              subHeader: c.subHeader,
              span: c.span || 1,
              // sortable: true,
              // filterable: true,
              accessor: (x) => (x[c.key] ? "t" : "f"),
              render: (x) => ({YES: html`<span class="text-success">✓</span>`, NO: html`<span class="text-danger">&times;</span>`, IF_NEEDED: html`<span class="text-warning">(✓)</span>`}[x[c.key]]),
              class: "minimal-width fs-5 fw-bold",
              footer: (data) => `${data.filter((x) => x[c.key] == "YES").length} + ${data.filter((x) => x[c.key] == "IF_NEEDED").length}`,
            })),
            {
              class: "minimal-width text-nowrap",
              render: (x) =>
                html`<button
                    class="btn btn-link"
                    title="${_`Edit official`}"
                    @click=${() => this.showOfficialEditor(x)}
                  >
                    <i class="bi bi-pencil-square" />
                  </button>
                  <button
                    class="btn btn-link"
                    title="${_`Unregister official`}"
                    @click=${() => this.askForOfficialDeletion(x)}
                  >
                    <i class="bi bi-trash3" /i>
                  </button>`,
            },
          ]}
        />
        ${this.officialToEdit
          ? html`<div class="modal fade">
        <div class="modal-dialog modal-xxl modal-dialog-centered">
          <div class="modal-content">
            <header class="modal-header">
              <h5>${_`Edit official`}</h5>
            </header>
            <main class="modal-body">
              <x-form
                @submit=${this.editOfficial}
                .validate=${(values) =>
                  Schema.Struct({
                    name: Schema.String().test((x, p) => !p.selected || (isDefined(x) && x), _`Name missing!`),
                    rank: Schema.String().test((x, p) => !p.selected || isDefined(x), _`Rank missing!`),
                    ...Object.fromEntries(officialRoles.map((r) => [r, Schema.String().oneOf(["NO", "YES", "IF_NEEDED"]).required()]))
                  }).validate(values)}
                .values=${this.officialToEdit}
                .renderContent=${({ values, errors, touched, submitting }) => html`<table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th colspan="${this.dojos?.length > 1 ? 5 : 4}"></th>
                      <th colspan="2"><label>${_`Random Attack`}</label></th>
                      <th colspan="2"><label>${_`Ground Fighting`}</label></th>
                      <th colspan="2"><label>${_`Sparring`}</label></th>
                      <th colspan="2"><label>${_`Continuous Fighting`}</label></th>
                      <th colspan="2"><label>${_`Pairs`}</label></th>
                      <th colspan="4"><label>${_`Kata`} ${_`Judge`}</label></th>
                      <th><label>${_`Team`}</label></th>
                    </tr>
                    <tr>
                      <th><label>${_`Name`}</label></th>
                      <th><label>${_`Given Name`}</label></th>
                      <th><label>${_`Birthdate`}</label></th>
                      ${this.dojos?.length > 1 ? html`<th><label>${_`Dojo`}</label></th>` : null}
                      <th><label>${_`Rank`}</label></th>
                      <th><label>${_`Referee`}</label></th>
                      <th><label>${_`Judge`}</label></th>
                      <th><label>${_`Referee`}</label></th>
                      <th><label>${_`Judge`}</label></th>
                      <th><label>${_`Referee`}</label></th>
                      <th><label>${_`Judge`}</label></th>
                      <th><label>${_`Referee`}</label></th>
                      <th><label>${_`Judge`}</label></th>
                      <th><label>${_`Referee`}</label></th>
                      <th><label>${_`Judge`}</label></th>
                      <th><label>${_`One Person Empty Hand`}</label></th>
                      <th><label>${_`One Person Weapons`}</label></th>
                      <th><label>${_`Two Person Empty Hand`}</label></th>
                      <th><label>${_`Two Person Weapons`}</label></th>
                      <th><label>${_`Judge`}</label></th>
                    </tr>
                  </thead>
                  <tr>
                    <td>
                      <input
                        class="${clsx("form-control", {
                          "is-invalid": touched.name && errors.name,
                        })}"
                        type="text"
                        name="name"
                      />
                    </td>
                    <td>
                      <div class="form-control" style="background-color: #e9ecef">
                        ${this.officialToEdit.given_name}
                      </div>
                    </td>
                    <td>
                      <div class="form-control" style="background-color: #e9ecef">
                        ${_`${this.officialToEdit.birthdate}:date`}
                      </div>
                    </td>
                    ${this.dojos?.length > 1
                      ? html`<td>
                          <div class="form-control" style="background-color: #e9ecef">
                            ${this.officialToEdit.dojo.name}
                          </div>
                        </td>`
                      : null}
                    <td>
                      <x-select
                        inputclass="${clsx("form-control", {
                          "is-invalid": touched.rank && errors.rank,
                        })}"
                        name="rank_id"
                        value="${values.rank_id}"
                        .options=${ranks.enums.filter(x => x >= ranks.SHODAN && x <= ranks.JUDAN)}
                        .optionToString=${(x) => rankNames[x]}
                        .optionToCandidate=${(x) => html`<x-rank value="${x}" />`}
                      />
                    </td>
                    ${officialRoles.map((x) => html`<td>
                      <x-select
                        class="${clsx({
                          "is-invalid": touched[x] && errors[x],
                        })}"
                        inputClass="${clsx("form-control", {
                          "is-invalid": touched[x] && errors[x],
                        })}"
                        name="${x}"
                        value="${values[x]}"
                        .options=${["NO", "IF_NEEDED", "YES"]}
                        .optionToString=${(x) => ({NO: _`no`, IF_NEEDED: _`if needed`, YES: _`yes`}[x])}
                      />
                      <div class="invalid-feedback">
                        ${errors[x]}
                      </div>
                    </td>`)}
                  </tr>
                </table>
                <div class="d-flex justify-content-end">
                  <button class="m-2 me-0 btn btn-secondary" type="reset" ?disabled=${submitting}>${_`Reset`}</button>
                  <button class="m-2 me-0 btn btn-primary" type="submit" ?disabled=${submitting}>${_`Apply`}</button>
                </div>`}
              />
            </main>
            </div>
          </div>
        </div>
      </div>`
          : null}
        ${this.officialToDelete
          ? html`<div class="modal fade">
              <div class="modal-dialog modal modal-dialog-centered">
                <div class="modal-content">
                  <header class="modal-header">
                    <h5>${_`Confirmation required!`}</h5>
                  </header>
                  <main class="modal-body">
                    ${_`Unregister ${this.officialToDelete.given_name} ${this.officialToDelete.name} from this competition?`}
                  </main>
                  <footer class="modal-footer">
                    <div class="d-flex justify-content-end">
                      <button class="m-2 me-0 btn btn-secondary" @click=${() => this.reload()}>${_`Cancel`}</button>
                      <button
                        class="m-2 me-0 btn btn-primary"
                        @click=${() => this.deleteOfficial(this.officialToDelete.id)}
                      >
                        ${_`Confirm`}
                      </button>
                    </div>
                  </footer>
                </div>
              </div>
            </div>`
          : null}
        ${this.showSelector
          ? html`<div class="modal fade">
              <div class="modal-dialog modal-xxl modal-dialog-centered">
                <div class="modal-content">
                  <header class="modal-header"><h1>${_`Register already existing official`}</h1></header>
                  <main class="modal-body">
                    <x-form
                      @submit=${this.registerOfficials}
                      .validate=${(values) =>
                        Schema.Dictionary(
                          Schema.Struct({
                            name: Schema.String().test((x, p) => !p.selected || (isDefined(x) && x), _`Name missing!`),
                          })
                        ).validate(values)}
                      .values=${this.persons?.reduce((p, c) => {
                        p[c.id] = c;
                        return p;
                      }, {}) || []}
                      .renderContent=${({ values, errors, touched, submitting }) => html`<x-table
                        tableClass="table table-striped table-hover table-bordered"
                        .data=${Object.entries(values)
                          .map(([k, v]) => v)
                          .filter((x) => !x.inactive)}
                        .columns=${[
                          {
                            header: "",
                            headerSpan: this.dojos?.length > 1 ? 6 : 5,
                            subHeader: "",
                            render: (x) => html`<label>
                              <input type="hidden" name="${x.id}.inactive" />
                                <i
                                  class="bi bi-eye-slash"
                                  title="${_`Do not show again`}"
                                  @click=${(e) => {
                                    e.target.previousElementSibling.value = true;
                                    e.target.previousElementSibling.dispatchEvent(
                                      new Event("change", { bubbles: true })
                                    );
                                  }}
                              />
                            </label>`
                          },
                          {
                            header: null,
                            subHeader: _`Name`,
                            render: (x) => html`<input
                              class="${clsx("form-control", {
                                "is-invalid": touched[x.id]?.name && errors[x.id]?.name,
                              })}"
                              type="text"
                              name="${x.id}.name"
                              value="${values[x.id].name}"
                              title="${values[x.id].name}"
                            />`,
                          },
                          {
                            header: null,
                            subHeader: _`Given Name`,
                            accessor: "given_name",
                          },
                          {
                            header: null,
                            subHeader: _`Birthdate`,
                            render: (x) => _`${x.birthdate}:date`,
                          },
                          ...(this.dojos?.length > 1 ? [{
                            header: null,
                            subHeader: _`Dojo`,
                            accessor: (x) => x.dojo.name,
                            class: "minimal-width text-nowrap",        
                          }] : []),
                          {
                            header: null,
                            subHeader: _`Rank`,
                            render: (x) => html`<x-select
                              inputclass="${clsx("form-control", {
                                "is-invalid": touched[x.id]?.rank && errors[x.id]?.rank,
                              })}"
                              name="${x.id}.rank"
                              value="${values[x.id].rank}"
                              .options=${ranks.enums.filter(x => x >= ranks.SHODAN && x <= ranks.JUDAN)}
                              .optionToString=${(x) => rankNames[x]}
                              .optionToCandidate=${(x) => html`<x-rank value="${x}" />`}
                            />`
                          },
                          ...[
                            { header: _`Random Attack`, headerSpan: 2, subHeader: _`Referee`, key: "random_attack_referee" },
                            { header: null, subHeader: _`Judge`, key: "random_attack_judge" },
                            { header: _`Ground Fighting`, headerSpan: 2, subHeader: _`Referee`, key: "ground_fighting_referee" },
                            { header: null, subHeader: _`Judge`, key: "ground_fighting_judge" },
                            { header: _`Sparring`, headerSpan: 2, subHeader: _`Referee`, key: "sparring_referee" },
                            { header: null, subHeader: _`Judge`, key: "sparring_judge" },
                            { header: _`Continuous Fighting`, headerSpan: 2, subHeader: _`Referee`, key: "continuous_fighting_referee" },
                            { header: null, subHeader: _`Judge`, key: "continuous_fighting_judge" },
                            { header: _`Pairs`, headerSpan: 2, subHeader: _`Referee`, key: "pairs_referee" },
                            { header: null, subHeader: _`Judge`, key: "pairs_judge" },
                            { header: `${_`One Person Kata`} ${_`Judge`}`, headerSpan: 2, subHeader: _`Empty Hand`, key: "empty_hand_kata_judge" },
                            { header: null, subHeader: _`Weapons`, key: "weapons_kata_judge" },
                            { header: `${_`Two Person Kata`} ${_`Judge`}`, headerSpan: 2, subHeader: _`Empty Hand`, key: "two_person_empty_hand_kata_judge" },
                            { header: null, subHeader: _`Weapons`, key: "two_person_weapons_kata_judge" },
                            { header: _`Team`, subHeader: _`Judge`, key: "team_judge" },
                          ].map((role) => ({...role, render: (x) => html`<x-select
                            inputclass="${clsx("form-control", {
                              "is-invalid": touched[x.id]?.[role.key] && errors[x.id]?.[role.key],
                            })}"
                            name="${x.id}.${role.key}"
                            value="${values[x.id][role.key]}"
                            .options=${["NO", "IF_NEEDED", "YES"]}
                            .optionToString=${(x) => ({NO: _`no`, IF_NEEDED: _`if needed`, YES: _`yes`}[x])}
                          />`})),
                          {
                            header: "",
                            subHeader: _`Register`,
                            class: "minimal-width center",
                            render: (x) =>
                              html`<input
                                type="checkbox"
                                class="form-check-input"
                                name="${x.id}.selected"
                                value="${x.id}.selected"
                              />`,
                          },
                        ]}
                      />
                      <div class="d-flex justify-content-end">
                        <button class="m-2 me-0 btn btn-secondary" type="reset" ?disabled=${submitting}>
                          ${_`Reset`}
                        </button>
                        <button class="m-2 me-0 btn btn-primary" type="submit" ?disabled=${submitting}>
                          ${_`Register selected officials`}
                        </button>
                      </div>`}
                    />
                  </main>
                </div>
              </div>
            </div>`
          : null}
        ${this.showAddDialog && this.dojos
          ? html`<div class="modal fade">
              <div class="modal-dialog modal-xxl modal-dialog-centered">
                <div class="modal-content">
                  <header class="modal-header"><h1>${_`Create and register new officials`}</h1></header>
                  <main class="modal-body">
                    <x-form
                      @submit=${this.addOfficials}
                      .validate=${(values) =>
                        Schema.Struct({
                          officials: Schema.Array(
                            Schema.Struct({
                              name: Schema.String().required(_`Name missing!`),
                              given_name: Schema.String().required(_`Given name missing!`),
                              birthdate: Schema.Date().required(_`Birthdate missing!`),
                              sex: Schema.String().required(_`Sex missing!`),
                              dojo: Schema.String().required(_`Dojo missing!`),
                              rank: Schema.String().required(_`Rank missing!`),
                              ...Object.fromEntries(officialRoles.map((r) => [r, Schema.String().oneOf(["NO", "IF_NEEDED", "YES"]).required()]))
                            }).test(
                              (x) =>
                                ![...this.persons, ...this.officials].some(
                                  (c) =>
                                    c.name === x.name && c.given_name === x.given_name && c.birthdate === x.birthdate
                                ),
                              _`Person already exists!`
                            )
                          ),
                        }).validate(values)}
                      .values=${{
                        officials: [this.dojos?.length === 1 ? { dojo: this.dojos[0] } : {}],
                      }}
                      .renderContent=${({ values, errors, touched, submitting }) => html`<div>
                          <x-form-array
                            name="officials"
                            value="${JSON.stringify(values.officials)}"
                            .renderContent=${({ itemName, push, pop, length }) =>
                              html`<table class="table table-striped table-bordered">
                                <thead>
                                  <tr>
                                    <th colspan="${this.dojos?.length > 1 ? 6 : 5}"></th>
                                    <th colspan="2"><label>${_`Random Attack`}</label></th>
                                    <th colspan="2"><label>${_`Ground Fighting`}</label></th>
                                    <th colspan="2"><label>${_`Sparring`}</label></th>
                                    <th colspan="2"><label>${_`Continuous Fighting`}</label></th>
                                    <th colspan="2"><label>${_`Pairs`}</label></th>
                                    <th colspan="4"><label>${_`Kata`} ${_`Judge`}</label></th>
                                    <th><label>${_`Team`}</label></th>
                                  </tr>
                                  <tr>
                                    <th><label>${_`Name`}</label></th>
                                    <th><label>${_`Given name`}</label></th>
                                    <th><label>${_`Birthdate`}</label></th>
                                    <th><label>${_`Sex`}</label></th>
                                    ${this.dojos?.length > 1 ? html`<th><label>${_`Dojo`}</label></th>` : null}
                                    <th><label>${_`Rank`}</label></th>
                                    <th><label>${_`Referee`}</label></th>
                                    <th><label>${_`Judge`}</label></th>
                                    <th><label>${_`Referee`}</label></th>
                                    <th><label>${_`Judge`}</label></th>
                                    <th><label>${_`Referee`}</label></th>
                                    <th><label>${_`Judge`}</label></th>
                                    <th><label>${_`Referee`}</label></th>
                                    <th><label>${_`Judge`}</label></th>
                                    <th><label>${_`Referee`}</label></th>
                                    <th><label>${_`Judge`}</label></th>
                                    <th><label>${_`One Person Empty Hand`}</label></th>
                                    <th><label>${_`One Person Weapons`}</label></th>
                                    <th><label>${_`Two Person Empty Hand`}</label></th>
                                    <th><label>${_`Two Person Weapons`}</label></th>
                                    <th><label>${_`Judge`}</label></th>
                                  </tr>
                                </thead>
                                ${range(0, length).map(
                                  (i) => html`<tr class="align-top">
                                    <td>
                                      <input
                                        class="${clsx("form-control input-sm", {
                                          "is-invalid":
                                            accessPathInObject(touched, itemName(i)).get()?.name &&
                                            (!isObject(accessPathInObject(errors, itemName(i)).get()) ||
                                              accessPathInObject(errors, itemName(i)).get()?.name),
                                        })}"
                                        type="text"
                                        name="${itemName(i)}.name"
                                        value="${accessPathInObject(values, itemName(i)).get()?.name}"
                                      />
                                      <div class="invalid-feedback">
                                        ${isObject(accessPathInObject(errors, itemName(i)).get())
                                          ? accessPathInObject(errors, itemName(i)).get().name
                                          : accessPathInObject(errors, itemName(i)).get()}
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        class="${clsx("form-control", {
                                          "is-invalid":
                                            accessPathInObject(touched, itemName(i)).get()?.given_name &&
                                            (!isObject(accessPathInObject(errors, itemName(i)).get()) ||
                                              accessPathInObject(errors, itemName(i)).get()?.given_name),
                                        })}"
                                        type="text"
                                        name="${itemName(i)}.given_name"
                                        value="${accessPathInObject(values, itemName(i)).get()?.given_name}"
                                      />
                                      <div class="invalid-feedback">
                                        ${accessPathInObject(errors, itemName(i)).get()?.given_name}
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        class="${clsx("form-control", {
                                          "is-invalid":
                                            accessPathInObject(touched, itemName(i)).get()?.birthdate &&
                                            (!isObject(accessPathInObject(errors, itemName(i)).get()) ||
                                              accessPathInObject(errors, itemName(i)).get()?.birthdate),
                                        })}"
                                        type="date"
                                        name="${itemName(i)}.birthdate"
                                        value="${accessPathInObject(values, itemName(i)).get()?.birthdate}"
                                      />
                                      <div class="invalid-feedback">
                                        ${accessPathInObject(errors, itemName(i)).get()?.birthdate}
                                      </div>
                                    </td>
                                    <td>
                                      <x-select
                                        class="${clsx({
                                          "is-invalid":
                                            accessPathInObject(touched, itemName(i)).get()?.sex &&
                                            accessPathInObject(errors, itemName(i)).get()?.sex,
                                        })}"
                                        inputClass="${clsx("form-control", {
                                          "is-invalid":
                                            accessPathInObject(touched, itemName(i)).get()?.sex &&
                                            accessPathInObject(errors, itemName(i)).get()?.sex,
                                        })}"
                                        name="${itemName(i)}.sex"
                                        value="${accessPathInObject(values, itemName(i)).get()?.sex}"
                                        .options=${["MALE", "FEMALE"]}
                                        .optionToString=${(x) => sexInfo[x].name()}
                                      />
                                      <div class="invalid-feedback">
                                        ${accessPathInObject(errors, itemName(i)).get()?.sex}
                                      </div>
                                    </td>
                                    ${this.dojos?.length > 1
                                      ? html`<td>
                                          <x-select
                                            class="${clsx({
                                              "is-invalid":
                                                accessPathInObject(touched, itemName(i)).get()?.dojo &&
                                                accessPathInObject(errors, itemName(i)).get()?.dojo,
                                            })}"
                                            inputclass="${clsx("form-control", {
                                              "is-invalid":
                                                accessPathInObject(touched, itemName(i)).get()?.dojo &&
                                                accessPathInObject(errors, itemName(i)).get()?.dojo,
                                            })}"
                                            name="${itemName(i)}.dojo"
                                            value="${accessPathInObject(values, itemName(i)).get()?.dojo}"
                                            .options=${this.dojos}
                                            .optionToString=${(x) => x.name}
                                          />
                                          <div class="invalid-feedback">
                                            ${accessPathInObject(errors, itemName(i)).get()?.dojo}
                                          </div>
                                        </td>`
                                      : null}
                                    <td>
                                      <x-select
                                        class="${clsx({
                                          "is-invalid":
                                            accessPathInObject(touched, itemName(i)).get()?.rank &&
                                            accessPathInObject(errors, itemName(i)).get()?.rank,
                                        })}"
                                        inputclass="${clsx("form-control", {
                                          "is-invalid":
                                            accessPathInObject(touched, itemName(i)).get()?.rank &&
                                            accessPathInObject(errors, itemName(i)).get()?.rank,
                                        })}"
                                        name="${itemName(i)}.rank"
                                        value="${accessPathInObject(values, itemName(i)).get()?.rank}"
                                        .options=${ranks.enums.filter(x => x >= ranks.SHODAN && x <= ranks.JUDAN)}
                                        .optionToString=${(x) => rankNames[x]}
                                        .optionToCandidate=${(x) => html`<x-rank value="${x}" />`}
                                      />
                                      <div class="invalid-feedback">
                                        ${accessPathInObject(errors, itemName(i)).get()?.rank}
                                      </div>
                                    </td>
                                    ${officialRoles.map((x) => html`<td>
                                        <x-select
                                          class="${clsx({
                                            "is-invalid":
                                              accessPathInObject(touched, itemName(i)).get()?.[x] &&
                                              accessPathInObject(errors, itemName(i)).get()?.[x],
                                          })}"
                                          inputclass="${clsx("form-control", {
                                            "is-invalid":
                                              accessPathInObject(touched, itemName(i)).get()?.[x] &&
                                              accessPathInObject(errors, itemName(i)).get()?.[x],
                                          })}"
                                          name="${itemName(i)}.${x}"
                                          value="${accessPathInObject(values, itemName(i)).get()?.[x]}"
                                          .options=${["NO", "IF_NEEDED", "YES"]}
                                          .optionToString=${(x) => ({NO: _`no`, IF_NEEDED: _`if needed`, YES: _`yes`}[x])}
                                        />
                                        <div class="invalid-feedback">
                                          ${accessPathInObject(errors, itemName(i)).get()?.[x]}
                                        </div>
                                      </td>`)
                                    }
                                  </tr>`
                                )}
                                </table>
                                <div class="d-flex justify-content-center">
                                  <button
                                    class="btn btn-secondary px-4"
                                    @click=${() => pop()}
                                    ?disabled=${length === 1}
                                  >
                                    -</button
                                  ><button
                                    class="btn btn-primary px-4"
                                    type="button"
                                    @click=${() => push(this.dojos.length === 1 ? { dojo: this.dojos[0] } : {})}
                                  >
                                    +
                                  </button>
                                </div>`}
                          />
                        </div>
                        <div class="d-flex justify-content-end">
                          <button class="m-2 me-0 btn btn-secondary" type="reset" ?disabled=${submitting}>
                            ${_`Reset`}
                          </button>
                          <button class="m-2 me-0 btn btn-primary" type="submit" ?disabled=${submitting}>
                            ${_`Register officials`}
                          </button>
                        </div>`}
                    />
                    <div style="max-height: 0; overflow: clip; transition: max-height .15s linear">
                      <div class="alert ${this.alert_class}">${this.alert_message}</div>
                    </div>
                  </main>
                </div>
              </div>
            </div>`
          : null}`;
    };
  }
);
