import { Light, LitElement, html } from "../lit.js";
import { httpRequest, range, unique } from "../util.js";
import { _ } from "../i18n.js";
import { state } from "../state.js";
import Schema from "../validation.js";

import { clsx } from "../lib/clsx.js";

import "../widgets/Form.js";
import "../widgets/Input.js";
import "../widgets/Table.js";
import { renderAgeAndRankClassCategory, getSortableCategoryName } from "./Categories.js";
import { sexInfo } from "./Sex.js";
import { ranks } from "./Rank.js";

customElements.define(
  "jjcm-entries-list",
  class extends Light(LitElement) {
    abortController = new AbortController();

    static properties = {
      discipline: { type: String },
      config: { type: Object },
    };

    constructor() {
      super();
      this.entries = [];
      this.dojos = [];
      this.form = null;
    }

    firstUpdated = () => this.updateEntries();

    reset = () => {
      this.form = null;
      this.requestUpdate();
      this.updateEntries();
    };

    updateEntries = () =>
      Promise.all([
        httpRequest(`/competitions/${state.session.competition_id}/registrations?rel=dojo;rel=${this.discipline}`, {
          signal: this.abortController.signal,
        }),
        httpRequest(
          `/competitions/${state.session.competition_id}/${this.discipline}/entries?${this.config.relations
            .map((x) => `rel=${x}`)
            .join(";")};rel=dojo;rel=category`,
          {
            signal: this.abortController.signal,
          }
        ),
      ])
        .then((responses) => Promise.all(responses.map((x) => x.json())))
        .then(([registrations, entries]) => {
          this.dojos = unique(registrations.map((x) => x.dojo));
          this.entries = entries;
          this.form = html`<x-form
            @submit=${this.addEntry}
            .validate=${(values) => this.config.schema.validate(values)}
            .renderContent=${({ values, errors, touched, submitting }) =>
              html`${range(0, Math.ceil(this.config.newEntry.length / 3))
                  .map((i) => this.config.newEntry.slice(i * 3, (i + 1) * 3))
                  .map(
                    (x) => html`<div class="row">
                      ${x.map(
                        (f) => html`<div class="col">
                          ${f.label ? html`<label>${f.label}</label>` : null}<x-select
                            class="${clsx({
                              "is-invalid": !!touched[f.name] && !!errors[f.name],
                            })}"
                            inputClass="${clsx("form-control", {
                              "is-invalid": !!touched[f.name] && !!errors[f.name],
                            })}"
                            name="${f.name}"
                            .options=${registrations/*.map(x => ({...x, entries: ['random_attack', 'ground_fighting', 'sparring', 'continuous_fighting', 'pairs', 'empty_hand_kata', 'weapons_kata', 'two_person_empty_hand_kata', 'two_person_weapons_kata'].filter(c => x[c]).length}))*/
                              .filter((x) => !x[this.discipline])
                              .filter((x) => (f.filter || (() => true))(x, values))
                              .map((x) => ({ id: x.id, name: x.name, given_name: x.given_name }))
                              .sort((a, b) => `${a.name}, ${a.given_name}`.localeCompare(`${b.name}, ${b.given_name}`))}
                            .optionToString=${(x) => `${x.name}, ${x.given_name}`}
                          />
                          <div class="invalid-feedback">${errors[f.name.split("[")[0]]}</div>
                        </div>`
                      )}
                    </div>`
                  )}
                ${this.config.additionalFormElements ? this.config.additionalFormElements : null}
                <div class="d-flex justify-content-end">
                  <button class="m-2 me-0 btn btn-secondary" type="button" @click=${this.reset} ?disabled=${submitting}>
                    ${_`Reset`}
                  </button>
                  <button class="m-2 me-0 btn btn-primary" type="submit" ?disabled=${submitting}>
                    ${_`Add ${this.config.title} entry`}
                  </button>
                </div>`}
          />`;
        })
        .then(() => this.requestUpdate())
        .catch(console.error);

    addEntry = (e) =>
      httpRequest(`/competitions/${state.session.competition_id}/${this.discipline}/entries`, {
        signal: this.abortController.signal,
        method: "POST",
        body: JSON.stringify(
          this.discipline === "team"
            ? { registrations: Object.entries(e.target.values).map(([_, v]) => v) }
            : e.target.values
        ),
      })
        .then((response) => response.ok && response)
        .then(() => this.reset())
        .catch((error) => {
          console.error(error);
          e.target.submittingDone();
        });

    deleteEntry = (id) =>
      httpRequest(`/competitions/${state.session.competition_id}/${this.discipline}/entries/${id}`, {
        signal: this.abortController.signal,
        method: "DELETE",
      })
        .then((response) => response.ok && response)
        .then(() => this.reset())
        .catch(console.error);

    render = () => html`${this.form ? this.form : null}
      <pre>${this.entries.map(e => [e.category.name, e.competitor.name, e.competitor.given_name, e.dojo.name].join(";")).join("\n")}</pre>
      <x-table
        tableClass="table table-striped table-hover table-bordered"
        .data=${this.entries}
        .sort=${[[0, "asc"]]}
        .columns=${[
          {
            header: _`Entry`,
            accessor: this.config.accessor,
            sortable: true,
            filterable: true,
          },
          ...(this.dojos.length != 1
            ? [
                {
                  header: _`Dojo`,
                  sortable: true,
                  filterable: true,
                  accessor: (x) => x.dojo.name,
                },
              ]
            : []),
          {
            header: _`Category`,
            sortable: true,
            filterable: true,
            accessor: (x) => getSortableCategoryName(x.category),
            render: (x) => (this.config.renderCategory ? this.config.renderCategory(x.category) : x.category.name),
          },
          {
            class: "minimal-width text-nowrap",
            render: (x) =>
              html`<!--button
                  class="btn btn-link"
                  title="${_`Edit entry`}"
                  @click=${() => this.showEntryEditor(x)}
                >
                  <i class="bi bi-pencil-square" />
                </button-->
                <button class="btn btn-link" title="${_`Delete entry`}" @click=${() => this.deleteEntry(x.id)}>
                  <i class="bi bi-trash3" /i>
                </button>`,
          },
        ]}
      />`;
  }
);

customElements.define(
  "jjcm-entries",
  class extends Light(LitElement) {
    uploadFile = (e) => {
      console.log(e);
    };

    constructor() {
      super();
      this.tabs = {
        random_attack: {
          title: _`Random Attack`,
          relations: ["competitor", "ranks"],
          accessor: (x) => `${x.competitor.name}, ${x.competitor.given_name}`,
          renderCategory: renderAgeAndRankClassCategory,
          newEntry: [{ name: "competitor_registration", label: _`Competitor` }],
          schema: Schema.Struct({
            competitor_registration: Schema.Struct({
              id: Schema.Number(),
              name: Schema.String(),
              given_name: Schema.String(),
            }).required(),
          }),
        },
        ground_fighting: {
          title: _`Ground Fighting`,
          relations: ["competitor", "age_class"],
          accessor: (x) => `${x.competitor.name}, ${x.competitor.given_name}`,
          newEntry: [{ name: "competitor_registration", label: _`Competitor`, filter: (x) => x.weight && x.age >= 12 }],
          schema: Schema.Struct({
            competitor_registration: Schema.Struct({
              id: Schema.Number(),
              name: Schema.String(),
              given_name: Schema.String(),
            }).required(),
          }),
        },
        ground_fighting_open: {
          title: _`Ground Fighting (Open Class)`,
          relations: ["competitor"],
          accessor: (x) => `${x.competitor.name}, ${x.competitor.given_name}`,
          renderCategory: (x) => x.sex.map((s) => sexInfo[s].name()).join("/"),
          newEntry: [{ name: "competitor_registration", label: _`Competitor`, filter: (x) => x.age >= 18 }],
          schema: Schema.Struct({
            competitor_registration: Schema.Struct({
              id: Schema.Number(),
              name: Schema.String(),
              given_name: Schema.String(),
            }).required(),
          }),
        },
        sparring: {
          title: _`Sparring`,
          relations: ["competitor"],
          accessor: (x) => `${x.competitor.name}, ${x.competitor.given_name}`,
          newEntry: [{ name: "competitor_registration", label: _`Competitor`, filter: (x) => x.weight && x.age >= 12 }],
          schema: Schema.Struct({
            competitor_registration: Schema.Struct({
              id: Schema.Number(),
              name: Schema.String(),
              given_name: Schema.String(),
            }).required(),
          }),
        },
        sparring_open: {
          title: _`Sparring (Open Class)`,
          relations: ["competitor"],
          accessor: (x) => `${x.competitor.name}, ${x.competitor.given_name}`,
          renderCategory: (x) => x.sex.map((s) => sexInfo[s].name()).join("/"),
          newEntry: [{ name: "competitor_registration", label: _`Competitor`, filter: (x) => x.age >= 18 }],
          schema: Schema.Struct({
            competitor_registration: Schema.Struct({
              id: Schema.Number(),
              name: Schema.String(),
              given_name: Schema.String(),
            }).required(),
          }),
        },
        continuous_fighting: {
          title: _`Continuous Fighting`,
          relations: ["competitor"],
          accessor: (x) => `${x.competitor.name}, ${x.competitor.given_name}`,
          newEntry: [{ name: "competitor_registration", label: _`Competitor`, filter: (x) => x.weight && x.age >= 12 }],
          schema: Schema.Struct({
            competitor_registration: Schema.Struct({
              id: Schema.Number(),
              name: Schema.String(),
              given_name: Schema.String(),
            }).required(),
          }),
        },
        continuous_fighting_open: {
          title: _`Continuous Fighting (Open Class)`,
          relations: ["competitor"],
          accessor: (x) => `${x.competitor.name}, ${x.competitor.given_name}`,
          renderCategory: (x) => x.sex.map((s) => sexInfo[s].name()).join("/"),
          newEntry: [{ name: "competitor_registration", label: _`Competitor`, filter: (x) => x.age >= 18 }],
          schema: Schema.Struct({
            competitor_registration: Schema.Struct({
              id: Schema.Number(),
              name: Schema.String(),
              given_name: Schema.String(),
            }).required(),
          }),
        },
        pairs: {
          title: _`Pairs`,
          relations: ["competitor_a", "competitor_b", "ranks"],
          accessor: (x) =>
            `${x.competitor_a.name}, ${x.competitor_a.given_name} / ${x.competitor_b.name}, ${x.competitor_b.given_name}`,
          renderCategory: renderAgeAndRankClassCategory,
          newEntry: [
            {
              name: "competitor_a_registration",
              label: _`First Competitor`,
              filter: (x, values) => x.id != values.competitor_b_registration?.id,
            },
            {
              name: "competitor_b_registration",
              label: _`Second Competitor`,
              filter: (x, values) => x.id != values.competitor_a_registration?.id,
            },
          ],
          schema: Schema.Struct({
            competitor_a_registration: Schema.Struct({
              id: Schema.Number(),
              name: Schema.String(),
              given_name: Schema.String(),
            }).required(),
            competitor_b_registration: Schema.Struct({
              id: Schema.Number(),
              name: Schema.String(),
              given_name: Schema.String(),
            }).required(),
          }),
        },
        // kata: {
        //   title: _`Kata`,
        //   relations: ["tori", "uke"],
        //   accessor: (x) => `${x.tori.name}, ${x.tori.given_name} / ${x.uke.name}, ${x.uke.given_name}`,
        //   newEntry: [
        //     {
        //       name: "tori_registration",
        //       label: _`Tori`,
        //       filter: (x, values) => ranks.get(x.rank_id) >= ranks.YONKYU && x.id != values.uke_registration?.id,
        //     },
        //     { name: "uke_registration", label: _`Uke`, filter: (x, values) => x.id != values.tori_registration?.id },
        //   ],
        //   schema: Schema.Struct({
        //     tori_registration: Schema.Struct({
        //       id: Schema.Number(),
        //       name: Schema.String(),
        //       given_name: Schema.String(),
        //     }).required(),
        //     uke_registration: Schema.Struct({
        //       id: Schema.Number(),
        //       name: Schema.String(),
        //       given_name: Schema.String(),
        //     }).required(),
        //   }),
        // },
        empty_hand_kata: {
          title: _`Empty Hand Kata`,
          relations: ["competitor"],
          accessor: (x) => `${x.competitor.name}, ${x.competitor.given_name}`,
          newEntry: [{ name: "competitor_registration", label: _`Competitor` }],
          schema: Schema.Struct({
            competitor_registration: Schema.Struct({
              id: Schema.Number(),
              name: Schema.String(),
              given_name: Schema.String(),
            }).required(),
          }),
        },
        weapons_kata: {
          title: _`Weapons Kata`,
          relations: ["competitor"],
          accessor: (x) => `${x.competitor.name}, ${x.competitor.given_name}`,
          newEntry: [{ name: "competitor_registration", label: _`Competitor`, filter: (x) => ranks.get(x.rank_id) >= ranks.PURPLE }],
          schema: Schema.Struct({
            competitor_registration: Schema.Struct({
              id: Schema.Number(),
              name: Schema.String(),
              given_name: Schema.String(),
            }).required(),
          }),
        },
        two_person_empty_hand_kata: {
          title: _`Two Person Empty Hand Kata`,
          relations: ["tori", "uke"],
          accessor: (x) => `${x.tori.name}, ${x.tori.given_name} / ${x.uke.name}, ${x.uke.given_name}`,
          newEntry: [
            { name: "tori_registration", label: _`Tori`, filter: (x, values) => x.id != values.uke_registration?.id },
            { name: "uke_registration", label: _`Uke`, filter: (x, values) => x.id != values.tori_registration?.id },
          ],
          schema: Schema.Struct({
            tori_registration: Schema.Struct({
              id: Schema.Number(),
              name: Schema.String(),
              given_name: Schema.String(),
            }).required(),
            uke_registration: Schema.Struct({
              id: Schema.Number(),
              name: Schema.String(),
              given_name: Schema.String(),
            }).required(),
          }),
        },
        two_person_weapons_kata: {
          title: _`Two Person Weapons Kata`,
          relations: ["tori", "uke"],
          accessor: (x) => `${x.tori.name}, ${x.tori.given_name} / ${x.uke.name}, ${x.uke.given_name}`,
          newEntry: [
            { name: "tori_registration", label: _`Tori`, filter: (x, values) => ranks.get(x.rank_id) >= ranks.PURPLE && x.id != values.uke_registration?.id },
            { name: "uke_registration", label: _`Uke`, filter: (x, values) => ranks.get(x.rank_id) >= ranks.PURPLE && x.id != values.tori_registration?.id },
          ],
          schema: Schema.Struct({
            tori_registration: Schema.Struct({
              id: Schema.Number(),
              name: Schema.String(),
              given_name: Schema.String(),
            }).required(),
            uke_registration: Schema.Struct({
              id: Schema.Number(),
              name: Schema.String(),
              given_name: Schema.String(),
            }).required(),
          }),
        },
        team: {
          title: _`Team`,
          relations: ["members"],
          accessor: (x) => x.members.map((m) => `${m.name}, ${m.given_name}`).join("; "),
          newEntry: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i) => ({
            name: `registration_${i}`,
            label: _`Competitor ${i + 1}`,
            filter: (x, values) =>
              !Object.entries(values)
                .filter(([k, v]) => k != `registration_${i}`)
                .map(([k, v]) => v?.id)
                .includes(x.id),
          })),
          // additionalFormElements: html`<div class="row">
          //   <div class="col-4">
          //     <label>${_`Music`} (${_`Can be updated after end of registrations`})</label
          //     ><input class="form-control" type="file" name="music" accept="audio/*" @change=${this.uploadFile} />
          //   </div>
          //   <div class="col-8">
          //     <label>${_`Requirements / Stage directions`} (${_`Can be updated after end of registrations`})</label
          //     ><textarea class="form-control" type="textarea" name="comment"></textarea>
          //   </div>
          // </div>`,
          schema: Schema.Struct(
            Object.fromEntries([
              ...[0, 1, 2].map((i) => [
                `registration_${i}`,
                Schema.Struct({
                  id: Schema.Number(),
                  name: Schema.String(),
                  given_name: Schema.String(),
                }).required(),
              ]),
              ...[3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => [
                `registration_${i}`,
                Schema.Struct({
                  id: Schema.Number(),
                  name: Schema.String(),
                  given_name: Schema.String(),
                }),
              ]),
            ])
          ),
        },
      };
    }

    render = () => html`<h1>${_`Entries`}</h1>
      <ul class="nav nav-tabs">
        ${Object.entries(this.tabs).map(
          ([k, v], i) => html`<li class="nav-item">
            <button class="${clsx("nav-link", { active: !i })}" data-bs-toggle="tab" data-bs-target="#${k}">
              ${v.title}
            </button>
          </li>`
        )}
      </ul>
      <div class="tab-content border border-top-0 p-3">
        ${Object.entries(this.tabs).map(
          ([k, v], i) =>
            html`<div class="${clsx("tab-pane fade", { "show active": !i })}" id="${k}">
              <jjcm-entries-list .discipline="${k}" .config=${v} />
            </div>`
        )}
      </div>`;
  }
);
