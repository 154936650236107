import { Light, LitElement, html } from "../lit.js";
import clsx from "../lib/clsx.js";

import { _ } from "../i18n.js";
import { state } from "../state.js";
import { httpRequest, sleep } from "../util.js";

import Schema from "../validation.js";

import "../widgets/Form.js";
import "../widgets/Input.js";

customElements.define(
  "jjcm-change-password",
  class extends Light(LitElement) {
    constructor() {
      super();
      this.alert_message = null;
      this.alert_class = null;
    }

    abortController = new AbortController();

    showAlert = (message, alert_class, delay) => {
      this.alert_message = message;
      this.alert_class = alert_class;
      this.requestUpdate();
      return this.getUpdateComplete().then(() => {
        const alert = this.querySelector(".alert");
        alert.parentNode.style.maxHeight = alert.offsetHeight + "px";
        return sleep(delay).then(() => (alert.parentNode.style.maxHeight = 0));
      });
    };

    changePassword = (e) =>
      httpRequest("/auth/change_password", {
        method: "POST",
        body: JSON.stringify({ password: e.target.values.password }),
      })
        .then(async (response) => {
          if (!response.ok) {
            this.showAlert(_`Could not change the password.`, "alert-danger", 5000);
            e.target.submittingDone();
            return;
          }
          this.showAlert(_`Your password was successfully changed.`, "alert-success", 2000).then(() =>
            window.history.back()
          );
        })
        .catch((error) => {
          console.error(error);
          e.target.submittingDone();
        });

    render = () =>
      state.session
        ? html`<h1>${_`Change password`}</h1>
            <x-form
              @submit=${this.changePassword}
              .validate=${(values) =>
                Schema.Struct({
                  password: Schema.String()
                    .required(_`Password missing!`)
                    .trim()
                    .min(8, _`The password must contain at least 8 characters.`),
                  password_confirmation: Schema.String().test(
                    (x, p) => x === p.password,
                    _`The passwords do not match!`
                  ),
                }).validate(values)}
              .renderContent=${({ values, errors, touched, submitting }) => html`<div class="row mb-3">
                  <div class="col">
                    <input
                      class="${clsx("form-control", {
                        "is-invalid": touched.password && errors.password,
                      })}"
                      type="password"
                      name="password"
                      placeholder="${_`New password`}"
                    />
                    <div class="invalid-feedback">${errors.password}</div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col">
                    <input
                      class="${clsx("form-control", {
                        "is-invalid": touched.password_confirmation && errors.password_confirmation,
                      })}"
                      type="password"
                      name="password_confirmation"
                      placeholder="${_`Repeat new password`}"
                    />
                    <div class="invalid-feedback">${errors.password_confirmation}</div>
                  </div>
                </div>
                <button class="mb-3 btn btn-primary" type="submit" ?disabled=${submitting}>
                  ${_`Change password`}
                </button>
                <button class="mb-3 btn btn-secondary" type="button" ?disabled=${submitting} @click=${this.logOut}>
                  ${_`Log out`}
                </button>`}
            />
            <div style="max-height: 0; overflow: clip; transition: max-height .15s linear">
              <div class="alert ${this.alert_class}">${this.alert_message}</div>
            </div>`
        : null;
  }
);
