import { Light, LitElement, html } from "../lit.js";
import { httpRequest, range, unique, shuffle } from "../util.js";
import { _ } from "../i18n.js";
import { state } from "../state.js";

import { clsx } from "../lib/clsx.js";

import "../widgets/Form.js";
import "../widgets/Input.js";
import "../widgets/Table.js";

import { ranks } from "./Rank.js";

import "./Match.js";

customElements.define(
  "jjcm-ground-fighting-match",
  class extends Light(LitElement) {
    abortController = new AbortController();

    static properties = {
      match: { type: Object },
    };

    constructor() {
      super();
      this.points = [
        { aka: 0, shiro: 0 },
        { aka: 0, shiro: 0 },
      ];
    }

    addPoint = (index, color, offset) => {
      this.points = [
        ...this.points.slice(0, index),
        { ...this.points[index], [color]: this.points[index][color] + offset },
        ...this.points.slice(index + 1),
      ];
      this.requestUpdate();
    };

    removePoint = (index, color, offset) => {
      this.points = [
        ...this.points.slice(0, index),
        { ...this.points[index], [color]: this.points[index][color] - offset },
        ...this.points.slice(index + 1),
      ];
      this.requestUpdate();
    };

    close = () => {
      this.dispatchEvent(new Event("closed", { bubbles: true }));
    };

    renderPointsInput = (index, color) => html`<div class="col">
      <h2>${[_`Points`, _`Penalty points`][index]}</h2>
      <div class="input-group shadow">
        <button
          class="btn btn-${["success", "warning"][index]} ps-5 pe-5"
          @click=${() => this.addPoint(index, color, index ? -1 : 1)}
        >
          <h1>+</h1>
        </button>
        <div
          class="${clsx("form-control", "points", {
            penalty: index,
            disqualification: this.points[index][color] < -3,
          })}"
        >
          ${(index ? -1 : 1) * this.points[index][color]}
        </div>
        <button
          class="btn btn-${["success", "warning"][index]} ps-5 pe-5"
          @click=${() => this.removePoint(index, color, index ? -1 : 1)}
        >
          <h1>-</h1>
        </button>
      </div>
    </div>`;

    render = () => html`<div class="vh-100 vw-100 d-flex flex-column bg-dark">
      <jjcm-match-header
        .match=${this.match}
        .points=${this.points}
        ?fusenGachiAvailable=${!this.points.some((x) => x.aka !== 0 || x.shiro !== 0)}
        ?victoryAvailable=${true}
      />
      <div class="m-0 row mt-auto">
        <div class="m-0 row col text-center p-5 bg-danger">
          ${this.renderPointsInput(1, "aka")} ${this.renderPointsInput(0, "aka")}
        </div>
        <div class="m-0 row col text-center p-5 bg-white">
          ${this.renderPointsInput(0, "shiro")} ${this.renderPointsInput(1, "shiro")}
        </div>
      </div>
      <div class="mt-5">
        <button class="btn btn-primary mb-2 ms-2" @click=${this.close}>
          <h1>${_`Postpone match`}</h1>
        </button>
      </div>
    </div>`;
  }
);
