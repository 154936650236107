import { Light, LitElement, html } from "../lit.js";
import { uuid } from "../util.js";

import "./Select.js";

class CheckableInput extends Light(LitElement) {
  static properties = {
    name: { type: String },
    value: { type: String },
    label: { type: String },
    checked: { type: Boolean },
    disabled: { type: Boolean },
  };

  #type = undefined;
  #id = uuid();

  constructor(type) {
    super();
    this.#type = type;
  }

  render = () => html`<input
      type="${this.#type}"
      id="${this.#id}"
      class="form-check-input"
      name="${this.name}"
      value="${this.value}"
      ?checked=${this.checked}
      ?disabled=${this.disabled}
    /><label class="form-check-label" for="${this.#id}">${this.label}</label>`;
}

customElements.define(
  "x-radio",
  class extends CheckableInput {
    constructor() {
      super("radio");
    }
  }
);
customElements.define(
  "x-checkbox",
  class extends CheckableInput {
    constructor() {
      super("checkbox");
    }
  }
);
