import { LitElement, html } from "../lit.js";
import { _ } from "../i18n.js";

export const sexInfo = {
  MALE: { name: () => _`male`, symbol: "♂" },
  FEMALE: { name: () => _`female`, symbol: "♀" },
  INTER: { name: () => _`non-binary`, symbol: "⚥" }, // ☿ // belgisch: niet-binair, ungarisch: nem-bináris, spanisch: no binario, japanisch: ノンバイナリー
};

customElements.define(
  "x-sex",
  class extends LitElement {
    static properties = { value: { converter: (value) => (value === "null" ? null : value) } };
    render = () =>
      this.value
        ? html`<span style="font-size: 1.2em" title="${sexInfo[this.value].name()}">${sexInfo[this.value].symbol}</span>`
        : null;
  }
);
