import { Light, LitElement, html } from "../lit.js";
import { httpRequest } from "../util.js";
import { _ } from "../i18n.js";
import { state } from "../state.js";

import { clsx } from "../lib/clsx.js";

import "./CompetitionBoard.js";

import { disciplineTitle } from "./Categories.js";

customElements.define(
  "jjcm-score-keeper",
  class extends Light(LitElement) {
    abortController = new AbortController();

    constructor() {
      super();
      this.updateCategories();
      this.activeCategory = null;
    }

    updateCategories = () =>
      httpRequest(`/competitions/${state.session.competition_id}/schedule?rel=games`, {
        signal: this.abortController.signal,
      })
        .then((response) => response.json())
        .then((data) => {
          this.categories = data;
          this.requestUpdate();
        })
        .catch(console.error);

    activateCategory = (category) => {
      this.activeCategory = category;
      this.requestUpdate();
    };

    deactivateCategory = (e) => {
      e.stopPropagation();
      this.activeCategory = null;
      this.requestUpdate();
    };

    render = () =>
      this.activeCategory
        ? html`<jjcm-competition-board .category=${this.activeCategory} @closed=${this.deactivateCategory} />`
        : html`<button class="btn-close" @click=${state.logOut} />
            <div>
              ${this.categories?.map(
                (x) =>
                  html`<div>
                    <button class="btn btn-primary m-2" @click=${() => this.activateCategory(x)}>
                      ${disciplineTitle(x.discipline)} - ${x.name} (${x.id})
                    </button>
                  </div>`
              )}
            </div>`;
  }
);

/**
 * random attack, jugendliche, gelb <- kaputt
 */
